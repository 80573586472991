import { PrivacySignalsConfig } from '@didomi/cmp-generator';
import { getPrivacySignalConfigUniqueName } from './signalsHelpers';

/**
 * Get synced GPP signals settings that should be applied to the regulation config - as the stored settings might be missing some signals
 * @param storedSignalsConfig - GPP signals config stored in the regulation config
 * @param configurableUniqueSignals - list of unique signals that can be mapped with purposes for the current regulation(state + usnat - as we store them all)
 */
export const getSyncedGppSignalsSettings = (storedSignalsConfig: PrivacySignalsConfig[], configurableUniqueSignals: PrivacySignalsConfig[]): PrivacySignalsConfig[] | null => {
  const gppSignalsSettings = configurableUniqueSignals.map(signal => {
    // Make sure that purposes added previously to the signal are transferred to the updated settings
    const signalUniqueName = getPrivacySignalConfigUniqueName(signal);
    const savedPurposes: string[] = storedSignalsConfig.find(s => getPrivacySignalConfigUniqueName(s) === signalUniqueName)?.purposes || [];
    return { ...signal, purposes: savedPurposes };
  }, []);

  return gppSignalsSettings;
};
