import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiModalContent, DidomiHintbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type MigrateToUiV2ModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const MigrateToUiV2Modal = ({ isOpen, onConfirm, onCancel }: MigrateToUiV2ModalProps) => {
  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };
  return (
    <DidomiModal isOpen={isOpen} onOpenChange={handleModalChange} closable="true">
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalSubTitle="Important" />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>
          <p>
            Your consent notice is using an obsolete version of the US regulation templates. Make the easy switch to US Privacy v2 today to unlock a new set of features and
            maximize the potential of your US notices.
          </p>
          <p className={tx('mb-s')}>Please note: the switch is irreversible. Make sure to read our documentation to know all you need to do.</p>

          <DidomiHintbox className={tx('text-left')} titleText="With US Privacy v2, you get:">
            <ul className={tx('list-disc list-inside mb-xs')}>
              <li>Expanded coverage: Nine new US state regulation templates.</li>
              <li>Multilingual compliance: Support for multiple languages.</li>
              <li>Streamlined design: Unified CSS across regulations.</li>
              <li>Enhanced flexibility: Improved Sensitive Personal Information (SPI) management.</li>
              <li>Future-ready: Global Privacy Platform (GPP) integration coming soon!</li>
            </ul>

            <p className={tx('font-bold')}>
              Read{' '}
              <a href="https://support.didomi.io/us-privacy-v2" className={tx('underline')} target="_blank" rel="noopener noreferrer">
                our US Privacy v2 documentation
              </a>{' '}
              to learn more.
            </p>
          </DidomiHintbox>
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onCancel}>
          Keep V1
        </DidomiButton>
        <DidomiButton onClick={onConfirm}>Activate US Privacy V2 now</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
