import { Platform } from '@didomi/cmp-generator';
import { CUSTOMIZATION_CONFIG_PER_REGULATION, CUSTOMIZATION_CONFIG_PER_REGULATION_V2 } from '@utils';

type ConfigOptions = {
  platform: Platform;
  config: { ui?: { version?: string } };
};

export const useCustomizationRegulationConfig = (regulationId: string, configOptions: ConfigOptions) => {
  const { config: rootConfig, platform } = configOptions || {};
  const customizationPerRegulation = rootConfig?.ui?.version === '2.0.0' && platform === 'web' ? CUSTOMIZATION_CONFIG_PER_REGULATION_V2 : CUSTOMIZATION_CONFIG_PER_REGULATION;
  const config = customizationPerRegulation[regulationId] || customizationPerRegulation.default;

  return {
    ...config,
  };
};
