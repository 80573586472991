import { useEffect, useMemo, useState } from 'react';
import { SDKConfig, SDKConfigPreferencesCategory } from '@didomi/cmp-generator';
import { extendPreferencesConfig, getAllSelectedVendors, getPurposeCategoriesBasedOnVendors, getPurposesFromCategories } from '@utils';
import { usePurposes } from './usePurposes.hook';
import { useVendors } from './useVendors.hook';

export const usePurposesCategoriesState = ({ config }: { config: SDKConfig }) => {
  const { data: vendors, isLoading: isVendorsLoading } = useVendors();
  const [categories, setCategories] = useState<SDKConfigPreferencesCategory[]>([]);
  const [essentialPurposes, setEssentialPurposes] = useState<string[]>([]);

  const { data: purposes, isLoading: isPurposesLoading } = usePurposes();

  useEffect(() => {
    if (config && vendors && purposes) {
      // Get categories/purposes based on the selected vendors - as `config.preferences.categories` might not be up to date(e.g when some purposes were added/removed from the Vendor)
      const allSelectedVendors = getAllSelectedVendors(config, vendors);
      const categories = getPurposeCategoriesBasedOnVendors(config.preferences?.categories || [], allSelectedVendors, purposes);
      setCategories(categories);

      setEssentialPurposes(config.app?.essentialPurposes || []);
    }
  }, [config, vendors, purposes]);

  const extendedCategories = useMemo(() => {
    if (categories && purposes) {
      return extendPreferencesConfig(categories, purposes, essentialPurposes);
    }
    return [];
  }, [categories, purposes, essentialPurposes]);

  const selectedPurposes = useMemo(() => {
    const purposesFromCategories = getPurposesFromCategories(categories);
    // In the categories we store purpose sdk id
    return purposesFromCategories.map(({ purposeId }) => purposes.find(p => p.sdk_id === purposeId)).filter(Boolean); // filter out any undefined values
  }, [categories, purposes]);

  const getPurposesCategoriesBasedOnVendors = (vendorsIds: string[]) => {
    const allSelectedVendors = vendors.filter(({ id }) => vendorsIds.includes(id));
    return getPurposeCategoriesBasedOnVendors(categories, allSelectedVendors, purposes);
  };

  const updateCategories = (categories: SDKConfigPreferencesCategory[]) => {
    setCategories(categories);
  };

  const updateEssentialPurpose = (updatedEssentialPurposes: string[]) => {
    setEssentialPurposes(updatedEssentialPurposes);
  };

  return {
    categories,
    extendedCategories,
    essentialPurposes,
    selectedPurposes,
    isLoading: isPurposesLoading || isVendorsLoading,
    getPurposesCategoriesBasedOnVendors,
    updateCategories,
    updateEssentialPurpose,
  };
};
