import { useCallback, useMemo } from 'react';
import { matchId } from '@didomi/helpers';
import { useLocalConsentNoticeConfig, useLocalRegulationConfig, usePurposes, useVendors, useVendorsWithOverrides } from '@hooks';
import { ConsentNoticeConfigRegulation, Purpose } from '@types';
import {
  CUSTOMIZATION_CONFIG_PER_REGULATION,
  CUSTOMIZATION_CONFIG_PER_REGULATION_V2,
  getPurposeCategoriesBasedOnVendors,
  getPurposesFromCategories,
  isActiveIAB2Vendor,
  isSpiPurpose,
} from '@utils';

/*
 * Calculate counters info for the default selected regulations
 * 1. Calculate unique vendors
 * Custom + IAB Vendors(is AIB is selected + regulations supports IAB)
 * 2. Calculate unique purposes
 * Get number of purposes in the preferences.categories
 * 3. Calculate unique SPI
 * Get SPI assigned to the vendors calculated on the first step. If regulation doesn't support SPI - returns undefined
 *
 * countsPerRegulation = { gdpr: { vendorsCount: 5, purposesCount: 4, spiCount: undefined }, cpra: { vendorsCount: 5, purposesCount: 4, spiCount: 3 } }
 */
export const useRegulationSelectionMetaInfo = (noticeId: string) => {
  const { data: noticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { defaultRegulations, isLoading: isConfigLoading } = useLocalRegulationConfig(noticeId);
  const { data: vendors = [], isLoading: isVendorsLoading } = useVendors();
  const { isLoading: isOverridesLoading, getProcessedVendors } = useVendorsWithOverrides();
  const { data: purposes, isLoading: isPurposesLoading } = usePurposes();

  const iabVendors = useMemo(() => vendors?.filter(isActiveIAB2Vendor), [vendors]);
  const iabVendorsIds = useMemo(() => iabVendors?.map(({ id }) => id), [iabVendors]);

  const checkIsIabAllSelected = (regConfig: ConsentNoticeConfigRegulation) => {
    return regConfig?.config?.app?.vendors?.iab?.enabled && regConfig?.config?.app?.vendors?.iab?.all;
  };

  const getRegulationUniqueSelectedVendors = useCallback(
    (regConfig: ConsentNoticeConfigRegulation) => {
      const isRegulationSupportsIAB = CUSTOMIZATION_CONFIG_PER_REGULATION[regConfig.regulation_id].hasIabIntegration;
      const inheritedVendorsIds = isRegulationSupportsIAB && checkIsIabAllSelected(regConfig) ? iabVendorsIds : [];
      const customVendorsIds = regConfig.config?.app?.vendors?.include || [];
      const combinedUniqueVendorsIds = [...new Set(customVendorsIds.concat(inheritedVendorsIds))];

      // Don't count deleted vendors (might be deleted from the data manager but still in the config)
      const combinedUniqueValidVendorsIds = combinedUniqueVendorsIds.filter(v => vendors.find(matchId(v)));

      return combinedUniqueValidVendorsIds.map(id => vendors.find(matchId(id)));
    },
    [vendors, iabVendorsIds],
  );

  const countsPerRegulation: { [key: string]: { vendorsCount: number; purposesCount: number; spiCount: number | undefined } } = useMemo(() => {
    if (isConfigLoading || isVendorsLoading || isOverridesLoading) {
      return {};
    }

    const purposesBySdkId = purposes.reduce<Record<string, Purpose>>((acc, purpose) => ({ ...acc, [purpose.sdk_id]: purpose }), {});

    return defaultRegulations.reduce((acc, regConfig) => {
      const selectedVendors = getRegulationUniqueSelectedVendors(regConfig);
      const processedVendors = getProcessedVendors(selectedVendors, regConfig.regulation_id, regConfig.config?.app?.vendors?.iab?.enabled);

      const customizationPerRegulation =
        noticeConfig?.config?.ui?.version === '2.0.0' && noticeConfig?.platform === 'web' ? CUSTOMIZATION_CONFIG_PER_REGULATION_V2 : CUSTOMIZATION_CONFIG_PER_REGULATION;
      const regulationSupportsSPI = customizationPerRegulation[regConfig.regulation_id].hasSPI;

      const upToDateCategories = getPurposeCategoriesBasedOnVendors(regConfig.config?.preferences?.categories || [], processedVendors, purposes);
      const purposesWithinCategories = getPurposesFromCategories(upToDateCategories);
      const purposesCount = purposesWithinCategories?.length || 0;
      const spiCount = regulationSupportsSPI ? purposesWithinCategories.filter(p => isSpiPurpose(purposesBySdkId[p.purposeId]))?.length : undefined;

      return {
        ...acc,
        [regConfig.regulation_id]: {
          vendorsCount: selectedVendors.length,
          purposesCount,
          spiCount,
        },
      };
    }, {});
  }, [noticeConfig, defaultRegulations, getRegulationUniqueSelectedVendors, isConfigLoading, isVendorsLoading, isOverridesLoading, getProcessedVendors, purposes]);

  return {
    countsPerRegulation,
    isLoading: isConfigLoading || isVendorsLoading || isPurposesLoading || isOverridesLoading,
  };
};
