import { SDKConfig, Platform, Regulation, PreferencesControlTypes, TranslatableString } from '@didomi/cmp-generator';
import { SPIRegulationSettings } from '@didomi/cmp-generator/dist/types/types/spi-regulations/spi-regulation-settings.type';

export interface CustomJSON {
  regulations?: {
    ccpa?: {
      enabled?: boolean;
    };
  };
}

export interface ConsentNoticeConfig {
  id: string;
  deployed_at?: string;
  custom_json?: CustomJSON;
  config: SDKConfig;
  created_at: Date;
  organization_id: string;
  notice_id: string;
  platform: Platform;
  default: boolean;
  targets: string[];
  text_id: string | null;
  text_mode: ConsentNoticeTextMode;
  negative_action: NegativeAction;
  negative_action_link: boolean;
  negative_action_link_format: NegativeActionLinkFormat;
  negative_action_button: boolean;
  disagree_button_style: DisagreeButtonStyle;
  full_atp: boolean;
  iabAll: boolean;
  salesforceEnabled: boolean;
  salesforceNamespace?: string;
  tagManagerProvider?: string;
  tagManagerDataLayerName?: string;
  enable_ignore_consent_before: boolean;
  ignore_consent_before: string;
  country: string | null;
  notice_deny_applies_to_li: boolean;
  preferences_deny_applies_to_li: boolean;
  consent_duration: number;
  consent_duration_unit: ConsentNoticeDurationUnit;
  daysBeforeShowingAgain: number;
  denied_consent_duration_custom: boolean;
  denied_consent_duration: number;
  denied_consent_duration_unit: ConsentNoticeDurationUnit;
  cross_device_enabled: boolean;
  cross_device_timeout: number;
  template_id?: null | string;
  gcm_enabled?: boolean;
  gcm_tcf_enabled?: boolean;
  gcm_set_default_status?: boolean;
  gcm_data_layer_name?: string | null;
  gcm_functionality_default_status?: boolean;
  gcm_personalization_default_status?: boolean;
  gcm_security_default_status?: boolean;
  gcm_analytics_default_status?: boolean;
  gcm_ad_user_data_default_status?: boolean;
  gcm_ad_personalization_default_status?: boolean;
  gcm_ads_default_status?: boolean;
  secure_cookies?: boolean;
  updated_at?: Date;
  version?: number;
  deployed_by?: null | string;
  deployed_by_name?: null | string;
  deployed_by_type?: null | string;
  deployed_metadata?: { [key: string]: string | number };
  enable_didomi_token_v2: boolean;
  enable_didomi_token_signature: boolean;
  regulation_configurations: ConsentNoticeConfigRegulation[];
  gpp_enabled: boolean; // TODO: remove casting once the generator is bumped with GPP version and new types.
}

export type ConsentNoticeConfigRegulation = Partial<ConsentNoticeConfig> & {
  regulation_id: Regulation;
  is_default_regulation_config: boolean;
  geo_locations: string[];
  disabled_at?: string;
  updated_at?: string;
  created_at?: string;
  config: SDKConfig;
  regulation_settings: SPIRegulationSettings | Record<string, never>;
  preferences_control_type?: PreferencesControlTypes;
  preferences_combine_li_and_consent?: boolean;
  preferences_content_instructions?: TranslatableString;
};

/**
 * This maps to notice.config['notice']['position']
 */
export enum ConsentNoticeConfigPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  POPUP = 'popup',
}

/**
 * This maps to notice.config['app']['vendors']['iab']['all]
 */
export enum IabVendorsType {
  ALL = 1,
  SELECTED = 0,
  NONE = -1,
}

/**
 * Interface for the `tagManager` object of a notice configuration
 */
export interface TagManagerConfig {
  provider: string;
  dataLayerName?: string;
}

/**
 * IAB TCF version. This maps to app.vendors.iab.version
 */
export enum ConsentNoticeIabVersion {
  V1 = 1,
  V2 = 2,
}

/**
 * Notice text mode
 */
export type ConsentNoticeTextMode = 'approved' | 'custom';

export type DisagreeButtonStyle = 'primary' | 'secondary';
export type ConsentNoticeDurationUnit = 'days' | 'months' | 'seconds';

export type NegativeAction = 'button' | 'link' | null;
export type NegativeActionLinkFormat = 'text' | 'cross';
