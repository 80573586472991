import { useMemo } from 'react';
import { CUSTOMIZATION_CONFIG_PER_REGULATION, extractPurposesFromVendors, getRegulationsNamesAddedToPurpose, isSpiPurpose } from '@utils';
import { usePurposes } from './usePurposes.hook';
import { usePurposesRegulationsOverrides } from './usePurposesRegulationsOverrides.hook';
import { useVendors } from './useVendors.hook';
import { useVendorsWithOverrides } from './useVendorsWithOverrides.hook';
import { useRegulations } from '../regulations/useRegulations.hook';

export const useSpiState = ({
  selectedVendorsIds,
  regulationId,
  isTcfEnabled,
  enabled,
}: {
  selectedVendorsIds: string[];
  regulationId?: string;
  isTcfEnabled?: boolean;
  enabled: boolean;
}) => {
  const { data: vendors } = useVendors();
  const { data: purposes } = usePurposes();
  const { getProcessedVendors } = useVendorsWithOverrides();
  const { data: regulations } = useRegulations();
  const { data: purposeOverrides } = usePurposesRegulationsOverrides();

  const spiGroups = useMemo(() => {
    if (!vendors || !purposes || !regulations || !purposeOverrides || !enabled) return [];

    const purposesBySdkId = purposes.reduce((acc, p) => ({ ...acc, [p.sdk_id]: p }), {});
    const regulationsNamesWithSpiSupport = regulations.data.filter(reg => reg.ui_status === 'visible' && CUSTOMIZATION_CONFIG_PER_REGULATION[reg.id]?.hasSPI).map(r => r.id);

    const getPurpose = (id: string) => purposes.find(p => p.id === id);
    const allSelectedVendors = vendors.filter(({ id }) => selectedVendorsIds.includes(id));
    const allSelectedProcessedVendors = getProcessedVendors(allSelectedVendors, regulationId, isTcfEnabled);
    const selectedPurposesIds = Array.from(extractPurposesFromVendors(allSelectedProcessedVendors));
    const selectedSpiIds = selectedPurposesIds.filter(pId => isSpiPurpose(purposesBySdkId[pId]));

    const getSpiInfo = (spiPurposeId: string) => {
      const purpose = getPurpose(spiPurposeId);

      return {
        id: spiPurposeId,
        regulationsId: getRegulationsNamesAddedToPurpose(purpose, purposeOverrides, regulationsNamesWithSpiSupport),
        description: purpose.description,
        vendorsNames: allSelectedProcessedVendors
          .filter(v => v.default_purposes_id.includes(spiPurposeId) || v.legitimate_interest_purposes_id.includes(spiPurposeId))
          .map(v => v.name),
      };
    };

    return selectedSpiIds.map(getSpiInfo);
  }, [selectedVendorsIds, vendors, purposes, regulationId, getProcessedVendors, isTcfEnabled, enabled, regulations, purposeOverrides]);

  return {
    spiGroups,
  };
};
