import { useCallback } from 'react';
import { PartnerPurposeProcessor, Purpose, Regulations } from '@didomi/cmp-generator';
import { Vendor } from '@types';
import { usePurposes } from './usePurposes.hook';
import { usePurposesRegulationsOverrides } from './usePurposesRegulationsOverrides.hook';
import { useVendorsPurposesOverrides } from './useVendorsPurposesOverrides.hook';

/**
 * Hook that encapsulates the logic to get vendors with overrides(vendors purposes overrides + purpose regulations overrides) applied
 */
export const useVendorsWithOverrides = () => {
  const purposesQuery = usePurposes();
  const partnerPurposesOverridesQuery = useVendorsPurposesOverrides();
  const purposeRegulationOverridesQuery = usePurposesRegulationsOverrides();

  const isLoading = purposesQuery.isLoading || partnerPurposesOverridesQuery.isLoading || purposeRegulationOverridesQuery.isLoading;
  const isLoaded = purposesQuery.isFetched || partnerPurposesOverridesQuery.isFetched || purposeRegulationOverridesQuery.isFetched;

  const getProcessedVendors = useCallback(
    (vendors: Vendor[], regulationId: string | null, isTcfEnabled?: boolean) => {
      if (isLoading) throw new Error('Overrides data is still loading');

      const purposes = purposesQuery.data;
      const purposeOverrides = partnerPurposesOverridesQuery.data.purposeOverrides;
      const legIntPurposeOverrides = partnerPurposesOverridesQuery.data.legIntPurposeOverrides;
      const purposesRegulationsOverrides = purposeRegulationOverridesQuery.data;

      return PartnerPurposeProcessor.processPartnerPurposes(vendors, purposes as unknown as Purpose[], regulationId as Regulations, {
        isTcfEnabled,
        purposeOverrides,
        legIntPurposeOverrides,
        purposesRegulationsOverrides,
      }) as unknown as Vendor[];
    },
    [
      isLoading,
      purposesQuery.data,
      partnerPurposesOverridesQuery.data.purposeOverrides,
      partnerPurposesOverridesQuery.data.legIntPurposeOverrides,
      purposeRegulationOverridesQuery.data,
    ],
  );

  return {
    isLoading,
    isLoaded,
    getProcessedVendors,
  };
};
