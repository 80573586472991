import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { ConsentNoticeWithTemplateMetaData, PaginatedResponse } from '@types';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';

// Filter out old notices that don't have config or template_metadata or are archived
const isValidNotice = (notice: ConsentNoticeWithTemplateMetaData) => !!notice.config && !!notice.template_metadata && !notice.archived_at;

/**
 * Fetches list of notices with template metadata
 * */
export const useTemplateConsentNotices = (options?: UseQueryOptions<ConsentNoticeWithTemplateMetaData[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchNotices = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<ConsentNoticeWithTemplateMetaData>>(
      `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices?organization_id=${organizationId}&$with[]=config&$with[]=template_metadata&$limit=5000`,
    );

    return response.data.data.filter(isValidNotice);
  };

  return useQuery<ConsentNoticeWithTemplateMetaData[], AxiosError>(
    ['consent-notices-with-template-metadata', organizationId],
    fetchNotices,
    // Disable cache - we want to avoid having two sources of notices data in cache as it will be difficult to keep them in sync
    { ...options, cacheTime: 0 },
  );
};
