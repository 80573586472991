import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DidomiButton,
  DidomiEmptyState,
  DidomiErrorState,
  DidomiHintbox,
  DidomiLoader,
  DidomiPaginator,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
} from '@didomi/ui-atoms-react';
import { useSPARouter, useSnackbar, useFeatureFlag, useReleaseFlag } from '@didomi/utility-react';
import { useParams, useSearchParams } from 'react-router-dom';
import { TextWithHighlighterAndTooltip, VendorIcon, VendorsListToolbar } from '@components';
import { usePaginatedFilteredItems, useTableState, useVendors } from '@hooks';
import { GcmRequiredModal, MicrosoftRequiredModal, SvlRedirectionGuardModal } from '@modals';
import { Vendor, VendorFilterType, VendorsSortableField } from '@types';
import { checkVendorType, GcmVendorsIds, getTableResultsText, McmVendorsIds } from '@utils';

const SEARCH_FIELDS = [{ field: 'name' }, { field: 'id' }, { field: 'sdk_id' }];
const GCM_VENDORS_IDS = [GcmVendorsIds.googleAnalytics, GcmVendorsIds.googleAds] as string[];
const MCM_VENDORS_IDS = [McmVendorsIds.microsoft] as string[];
interface VendorsListProps {
  selectedIds: string[];
  preselectedIds: string[];
  selectedTotalCount: number;
  isGcmVendorRequired: boolean;
  isMicrosoftVendorRequired?: boolean;
  viewOnly?: boolean;
  isRelatedDataLoading?: boolean;
  updateSelection: (newSelection: string[]) => void;
  onSaveAsTemplate?: () => void;
  withSmartVendorsListButton?: Boolean;
  hasConfigChanged?: Boolean;
  isNewTemplate?: Boolean;
  onApplyTemplateClicked?: () => void;
}

export const VendorsList = ({
  withSmartVendorsListButton = false,
  selectedIds = [],
  preselectedIds = [],
  selectedTotalCount,
  isGcmVendorRequired,
  isMicrosoftVendorRequired,
  viewOnly,
  isRelatedDataLoading,
  updateSelection,
  onSaveAsTemplate,
  hasConfigChanged = false,
  isNewTemplate = false,
  onApplyTemplateClicked,
}: VendorsListProps): JSX.Element => {
  const { displaySnackbar } = useSnackbar();
  const [gcmSelectionWarningOpen, setGcmSelectionWarningOpen] = useState(false);
  const [mcmSelectionWarningOpen, setMcmSelectionWarningOpen] = useState(false);
  const { navigateTo } = useSPARouter();
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const { isLoading: isVendorsLoading, data: vendors = [], error: errorLoadingVendors } = useVendors();
  const { limit, page, sortConfig, search, filters, changeLimit, changePage, changeSorting, changeSearch, changeFilters } = useTableState<VendorsSortableField>({
    defaultLimit: 5,
    defaultFilters: { type: VendorFilterType.ALL },
  });
  const allSelectedIds = useMemo(() => selectedIds.concat(preselectedIds), [selectedIds, preselectedIds]);
  const filterByType = useCallback((vendor: Vendor) => checkVendorType(vendor, filters.type as VendorFilterType, allSelectedIds), [filters, allSelectedIds]);
  const filtersToApply = useMemo(() => [filterByType], [filterByType]);
  const [svlguardPopupIsOpen, setSvlguardPopupIsOpen] = useState(false);

  const initialVendorsData = useMemo(() => vendors.filter(v => checkVendorType(v, VendorFilterType.ALL, allSelectedIds)), [vendors, allSelectedIds]);

  const { data: displayedVendors, total: paginationTotal } = usePaginatedFilteredItems<Vendor, VendorsSortableField>(initialVendorsData, {
    search,
    searchFields: SEARCH_FIELDS,
    limit,
    page,
    sortConfig,
    filters: filtersToApply,
  });

  const resultsText = getTableResultsText(paginationTotal, initialVendorsData.length, 'vendor');

  const isGCMUnselectAllowed = (unselectedVendorId: string, newSelectedItems: string[]) => {
    if (!isGcmVendorRequired || !GCM_VENDORS_IDS.includes(unselectedVendorId)) return true;

    const allSelectedIdsAfterUnselect = preselectedIds.concat(newSelectedItems);
    return GCM_VENDORS_IDS.some(vendorId => allSelectedIdsAfterUnselect.includes(vendorId));
  };

  const isMCMUnselectAllowed = (unselectedVendorId: string, newSelectedItems: string[]) => {
    if (!isMicrosoftVendorRequired || !MCM_VENDORS_IDS.includes(unselectedVendorId)) return true;

    const allSelectedIdsAfterUnselect = preselectedIds.concat(newSelectedItems);

    return MCM_VENDORS_IDS.some(vendorId => allSelectedIdsAfterUnselect.includes(vendorId));
  };

  const isGcmSelectAllChangeAllowed = (newSelectedItems: string[]) => {
    if (!isGcmVendorRequired) return true;

    const allSelectedIdsAfterChange = preselectedIds.concat(newSelectedItems);
    // If at least one GCM vendor remain selected - select-all-change is allowed
    return GCM_VENDORS_IDS.some(vendorId => allSelectedIdsAfterChange.includes(vendorId));
  };

  const isMcmSelectAllChangeAllowed = (newSelectedItems: string[]) => {
    if (!isMicrosoftVendorRequired) return true;

    const allSelectedIdsAfterChange = preselectedIds.concat(newSelectedItems);
    // If the Microsoft Advertising vendor remains selected - select-all-change is allowed
    return MCM_VENDORS_IDS.some(vendorId => allSelectedIdsAfterChange.includes(vendorId));
  };

  const handleRowSelectionChange = (event: CustomEvent<{ type: 'select' | 'unselect'; selectedItem: string; newSelectedItems: string[] }>) => {
    const { type, selectedItem, newSelectedItems } = event.detail;

    const showGcmWarning = isGcmVendorRequired && !isGCMUnselectAllowed(selectedItem, newSelectedItems);
    const showMcmWarning = isMicrosoftVendorRequired && !isMCMUnselectAllowed(selectedItem, newSelectedItems);

    const canUpdateSelection = type === 'select' || (!showGcmWarning && !showMcmWarning);

    if (canUpdateSelection) {
      updateSelection(newSelectedItems);
      return;
    }

    if (showGcmWarning) {
      setGcmSelectionWarningOpen(true);
    }

    if (showMcmWarning) {
      setMcmSelectionWarningOpen(true);
    }
  };

  const handleAllSelectionChange = (event: CustomEvent<{ selectedItems?: string[]; newSelectedItems: string[] }>) => {
    const { newSelectedItems } = event.detail;

    const updateSelectionWithRequiredVendor = (vendorIds: string[], currentSelection: string[]) => {
      const firstRequiredVendorOnPage = displayedVendors.find(({ id }) => vendorIds.includes(id));
      return firstRequiredVendorOnPage ? [...currentSelection, firstRequiredVendorOnPage.id] : currentSelection;
    };

    let updatedSelectionList = newSelectedItems;

    if (!isGcmSelectAllChangeAllowed(newSelectedItems)) {
      updatedSelectionList = updateSelectionWithRequiredVendor(GCM_VENDORS_IDS, updatedSelectionList);
    }

    if (!isMcmSelectAllChangeAllowed(updatedSelectionList)) {
      updatedSelectionList = updateSelectionWithRequiredVendor(MCM_VENDORS_IDS, updatedSelectionList);
    }

    updateSelection(updatedSelectionList);
  };

  const [hasSvlReleaseFlag] = useReleaseFlag('smart_vendor_list');
  const [hasSvlFeatureFlag] = useFeatureFlag('smart_vendor_list');

  const hasSvlFeatureAndReleaseFlags = hasSvlReleaseFlag && hasSvlFeatureFlag;
  useEffect(() => {
    if (!isVendorsLoading && searchParams.get('show-selected-vendors-snackbar') && selectedIds?.length) {
      displaySnackbar(`${selectedIds.length} Vendors are now selected`, { icon: 'success-small' });
      searchParams.delete('show-selected-vendors-snackbar');
      setSearchParams(searchParams);
    }
  }, [displaySnackbar, searchParams, isVendorsLoading, selectedIds, setSearchParams]);

  const handleRedirectToSvl = () => {
    navigateTo(`/smart-vendor-list/${params.templateId}?fromPath=${window.location.pathname}`);
  };

  const isLoading = isVendorsLoading || isRelatedDataLoading;

  return (
    <section className="mb-l">
      {/* Vendors List Block */}
      <SvlRedirectionGuardModal onClose={() => setSvlguardPopupIsOpen(false)} isOpen={svlguardPopupIsOpen} />
      {!isLoading && vendors && (
        <div className="flex flex-col">
          <div className="flex items-center mb-4">
            <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4 mr-auto">Vendors</h2>

            {/* TODO: refactor control buttons into separate component: VendorsManagementControls */}
            {!viewOnly && onSaveAsTemplate && (
              <DidomiButton variant="option-top" iconRight="star" className="mr-4" onClick={onSaveAsTemplate}>
                Save as vendor list
              </DidomiButton>
            )}

            {!!onApplyTemplateClicked && (
              <DidomiButton variant="option" iconRight="magic-wand" className="mr-4" onClick={onApplyTemplateClicked}>
                Apply an existing template
              </DidomiButton>
            )}

            {!viewOnly && (
              <div className="flex">
                <DidomiButton
                  size="small"
                  onClick={() => {
                    navigateTo(`/data-manager/add-vendor?fromPath=${window.location.pathname}`);
                  }}
                >
                  Add a new vendor
                </DidomiButton>
                {hasSvlFeatureAndReleaseFlags && withSmartVendorsListButton ? (
                  <DidomiButton
                    size="small"
                    className="ml-xs"
                    iconLeft="magic-wand"
                    onClick={() => (isNewTemplate || hasConfigChanged ? setSvlguardPopupIsOpen(true) : handleRedirectToSvl())}
                  >
                    CMP Vendor sync
                  </DidomiButton>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>

          <DidomiHintbox className="mb-m" titleText="Select vendors manually or through vendor lists">
            Select the vendors that you want to collect consent for. These vendors will appear in your consent notice with the required information (privacy policy URL and
            purposes).
          </DidomiHintbox>

          <VendorsListToolbar resultsText={resultsText} selectedCount={selectedTotalCount} onSearchChange={changeSearch} onFilterChange={type => changeFilters({ type })} />

          {displayedVendors.length > 0 && (
            <>
              <DidomiTable
                className="mb-4"
                data-testid="vendors-table"
                fixedLayout
                disabled={viewOnly}
                sortable
                selectable
                selectedItems={selectedIds}
                preSelectedItems={preselectedIds}
                sortBy={sortConfig.field}
                sortDirection={sortConfig.dir}
                onSortChange={changeSorting}
                onRowSelectionChange={handleRowSelectionChange}
                onToggleAllRowsSelectedChange={handleAllSelectionChange}
              >
                <DidomiTableHeading>
                  <DidomiTableHeaderRow>
                    <DidomiTableTh sortId="name" data-testid="sortName" style={{ flexBasis: 80 }}>
                      NAME
                    </DidomiTableTh>
                    <DidomiTableTh sortId="id">API ID</DidomiTableTh>
                    <DidomiTableTh sortId="sdk_id">IAB/SDK ID</DidomiTableTh>

                    {/* Logo Column */}
                    <DidomiTableTh></DidomiTableTh>
                  </DidomiTableHeaderRow>
                </DidomiTableHeading>

                <DidomiTableBody>
                  {/** There is a bug in the ui-library that breaks re-rendering the rows dynamically - wrapping the content in a div fixes the issue */}
                  {/** TODO: Investigate what is the problem, also found in ConsentNotices.tsx */}
                  <div>
                    {displayedVendors.map(vendor => (
                      <DidomiTableRow key={vendor.id} selectionValue={vendor.id} data-testid={vendor.id} data-cy={vendor.id}>
                        <DidomiTableTd style={{ flexBasis: 80 }}>
                          <TextWithHighlighterAndTooltip searchText={search} text={vendor.name} />
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <TextWithHighlighterAndTooltip searchText={search} text={vendor.id} />
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <TextWithHighlighterAndTooltip searchText={search} text={String(vendor.sdk_id || '')} />
                        </DidomiTableTd>

                        <DidomiTableTd>
                          <VendorIcon vendor={vendor} />
                        </DidomiTableTd>
                      </DidomiTableRow>
                    ))}
                  </div>
                </DidomiTableBody>
              </DidomiTable>

              <DidomiPaginator
                data-testid="vendors-paginator"
                className="self-end"
                page={page}
                itemCount={paginationTotal}
                size={limit}
                onPageSizeChange={changeLimit}
                onPageChange={changePage}
              />
            </>
          )}
        </div>
      )}

      {/* Loading State */}
      {isLoading && (
        <div className="w-full h-[700px] flex flex-col items-center justify-center">
          <DidomiLoader className="mb-xs" />
          <div className="text-body-normal text-primary-blue-5">Loading vendors</div>
        </div>
      )}

      {/* Error State */}
      {errorLoadingVendors && (
        <div className="w-full h-[500px]">
          <DidomiErrorState illustration-name="list-cannot-be-loaded" className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1">
            <div slot="title">There was an error loading the vendors list</div>
          </DidomiErrorState>
        </div>
      )}

      {/* Empty State */}
      {!isLoading && displayedVendors.length === 0 && (
        <DidomiEmptyState illustration-name="traces-no-match-found" className="h-full">
          No results
        </DidomiEmptyState>
      )}

      {/* Modals */}
      <GcmRequiredModal isOpen={gcmSelectionWarningOpen} onClose={() => setGcmSelectionWarningOpen(false)} />
      <MicrosoftRequiredModal isOpen={mcmSelectionWarningOpen} onClose={() => setMcmSelectionWarningOpen(false)} />
    </section>
  );
};
