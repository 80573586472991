import React, { useMemo } from 'react';
import { SDKConfigAppIabRestriction } from '@didomi/cmp-generator';
import { getTranslatedValue } from '@didomi/helpers';
import {
  DidomiModal,
  DidomiModalActions,
  DidomiButton,
  DidomiModalContent,
  DidomiHintbox,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiRadioGroupField,
  DidomiCollapse,
  DidomiSelectField,
  DidomiIcon,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Formik, Form, FormikHelpers } from 'formik';
import { customAlphabet as generateNanoId } from 'nanoid';
import nolookalikes from 'nanoid-dictionary/nolookalikes';
import { CardSelectionField, CompactVendorsSelectionList } from '@components';
import { useRestrictionPurposes, useVendors } from '@hooks';
import { getAvailableRestrictions, isIAB2Vendor } from '@utils';
import { Vendor } from '@types';

const onlyIab2Vendors = (vendors: Vendor[]) => vendors.filter(isIAB2Vendor);

const initialFormValue = {
  restrictionType: 'allow',
  purposeId: '',
  allVendors: true,
  vendorsIds: [],
};

type RestrictionFormModalProps = {
  isOpen: boolean;
  noticeName: string;
  restriction?: SDKConfigAppIabRestriction;
  onCancel?: () => void;
  onSave?: (restriction: SDKConfigAppIabRestriction) => void;
};

export const RestrictionFormModal = ({ isOpen, noticeName, restriction, onCancel, onSave }: RestrictionFormModalProps) => {
  const { data: purposes } = useRestrictionPurposes();
  const { data: vendors } = useVendors({ select: onlyIab2Vendors });

  const initialFormState = useMemo(() => {
    if (restriction) {
      const { purposeId, restrictionType, vendors } = restriction;
      return { purposeId, restrictionType, allVendors: vendors.type === 'all', vendorsIds: vendors.ids };
    } else {
      return initialFormValue;
    }
  }, [restriction]);

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const handleSave = (values: typeof initialFormValue, { resetForm }: FormikHelpers<typeof initialFormValue>) => {
    // TODO: discuss moving id generation to the API(CNC-162)
    const id = restriction?.id || generateNanoId(nolookalikes, 8)();
    const vendors = { type: values.allVendors ? 'all' : 'list', ids: values.vendorsIds };
    const data = { id, restrictionType: values.restrictionType, purposeId: values.purposeId, vendors } as SDKConfigAppIabRestriction;
    onSave(data);
    resetForm();
  };

  return (
    <DidomiModal isOpen={isOpen} closable="true" onOpenChange={handleModalChange}>
      <div data-testid="restriction-notice-name" className={tx('text-body-extra-small text-primary-blue-6')}>
        {noticeName}
      </div>
      <div data-testid="restriction-form-title" className={tx('text-h3 text-primary-blue-6 font-bold font-serif mb-s')}>
        {restriction ? 'Edit' : 'Add'} restriction
      </div>

      <Formik initialValues={initialFormState} enableReinitialize onSubmit={handleSave}>
        {({ values, setFieldValue, resetForm }) => {
          const availableRestrictions = getAvailableRestrictions(values?.purposeId);

          return (
            <Form>
              <DidomiModalContent className={tx('text-left')}>
                <DidomiHintbox className={tx('mb-s')} titleText="How to configure restriction?">
                  <p className={tx('mb-2')}>
                    A restriction specifies what IAB vendors are allowed to do for a specific purpose. You can choose to apply a restriction to a specific list of IAB vendors or to
                    all of them automatically.
                  </p>

                  <a
                    href="https://support.didomi.io/parameter-the-publisher-restrictions-in-the-didomi-console"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={tx('font-semibold flex items-center gap-2')}
                  >
                    Read our help center documentation <DidomiIcon name="export" />
                  </a>
                </DidomiHintbox>

                <DidomiSelectField className={tx('mb-s')} name="purposeId" placeholder="Select a purpose" label="Purpose" hideErrorMessage={true}>
                  <DidomiSelectOptions>
                    {purposes?.map(purpose => (
                      <DidomiSelectOption
                        key={purpose.id}
                        label={getTranslatedValue(purpose.description)}
                        value={purpose.id}
                        onOptionSelectionChange={() => {
                          // Reset restriction type when changing purpose
                          setFieldValue('restrictionType', 'allow');
                        }}
                      ></DidomiSelectOption>
                    ))}
                  </DidomiSelectOptions>
                </DidomiSelectField>

                <DidomiCollapse isExpanded={!!values.purposeId}>
                  <div>
                    {/* Vendors */}
                    <fieldset className={tx('mb-s')}>
                      <legend className={tx('text-field-label font-semibold text-primary-blue-6')}>Vendors</legend>
                      <CardSelectionField
                        labelText="All IAB vendors"
                        selected={values.allVendors}
                        inputType="checkbox"
                        name="vendors"
                        onChange={e => {
                          setFieldValue('allVendors', e.detail);
                          setFieldValue('vendorsIds', []);
                        }}
                      >
                        <div className={tx('pr-s')}>
                          Restrictions will be added to all current IAB Vendors and will be automatically kept up to date as new IAB vendors are added to the TCF.
                        </div>
                      </CardSelectionField>
                    </fieldset>

                    {!values.allVendors && (
                      <CompactVendorsSelectionList
                        className={tx('mb-xl')}
                        selectedIds={values.vendorsIds}
                        vendors={vendors}
                        updateSelection={newSelection => setFieldValue('vendorsIds', newSelection)}
                      />
                    )}

                    {/* Restriction */}
                    <fieldset>
                      <legend className={tx('text-field-label font-semibold text-primary-blue-6')}>Restriction</legend>
                      <DidomiRadioGroupField name="restrictionType">
                        <div>
                          {availableRestrictions.map(({ value, label, description }) => (
                            <CardSelectionField
                              data-testid={'restriction-' + value}
                              key={value}
                              labelText={label}
                              value={value}
                              selected={values.restrictionType === value}
                              collapsibleContent
                            >
                              <div className={tx('pr-s')}>{description}</div>
                            </CardSelectionField>
                          ))}
                        </div>
                      </DidomiRadioGroupField>
                    </fieldset>
                  </div>
                </DidomiCollapse>
              </DidomiModalContent>

              <DidomiModalActions>
                <DidomiButton
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </DidomiButton>
                <DidomiButton data-testid="save-restriction" type="submit" disabled={!values.purposeId}>
                  Save
                </DidomiButton>
              </DidomiModalActions>
            </Form>
          );
        }}
      </Formik>
    </DidomiModal>
  );
};
