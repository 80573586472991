import { PrivacySignalsConfig, US_PURPOSE_ID_TO_PRIVACY_SIGNAL_SECTION_OR_SPI } from '@didomi/cmp-generator';

// Swap key and value - from { purposeId: signalName } to { signalName: purposeId }
const PRIVACY_SIGNAL_SECTION_OR_SPI_TO_US_PURPOSE_ID = Object.entries(US_PURPOSE_ID_TO_PRIVACY_SIGNAL_SECTION_OR_SPI).reduce((acc, [purposeId, signalName]) => {
  acc[signalName] = purposeId;
  return acc;
}, {});

// Get purpose id that can be auto-mapped with the signal based on the default mapping
const getDefaultPurposeIdForSignal = (signalConfig: PrivacySignalsConfig) => {
  const signalName = signalConfig.namespaces.gpp || signalConfig.section;
  return PRIVACY_SIGNAL_SECTION_OR_SPI_TO_US_PURPOSE_ID[signalName];
};

// Check if the signal is missing the default purpose(in case it's selected)
const isSignalMissingDefaultPurposeThatIsSelected = (signalsConfig: PrivacySignalsConfig, selectedPurposeIds: string[]) => {
  const defaultPurposeIdForSignal = getDefaultPurposeIdForSignal(signalsConfig);
  // Check if purpose is selected + not mapped to the signal
  return selectedPurposeIds.includes(defaultPurposeIdForSignal) && !signalsConfig.purposes.includes(defaultPurposeIdForSignal);
};

// Check if there is any signal that can be auto-mapped with a selected purpose based on the default mapping
export const checkIfDefaultMappingAvailable = (signalsConfig: PrivacySignalsConfig[], selectedPurposeIds: string[]) => {
  return signalsConfig.some(signalConfig => isSignalMissingDefaultPurposeThatIsSelected(signalConfig, selectedPurposeIds));
};

// Get the updated GPP signals config with applied default mapping
export const getSignalsConfigWithAppliedDefaultMapping = (signalsConfig: PrivacySignalsConfig[], selectedPurposeIds: string[]): PrivacySignalsConfig[] => {
  return signalsConfig.map(signalsConfig => {
    if (isSignalMissingDefaultPurposeThatIsSelected(signalsConfig, selectedPurposeIds)) {
      // Add the default purpose to the signal
      const defaultPurposeIdMappingForSignal = getDefaultPurposeIdForSignal(signalsConfig);
      return { ...signalsConfig, purposes: [...signalsConfig.purposes, defaultPurposeIdMappingForSignal] };
    }

    // Nothing to change, return the original signal config
    return signalsConfig;
  });
};
