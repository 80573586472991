import React from 'react';
import { produce } from '@didomi/helpers';
import { DidomiTextInput, DidomiTooltip, DidomiIcon, DidomiColorPicker, DidomiHintbox, DidomiChip } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useLocalRegulationConfig } from '@hooks';
import { ACCESS_POLICIES_CONFIG, INPUT_DEBOUNCE_TIME, REGULATION_DICTIONARY } from '@utils';

interface CustomizationGlobalOptionsProps {
  noticeId: string;
}

export const CustomizationGlobalOptions = ({ noticeId }: CustomizationGlobalOptionsProps) => {
  const { data: localConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const { activeDefaultRegulations } = useLocalRegulationConfig(noticeId);

  const handleLogoURLChange = (newLogoURL: string) => {
    updateLocalConfig(produce(localConsentNoticeConfig, 'config.app.logoUrl', newLogoURL || undefined));
  };

  const handleLogoAltTextChange = (newLogoAltTag: string) => {
    updateLocalConfig(produce(localConsentNoticeConfig, 'config.app.logoAltText', newLogoAltTag || undefined));
  };

  const handleBrandColorChange = (newBrandColorEvent: CustomEvent<string>) => {
    const newBrandColor = newBrandColorEvent.detail;
    updateLocalConfig(produce(localConsentNoticeConfig, 'config.theme.color', newBrandColor || '#05687B'));
  };

  const handleLinkColorChange = (newLinkColorEvent: CustomEvent<string>) => {
    const newLinkColor = newLinkColorEvent.detail;
    updateLocalConfig(produce(localConsentNoticeConfig, 'config.theme.linkColor', newLinkColor || '#05687B'));
  };

  const handleFontChange = (newFontEvent: CustomEvent<string>) => {
    const newFont = newFontEvent.detail;
    updateLocalConfig(produce(localConsentNoticeConfig, 'config.theme.font', newFont || 'Arial, sans-serif'));
  };

  const isWebPlatform = localConsentNoticeConfig?.platform === 'web';

  return (
    <>
      <DidomiHintbox iconName="warning-blue">
        <p className="text-primary-blue-6">All the settings that apply globally to all selected regulations.</p>
      </DidomiHintbox>

      <div className="flex gap-xxs items-center">
        <div id="selected-regulations" className="text-body-extra-small text-secondary-cobalt-blue-3 font-semibold shrink-0">
          Selected regulations:
        </div>
        <ul className="flex gap-xxxs items-center flex-wrap" aria-labelledby="selected-regulations">
          {activeDefaultRegulations.map(regulation => (
            <li key={regulation.regulation_id} className="leading-none">
              <DidomiChip label={REGULATION_DICTIONARY[regulation.regulation_id]?.titleText} />
            </li>
          ))}
        </ul>
      </div>

      <DidomiTextInput
        label="Brand logo"
        placeholder="Enter a hosted logo URL"
        debounce={INPUT_DEBOUNCE_TIME}
        hideErrorMessage
        value={localConsentNoticeConfig?.config?.app?.logoUrl || ''}
        disabled={!isCMPEditor}
        onValueChange={e => handleLogoURLChange(e.detail)}
      >
        <div slot="description" className="ml-xxxs">
          <DidomiTooltip variant="helper" content="Copy and paste the URL address of your brand logo and make sure your link is allowed to be used">
            <DidomiIcon name="helper-text"></DidomiIcon>
          </DidomiTooltip>
        </div>
      </DidomiTextInput>

      {isWebPlatform && (
        <DidomiTextInput
          label="Brand logo - alt tag"
          inputPrefix="alt="
          debounce={INPUT_DEBOUNCE_TIME}
          hideErrorMessage
          value={localConsentNoticeConfig?.config?.app?.logoAltText || ''}
          disabled={!localConsentNoticeConfig?.config?.app?.logoUrl || !isCMPEditor}
          onValueChange={e => handleLogoAltTextChange(e.detail)}
        >
          <div slot="description" className="ml-xxxs">
            <DidomiTooltip variant="helper" contentElementId="altTagTooltip">
              <DidomiIcon name="helper-text"></DidomiIcon>
            </DidomiTooltip>
          </div>
        </DidomiTextInput>
      )}
      <div id="altTagTooltip" hidden>
        <strong>Accessibility requirement</strong>
        <p>
          The purpose of this attribute is to provide a way for screen readers and assistive technologies to understand what the image is about. For a brand logo, for example, you
          could use:
        </p>
        <p className="italic">alt=”Brand logo”</p>
      </div>

      <div className="flex justify-between items-center" data-testid="brand-color">
        <span className="text-body-small text-neutral-gray-8">Brand color</span>
        <DidomiColorPicker title="Brand color" value={localConsentNoticeConfig?.config?.theme?.color || '#05687B'} disabled={!isCMPEditor} onValueChange={handleBrandColorChange} />
      </div>
      <div className="flex justify-between items-center" data-testid="links-color">
        <span className="text-body-small text-neutral-gray-8">Links color</span>
        <DidomiColorPicker
          title="Links color"
          value={localConsentNoticeConfig?.config?.theme?.linkColor || '#05687B'}
          disabled={!isCMPEditor}
          onValueChange={handleLinkColorChange}
        />
      </div>

      {localConsentNoticeConfig?.platform === 'web' && (
        <DidomiTextInput
          label="Font family"
          debounce={INPUT_DEBOUNCE_TIME}
          value={localConsentNoticeConfig?.config?.theme?.font || 'Arial, sans-serif'}
          onValueChange={handleFontChange}
          placeholder="Avenir, book, medium"
          disabled={!isCMPEditor}
          hideErrorMessage
        >
          <div slot="description" className="ml-xxxs">
            <div id="fontFamilyTooltip" hidden>
              Define the font family you want to use for the description text. <br />
              e.g. <strong>&ldquo;Monserrat&ldquo;, sans-serif;</strong>
            </div>
            <DidomiTooltip variant="helper" contentElementId="fontFamilyTooltip">
              <DidomiIcon name="helper-text"></DidomiIcon>
            </DidomiTooltip>
          </div>
        </DidomiTextInput>
      )}
    </>
  );
};
