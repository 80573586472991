import { PrivacySignalsConfig } from '@didomi/cmp-generator';
import { Purpose } from '@types';

// Check if all selected purposes are mapped to privacy signals - as it's required for GPP settings to be valid
export const areAllPurposesMappedToPrivacySignals = (purposes: Purpose[], privacySignals: PrivacySignalsConfig[]) => {
  // Set of purpose ids that are mapped to privacy signals
  const mappedPurposeIdsSet = privacySignals.reduce((acc, signal) => {
    signal.purposes.forEach(purposeId => acc.add(purposeId));
    return acc;
  }, new Set<string>());

  return purposes.every(purpose => mappedPurposeIdsSet.has(purpose.sdk_id));
};
