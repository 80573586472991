import React, { useState } from 'react';
import { useSnackbar } from '@didomi/utility-react';
import { Outlet, useParams } from 'react-router-dom';
import { ConsentNoticePreviewStandalone } from '@components';
import { LocalNoticeConfigProvider } from '@facade-hooks';
import { useEditConsentNotice, useEditConsentNoticeConfig, useHasNoticeChanged, useLocalConsentNotice, useLocalConsentNoticeConfig } from '@hooks';
import { BlockNavigationModal, LoadingModal } from '@modals';
import { PreviewInitialParams } from '@types';

export const ConsentNoticeEditPage = () => {
  let { noticeId } = useParams();
  const { hasNoticeChanged, hasNoticeConfigChanged } = useHasNoticeChanged(noticeId);
  const { mutateAsync: updateConsentNotice, isLoading: savingNotice } = useEditConsentNotice(noticeId);
  const { mutateAsync: updateConsentNoticeConfig, isLoading: savingNoticeConfig } = useEditConsentNoticeConfig();
  const { data: localConsentNotice } = useLocalConsentNotice(noticeId);
  const { data: localConsentNoticeConfig, remove: resetLocalConfig } = useLocalConsentNoticeConfig(noticeId);
  const { displaySnackbar } = useSnackbar();
  const [previewState, setPreviewState] = useState<{ isOpen: boolean; params: PreviewInitialParams }>({ isOpen: false, params: null });

  const saveNoticeChanges = async () => {
    try {
      if (hasNoticeConfigChanged) {
        await updateConsentNoticeConfig(localConsentNoticeConfig);
      }
      if (hasNoticeChanged) {
        await updateConsentNotice(localConsentNotice);
      }

      displaySnackbar(`Your changes have been saved`, { icon: 'success-small' });
      return true;
    } catch (error) {
      displaySnackbar(error?.response?.data?.message || 'There was an error updating the notice', { variant: 'error', duration: 5000 });
      return false;
    }
  };

  const openPreview = (previewParams?: PreviewInitialParams) => {
    setPreviewState({ isOpen: true, params: previewParams });
  };

  const hasChanges = hasNoticeConfigChanged || hasNoticeChanged;
  const isSaving = savingNoticeConfig || savingNotice;

  return (
    <>
      <LocalNoticeConfigProvider>
        <Outlet context={{ hasChanges, saveNoticeChanges, openPreview }} />

        <ConsentNoticePreviewStandalone
          id="standalone-edit-page"
          noticeId={previewState.isOpen ? noticeId : null}
          initialParams={previewState.params}
          onClose={() => setPreviewState({ isOpen: false, params: null })}
          isOpen={previewState.isOpen}
        />

        <BlockNavigationModal
          buttonArrangement="row"
          whitelist={[new RegExp(noticeId)]}
          isBlock={hasNoticeConfigChanged || hasNoticeChanged}
          isLoading={isSaving}
          title="You have unsaved changes!"
          subTitle="Pay attention"
          description="If you do not save now, you will lose the last changes you made."
          saveButtonText="Save"
          onSave={saveNoticeChanges}
          // reset local config to the original state(noticeConfig)
          discardAndProceedText={`Don't Save`}
          onDiscardChanges={resetLocalConfig}
        />

        <LoadingModal isOpen={isSaving} title="Saving changes..." />
      </LocalNoticeConfigProvider>
    </>
  );
};
