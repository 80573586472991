import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type AddGCMWarningModalProps = {
  type: 'advertising' | 'analytics' | 'all';
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

const ILLUSTRATION_PER_TYPE = {
  advertising: 'add-google-advertising-vendor',
  analytics: 'add-google-analytics-vendor',
  all: 'add-google-vendors',
};

/**
 * Confirm before adding a Google Vendor(s)
 */
export const AddGCMWarningModal = ({ isOpen, type = 'advertising', onCancel, onProceed }: AddGCMWarningModalProps): JSX.Element => {
  /* istanbul ignore next */
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const modalTitle = type === 'all' ? 'Vendors will be added' : 'A vendor will be added';

  return (
    <DidomiModal isOpen={isOpen} onOpenChange={handleOnChange}>
      <DidomiIllustration name={ILLUSTRATION_PER_TYPE[type] as any} style={{ marginBottom: 'var(--space-m)' }} />
      <DidomiModalHeader variant="warning" modalSubTitle="Please pay attention" modalTitle={modalTitle}></DidomiModalHeader>

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6 max-w-[330px]')}>
          {type === 'all' && (
            <p>
              In order to use the Google Consent Mode integration the <strong>Google Analytic vendor</strong> and the <strong>Google Advertising vendor</strong> needs to be added
              to the notice.
            </p>
          )}

          {type === 'analytics' && (
            <p>
              In order to use the Google Consent Mode integration the <strong>Google Analytic vendor</strong> needs to be added to the notice.
            </p>
          )}

          {type === 'advertising' && (
            <p>
              In order to use the Google Consent Mode integration the <strong>Google Advertising vendor</strong> needs to be added to the notice.
            </p>
          )}
        </div>
      </DidomiModalContent>

      <DidomiModalActions className={tx('!pt-5')}>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onProceed}>Ok, add the {type === 'all' ? 'vendors' : 'vendor'}</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
