import React, { useEffect, useState } from 'react';
import {
  DidomiButton,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButtonGroup,
  DidomiModalContent,
  DidomiHintbox,
  DidomiCardContainer,
  DidomiControlledCollapsibleCard,
  DidomiCollapse,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { GppStringSelectionRadioControl, GppStringSelectionRegulationCardHeader } from '@components';

type GppStringSettingsProps = {
  isOpen: boolean;
  defaultValue: boolean;
  regulationIdToUsnatValueHash: Record<string, boolean | null>;
  onCancel: () => void;
  onSave: (defaultValue: boolean, regulationIdToUsnatValueHash: Record<string, boolean | null>) => void;
};

export const GppStringSettingsModal = ({ isOpen, defaultValue, regulationIdToUsnatValueHash, onCancel, onSave }: GppStringSettingsProps) => {
  const [defaultValueSelect, setDefaultValueSelect] = useState<boolean>();
  const [regulationValueSelectHash, setRegulationValueSelectHash] = useState<Record<string, boolean | null>>({});

  useEffect(() => {
    // set inputs values when modal opens
    if (isOpen) {
      setDefaultValueSelect(defaultValue);
      setRegulationValueSelectHash(regulationIdToUsnatValueHash);
    }
  }, [isOpen, defaultValue, regulationIdToUsnatValueHash]);

  const handleRegulationValueChange = (regulationId: string, value: boolean | null) => {
    setRegulationValueSelectHash({ ...regulationValueSelectHash, [regulationId]: value });
  };

  // Show additional info when state id(usnat false) is selected
  const isStateSpecificInfoTextVisible = defaultValueSelect === false;

  // Transform object to to array so it can be iterated(e.g. [['usnat', true]])
  const regulationValueSelectEntries = Object.entries(regulationValueSelectHash);

  return (
    <DidomiModal isOpen={isOpen} permanent>
      <DidomiModalHeader variant="warning" modalTitle="Configure GPP string type" />

      <DidomiModalContent className={tx('text-left text-primary-blue-6')}>
        <DidomiHintbox className={tx('mb-s')} iconName="warning-blue">
          Default value will be used for regulations that don&apos;t have value set individually. For full control, specify the string type per state.
        </DidomiHintbox>

        <DidomiCardContainer>
          <fieldset>
            <legend className={tx('mb-xxs')}>Default</legend>
            <GppStringSelectionRadioControl value={defaultValueSelect} onValueChange={setDefaultValueSelect} />
          </fieldset>
        </DidomiCardContainer>

        <DidomiCollapse isExpanded={isStateSpecificInfoTextVisible}>
          <DidomiHintbox className={tx('mt-s')} iconName="warning-blue">
            <p className={tx('mb-xxs')}>
              State-specific strings will be automatically applied when a new GPP state is supported and available in the Console, effective after notice publication.
            </p>
            <p>
              By adjusting the setting below, you can ensure the US National string remains in use, even when a state string becomes available, until you&apos;re ready to switch.
            </p>
          </DidomiHintbox>
        </DidomiCollapse>

        {regulationValueSelectEntries.length > 0 && (
          <>
            <hr className={tx('h-0 border-t-1 border-neutral-gray-3 my-s')} />
            <div className={tx('mb-xxs text-h4 !font-semibold')}>String type per regulation</div>

            <div className={tx('flex gap-xxs flex-col')}>
              {regulationValueSelectEntries.map(([regulationId, value]) => (
                <fieldset key={regulationId} aria-label={`Configure ${regulationId}`}>
                  <DidomiControlledCollapsibleCard>
                    <div slot="header-title-content">
                      <GppStringSelectionRegulationCardHeader regulationId={regulationId} value={value} />
                    </div>
                    <GppStringSelectionRadioControl value={value} showDefault onValueChange={value => handleRegulationValueChange(regulationId, value)} />
                  </DidomiControlledCollapsibleCard>
                </fieldset>
              ))}
            </div>
          </>
        )}
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={() => onSave(defaultValueSelect, regulationValueSelectHash)}>Save</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
