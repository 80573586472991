import React from 'react';
import { IntegrationIAB, IntegrationGoogleAdManagerACM, IntegrationGoogleConsentMode, IntegrationGpp, IntegrationMicrosoftConsentMode } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { useIntegrationsAvailability } from '@hooks';

export const IntegrationSelectionSection = () => {
  const { isIabEnabled, platform, isStandardAtpListSelected, activeRegulationIds, isLoadingConfig } = useLocalConsentNoticeConfigFacade();
  const { adsIntegrationsAvailability } = useIntegrationsAvailability({ isIabEnabled, platform, isStandardAtpListSelected, activeRegulationIds });

  if (isLoadingConfig)
    return (
      <div className="flex flex-col gap-xs">
        <div className="w-full h-[320px]" data-skeleton />
        <div className="w-full h-[320px]" data-skeleton />
        <div className="w-full h-[320px]" data-skeleton />
        <div className="w-full h-[320px]" data-skeleton />
      </div>
    );

  return (
    <div className="flex flex-col gap-xs pb-s">
      {adsIntegrationsAvailability.iab && <IntegrationIAB />}
      {adsIntegrationsAvailability.gpp && <IntegrationGpp />}
      {adsIntegrationsAvailability.googleAdManager && <IntegrationGoogleAdManagerACM />}
      {adsIntegrationsAvailability.gcm && <IntegrationGoogleConsentMode />}
      {adsIntegrationsAvailability.mcm && <IntegrationMicrosoftConsentMode />}
    </div>
  );
};
