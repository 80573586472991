import React, { useState } from 'react';
import { Platform } from '@didomi/cmp-generator';
import { DidomiMenu, DidomiMenuItem, DidomiIcon } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies, useCustomFeature } from '@didomi/utility-react';
import { useParams } from 'react-router-dom';
import { useLocalConsentNoticeConfig, useEditLocalConsentNoticeConfig, useRegulations } from '@hooks';
import { ConfirmPlatformChangeModal } from '@modals';
import { CONSENT_NOTICES_PLATFORMS_DESC } from '@types';
import { ACCESS_POLICIES_CONFIG, produceListOfChanges, updateRegulationConfigsEnablement } from '@utils';

export const PlatformSelectionMenu = () => {
  let { noticeId } = useParams();
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const [platformToUpdate, setPlatformToUpdate] = useState<Platform>();

  const [hasCTVFeature] = useCustomFeature('widgets-notices-ctv');

  const { data: localConsentNoticeConfig, isLoading: loadingLocalNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { data: regulations } = useRegulations();

  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);

  const updateConsentNoticeConfigPlatform = () => {
    const changesToApply = [
      { path: 'platform', value: platformToUpdate },
      {
        path: 'regulation_configurations',
        value: updateRegulationConfigsEnablement(localConsentNoticeConfig.regulation_configurations, regulations.data, { platform: platformToUpdate }),
      },
      // Set ui.version to 2.0.0 when switching to the web platform
      ...(platformToUpdate === 'web' ? [{ path: 'config.ui.version', value: '2.0.0' }] : []),
    ];

    updateLocalConfig(produceListOfChanges(changesToApply, localConsentNoticeConfig));
    setPlatformToUpdate(null);
  };

  return (
    <>
      <button
        id="menu-platform"
        disabled={loadingLocalNoticeConfig || !isCMPEditor}
        data-testid="menu-platform-button"
        data-skeleton={loadingLocalNoticeConfig}
        className="flex gap-xxxs rounded !outline-none focus-within:(ring-[3px] !ring-primary-blue-2) group~platform_change aria-expanded:text-secondary-cobalt-blue-3"
      >
        <strong>Platform</strong>
        <span>
          {CONSENT_NOTICES_PLATFORMS_DESC.get(localConsentNoticeConfig?.platform)?.displayName || '-'}
          {isCMPEditor && <DidomiIcon name="down-on-sm" className="ml-xxxs rotate-0 transition group~platform_change-aria-expanded:rotate-180" />}
        </span>
      </button>
      <DidomiMenu for="menu-platform" placement="bottom" menuTitle="Change platform">
        <DidomiMenuItem iconName="consent-type-website" className="!mt-xxs" disabled={localConsentNoticeConfig?.platform === 'web'} onClick={() => setPlatformToUpdate('web')}>
          Website
        </DidomiMenuItem>
        <DidomiMenuItem iconName="consent-type-amp" disabled={localConsentNoticeConfig?.platform === 'amp'} onClick={() => setPlatformToUpdate('amp')}>
          AMP website
        </DidomiMenuItem>
        <DidomiMenuItem iconName="consent-type-mobile" disabled={localConsentNoticeConfig?.platform === 'app'} onClick={() => setPlatformToUpdate('app')}>
          Mobile APP
        </DidomiMenuItem>
        {hasCTVFeature && (
          <DidomiMenuItem iconName="consent-type-ctv" disabled={localConsentNoticeConfig?.platform === 'ctv'} onClick={() => setPlatformToUpdate('ctv')}>
            CTV APP
          </DidomiMenuItem>
        )}
      </DidomiMenu>
      <ConfirmPlatformChangeModal
        isOpen={!!platformToUpdate}
        platform={platformToUpdate}
        onCancel={() => setPlatformToUpdate(null)}
        onConfirm={() => updateConsentNoticeConfigPlatform()}
      />
    </>
  );
};
