import React from 'react';
import { DidomiChip } from '@didomi/ui-atoms-react';

interface IntegrationDescriptionProps {
  compatibleWith: string[];
  content: React.ReactNode;
}

export const IntegrationDescription = ({ compatibleWith, content }: IntegrationDescriptionProps) => (
  <>
    <div className="mb-xxs">
      Compatible with
      <ul className="inline">
        {compatibleWith.map(label => (
          <li key={label} className="inline">
            {' '}
            <DidomiChip variation="basic-outline" label={label} />
          </li>
        ))}
      </ul>
    </div>
    {content}
  </>
);
