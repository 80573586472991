import { PrivacySignalsConfigUi } from '@types';

export const isSignalMatchesRegulationsFilter = (signal: PrivacySignalsConfigUi, regulationsFilter: string[]): boolean => {
  // if regulationsFilter is empty, return true - signal matches
  if (regulationsFilter.length === 0) {
    return true;
  }

  return signal.regulationIds?.some(rId => regulationsFilter.includes(rId));
};
