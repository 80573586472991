import { PrivacySignalsConfigUi } from '@types';

interface SignalsUiGroups {
  signalsWithoutSpis: PrivacySignalsConfigUi[];
  signalsWithSpis: Record<string, PrivacySignalsConfigUi[]>;
}

/*
 * Transform list of privacy signals into object with two groups:
 * - list of privacy signals that don't have subitems(SPIs)
 * - record of privacy signals that have subitems(SPIs)
 * That transformation represents the structure that is displayed in the UI of V&P page
 */
export const getPrivacySignalsConfigsUiGroups = (privacySignals: PrivacySignalsConfigUi[]): SignalsUiGroups => {
  return privacySignals.reduce<SignalsUiGroups>(
    (acc, signal) => {
      const isSpi = !!signal.namespaces.gpp;
      if (isSpi) {
        acc.signalsWithSpis[signal.section] = (acc.signalsWithSpis[signal.section] || []).concat([signal]);
      } else {
        acc.signalsWithoutSpis.push(signal);
      }

      return acc;
    },
    { signalsWithoutSpis: [], signalsWithSpis: {} },
  );
};
