import { ConsentNoticeConfigRegulation } from '@types';

export const getIsPurposesEnableAllSelected = (regulationConfig: ConsentNoticeConfigRegulation): boolean => {
  // For GDPR regulation, enableAllButtons option is turned on by default when IAB TCF is enabled
  if (regulationConfig?.regulation_id === 'gdpr' && regulationConfig?.config?.app?.vendors.iab.enabled) {
    return true;
  }

  // If option is set in the regulation config, use it. Otherwise, default to true
  return regulationConfig?.config?.preferences?.enableAllButtons !== undefined ? regulationConfig?.config?.preferences?.enableAllButtons : true;
};
