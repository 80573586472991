import { Regulation } from '@didomi/cmp-generator';
import { Icons } from '@didomi/ui-foundation';

// TODO: Remove this enum when the additional US regulations become available in the cmp-generator
enum AdditionalRegulations {
  VCDPA = 'vcdpa',
  TDPSA = 'tdpsa',
  OCPA = 'ocpa',
  FDBR = 'fdbr',
  MCDPA = 'mcdpa',
  NHPA = 'nhpa',
  ICDPA = 'icdpa',
  DPDPA = 'dpdpa',
  NJDPA = 'njdpa',
}

export const REGULATION_DICTIONARY: Record<Regulation | AdditionalRegulations, { titleText: string; icon: Icons; iconMd: Icons }> = {
  cpra: {
    titleText: 'CPRA',
    icon: 'cpra',
    iconMd: 'cpra-md',
  },
  gdpr: {
    titleText: 'GDPR',
    icon: 'gdpr_canada',
    iconMd: 'gdpr-md',
  },
  lgpd: {
    titleText: 'LGPD',
    icon: 'lgpd',
    iconMd: 'lgpd-md',
  },
  vcdpa: {
    titleText: 'VCDPA',
    icon: 'vcdpa',
    iconMd: 'vcdpa-md',
  },
  cpa: {
    titleText: 'CPA',
    icon: 'cpa',
    iconMd: 'cpa-md',
  },
  ctdpa: {
    titleText: 'CTDPA',
    icon: 'ctdpa',
    iconMd: 'ctdpa-md',
  },
  ucpa: {
    titleText: 'UCPA',
    icon: 'ucpa',
    iconMd: 'ucpa-md',
  },
  tdpsa: {
    titleText: 'TDPSA',
    icon: 'tdpsa',
    iconMd: 'tdpsa-md',
  },
  ocpa: {
    titleText: 'OCPA',
    icon: 'ocpa',
    iconMd: 'ocpa-md',
  },
  fdbr: {
    titleText: 'FDBR',
    icon: 'fdbr',
    iconMd: 'fdbr-md',
  },
  mcdpa: {
    titleText: 'MCDPA',
    icon: 'mcdpa',
    iconMd: 'mcdpa-md',
  },
  nhpa: {
    titleText: 'NHPA',
    icon: 'nhpa',
    iconMd: 'nhpa-md',
  },
  icdpa: {
    titleText: 'ICDPA',
    icon: 'icdpa',
    iconMd: 'icdpa-md',
  },
  dpdpa: {
    titleText: 'DPDPA',
    icon: 'dpdpa',
    iconMd: 'dpdpa-md',
  },
  njdpa: {
    titleText: 'NJDPA',
    icon: 'njdpa',
    iconMd: 'njdpa-md',
  },
};
