import React from 'react';
import { DidomiCheckbox } from '@didomi/ui-atoms-react';
import { PrivacySignalSupportedRegulations } from '@components';

interface PrivacySignalCheckboxCardProps {
  fieldId: string;
  label: string;
  value: boolean;
  regulationIds: string[];
  disabled?: boolean;
  onValueChange: (value: boolean) => void;
}

export const PrivacySignalCheckboxCard = ({ fieldId, label, value, regulationIds, disabled, onValueChange }: PrivacySignalCheckboxCardProps) => {
  return (
    <div className="flex items-center p-xs mb-xs bg-white rounded">
      <div className="mr-auto">
        <label className="grow block mb-xxxs text-body-small font-semibold text-primary-blue-6" htmlFor={fieldId}>
          {label}
        </label>
        <PrivacySignalSupportedRegulations regulationIds={regulationIds} />
      </div>

      <DidomiCheckbox data-testid={fieldId} checkbox-id={fieldId} checked={value} disabled={disabled} onValueChange={e => onValueChange(e.detail)} hideErrorMessage />
    </div>
  );
};
