import { PrivacySignalsConfig } from '@didomi/cmp-generator';
import { getPrivacySignalConfigUniqueName } from './signalsHelpers';

// Get purposes ids that are mapped to privacy signals. If signalToExclude is provided - it's purposes will not be taken into account
export const getPurposesMappedToSignals = (signalConfigs: PrivacySignalsConfig[], signalToExclude?: PrivacySignalsConfig) => {
  return signalConfigs.reduce<Set<string>>((acc, signalConfig) => {
    if (!signalToExclude || getPrivacySignalConfigUniqueName(signalConfig) !== getPrivacySignalConfigUniqueName(signalToExclude)) {
      signalConfig.purposes.forEach(pId => acc.add(pId));
    }
    return acc;
  }, new Set());
};
