import React, { useMemo } from 'react';
import { Regulation } from '@didomi/cmp-generator';
import { DidomiIcon, DidomiTabHeader, DidomiTabHeaders, DidomiTabHeading, DidomiTabPanel, DidomiTabPanels, DidomiTabs } from '@didomi/ui-atoms-react';
import { ExpandableSection, ExpandableSectionToggle, CustomizationFirstLayer, CustomizationSecondLayer, CustomizationThirdLayer } from '@components';
import { useCustomizationRegulationConfig, useCustomizationStepOptions, useLocalRegulationConfig, useSpiState, useLocalConsentNoticeConfig } from '@hooks';
import { NoticeLayer } from '@types';
import { REGULATION_DICTIONARY, getDefaultGdprConfigIab } from '@utils';
import { CustomizationSpiLayer } from '../CustomizationSpiLayer/CustomizationSpiLayer';

interface CustomizationRegulationContentProps {
  noticeId: string;
  regulationId: Regulation;
}

export const CustomizationRegulationContent = ({ noticeId, regulationId }: CustomizationRegulationContentProps) => {
  const { activeRegulationIds, regulationConfig, isLoading } = useLocalRegulationConfig(noticeId, regulationId);
  const { data: localConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);

  const { contentEditorLayer, setContentEditorLayer, previewRegulation, setPreviewRegulation } = useCustomizationStepOptions({
    activeRegulationIds,
  });
  const { hasSPI } = useCustomizationRegulationConfig(regulationId, localConsentNoticeConfig);
  const { spiGroups } = useSpiState({
    selectedVendorsIds: regulationConfig?.config?.app?.vendors?.include,
    regulationId,
    isTcfEnabled: getDefaultGdprConfigIab(localConsentNoticeConfig)?.enabled,
    enabled: hasSPI && !!regulationConfig,
  });
  const isSpiLayerVisible = hasSPI && spiGroups.length > 0 && localConsentNoticeConfig?.platform !== 'app';

  const tabs: NoticeLayer[] = useMemo(() => {
    return isSpiLayerVisible ? ['notice', 'purposes', 'spi', 'vendors'] : ['notice', 'purposes', 'vendors'];
  }, [isSpiLayerVisible]);

  const activeTab = useMemo(() => {
    const tabIndex = tabs.findIndex(t => t === contentEditorLayer);
    // default to the first tab in case tab index wasn't found
    return Math.max(0, tabIndex);
  }, [tabs, contentEditorLayer]);

  return (
    <ExpandableSection
      id={regulationId}
      variant="top-level"
      header={
        <div className="flex items-center gap-xs" data-testid={isLoading ? 'regulation-content-loading' : 'regulation-content-loaded'}>
          <DidomiIcon aria-hidden name={REGULATION_DICTIONARY[regulationId].iconMd} />
          <div className="font-semibold">{REGULATION_DICTIONARY[regulationId]?.titleText}</div>
          <ExpandableSectionToggle className="ml-auto" withBorder={false} a11yLabel={regulationId} />
        </div>
      }
      expanded={previewRegulation === regulationId}
      onToggleExpanded={expanded => {
        if (expanded) {
          setPreviewRegulation(regulationId);
        }
      }}
    >
      <DidomiTabs
        className="mt-xs"
        activeTab={activeTab}
        onActiveTabChange={e => {
          e.stopImmediatePropagation();
          setContentEditorLayer(tabs[e?.detail?.active]);
        }}
      >
        <DidomiTabHeading variant="sub-level" align="right">
          <DidomiTabHeaders>
            <DidomiTabHeader name="1st Layer"></DidomiTabHeader>
            <DidomiTabHeader name="Purposes"></DidomiTabHeader>
            {isSpiLayerVisible && <DidomiTabHeader name="SPI"></DidomiTabHeader>}
            <DidomiTabHeader name="Vendors"></DidomiTabHeader>
          </DidomiTabHeaders>
        </DidomiTabHeading>

        <DidomiTabPanels>
          <DidomiTabPanel className="pt-xs">
            <CustomizationFirstLayer regulationId={regulationId} noticeId={noticeId} />
          </DidomiTabPanel>
          <DidomiTabPanel className="pt-xs">
            <CustomizationSecondLayer regulationId={regulationId} noticeId={noticeId} />
          </DidomiTabPanel>
          {isSpiLayerVisible && (
            <DidomiTabPanel className="pt-xs">
              <CustomizationSpiLayer regulationId={regulationId} noticeId={noticeId} />
            </DidomiTabPanel>
          )}
          <DidomiTabPanel className="pt-xs">
            <CustomizationThirdLayer regulationId={regulationId} noticeId={noticeId} />
          </DidomiTabPanel>
        </DidomiTabPanels>
      </DidomiTabs>
    </ExpandableSection>
  );
};
