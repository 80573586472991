import { PrivacySignalsConfig } from '@didomi/cmp-generator';
import { PrivacySignal } from '@types';
import { getUniqueSignalNamesForStateId } from './getUniqueSignalNamesForStateId';
import { GPP_SIGNALS_SUBITEMS_DATA } from './signalsHelpers';

// As we are storing state id + usnat signals in the regulation config, we need to filter out the signals that are not available for mapping for selected state id
export const getSignalConfigsAvailableForMappingForStateId = (signals: PrivacySignal[], storedSignalConfigs: PrivacySignalsConfig[], gppStateId: string | null) => {
  const signalsForCurrentStateId = getUniqueSignalNamesForStateId(signals, gppStateId);

  return storedSignalConfigs.filter(signalConfig => {
    // Check if signal belongs to the current state id
    if (!signalsForCurrentStateId.has(signalConfig.section)) {
      return false;
    }

    // If signal is subitem - check if it is available for current state id
    if (signalConfig.namespaces.gpp) {
      const stateSubitems = GPP_SIGNALS_SUBITEMS_DATA[signalConfig.section]?.getItems?.(gppStateId);
      return stateSubitems?.includes(signalConfig.namespaces.gpp);
    }

    return true;
  });
};
