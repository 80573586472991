import { useMemo } from 'react';
import { Regulation, Regulations, Platform } from '@didomi/cmp-generator';
import { useReleaseFlag } from '@didomi/utility-react';
import { GCM_SUPPORTED_PLATFORMS, GOOGLE_AD_MANAGER_SUPPORTED_PLATFORMS, GPP_SUPPORTED_PLATFORMS } from '@utils';

// Helper hook to determine integrations that are available
export const useIntegrationsAvailability = ({
  platform,
  activeRegulationIds,
  isIabEnabled,
  isStandardAtpListSelected,
}: {
  platform: Platform;
  activeRegulationIds: Regulation[];
  isIabEnabled: boolean;
  isStandardAtpListSelected?: boolean;
}) => {
  const [hasGppRelease] = useReleaseFlag('gpp_privacy_signals');

  // Determine availability of ads integrations
  const adsIntegrationsAvailability = useMemo(() => {
    const isGdprEnabled = activeRegulationIds.includes(Regulations.GDPR);
    return {
      iab: isGdprEnabled,
      gpp: hasGppRelease && GPP_SUPPORTED_PLATFORMS.includes(platform),
      gcm: isGdprEnabled && GCM_SUPPORTED_PLATFORMS.includes(platform),
      /* Google Ad Manager is hidden for all new notices (full_apt is true), and shown for old notices with full_atp set to false */
      googleAdManager: isIabEnabled && isStandardAtpListSelected && GOOGLE_AD_MANAGER_SUPPORTED_PLATFORMS.includes(platform),
    };
  }, [hasGppRelease, activeRegulationIds, platform, isIabEnabled, isStandardAtpListSelected]);

  // Determine if any ads integrations are available
  const isAdsIntegrationsAvailable = useMemo(() => {
    return Object.values(adsIntegrationsAvailability).some(Boolean);
  }, [adsIntegrationsAvailability]);

  const isTagManagerAvailable = platform === 'web';

  return {
    adsIntegrationsAvailability,
    isAdsIntegrationsAvailable,
    isTagManagerAvailable,
  };
};
