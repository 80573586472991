import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiCheckbox, DidomiChip, DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ExtendedPurpose } from '@types';
import { isIABPurpose } from '@utils';
import { SortableGrabHandler } from '../../SortableHelpers/SortableGrabHandler';

type NoticePurposeCardProps = {
  purpose: ExtendedPurpose;
  highlightTitle?: boolean;
  viewOnly?: boolean;
  hasEssentialPurposes?: boolean;
  isGDPRRegulation?: boolean;
  isMappedToSignal?: boolean;
  isHidden?: boolean;
  onRequiredChange?: (isRequired: boolean) => void;
};

export const NoticePurposeCard = ({
  purpose,
  highlightTitle,
  viewOnly,
  hasEssentialPurposes = true,
  isGDPRRegulation,
  isMappedToSignal,
  onRequiredChange,
  isHidden,
}: NoticePurposeCardProps) => {
  // For GDPR - custom purposes are purposes that are not IAB purposes.
  // For other regulations - all purposes are custom.
  const isCustomPurpose = isGDPRRegulation ? !isIABPurpose(purpose) : true;
  const canBeMarkedAsEssential = hasEssentialPurposes && isCustomPurpose;

  return (
    <div className="flex items-center bg-neutral-gray-1 p-xs rounded text-primary-blue-6">
      <SortableGrabHandler a11yText={'Drag ' + getTranslatedValue(purpose.description)} disabled={viewOnly} className="mr-xs" />

      {/* Card body */}
      <div className={tx('flex items-center grow', { 'opacity-60': isHidden })}>
        {isHidden && <DidomiIcon name="hide" aria-label="Hidden in the category" className="mr-xs" />}

        <div className={tx('text-body-small mr-auto', { 'font-bold': highlightTitle })} data-testid="purpose-description">
          {getTranslatedValue(purpose.description)}
        </div>

        {isMappedToSignal === true && <DidomiChip label="Mapped" basicType="success" rightIcon="check-sm" />}
        {isMappedToSignal === false && <DidomiChip label="Not mapped" basicType="warning" rightIcon="danger-light-sm" />}

        <div className="text-body-extra-small flex-shrink-0 ml-xs" data-testid="purpose-sdk-id">
          SDK ID: {purpose.sdk_id}
        </div>

        {canBeMarkedAsEssential && (
          <div className="flex items-center gap-1 pl-4 ml-4 border-l-1 border-solid border-neutral-gray-4">
            <DidomiTooltip className="leading-[0]" variant="helper" placement="top" contentElementId="essential-purpose-helper-text">
              <DidomiIcon name="helper-text" />
            </DidomiTooltip>

            <div className="flex gap-2 items-center">
              <label className="font-semibold text-body-extra-small" htmlFor={purpose.sdk_id}>
                Define as essential
              </label>
              <DidomiCheckbox
                checkboxId={purpose.sdk_id}
                data-testid={'essential-' + purpose.sdk_id}
                checked={purpose.required}
                hideErrorMessage
                disabled={viewOnly || isHidden}
                onValueChange={e => onRequiredChange(e.detail)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
