import React, { useEffect, useState } from 'react';
import { PrivacySignalsConfig } from '@didomi/cmp-generator';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiAutocompleteWithSearch, DidomiButton, DidomiButtonGroup, DidomiEmptyState, DidomiModal, DidomiModalActions, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Purpose } from '@types';

interface MapSignalToPurposeModalProps {
  isOpen: boolean;
  privacySignalConfig: PrivacySignalsConfig | undefined;
  purposes: Purpose[];
  purposesMappedToOtherSignals: Set<string>;
  onCancel: () => void;
  onSave: (selectedPurposesSdkIds: string[]) => void;
}

export const MapSignalToPurposeModal = ({ isOpen, privacySignalConfig, purposes, purposesMappedToOtherSignals, onCancel, onSave }: MapSignalToPurposeModalProps) => {
  const [selectedPurposesSdkIds, setSelectedPurposesSdkIds] = useState<string[]>(null);

  // Set the initial value of the autocomplete to the signal's purposes
  useEffect(() => setSelectedPurposesSdkIds(privacySignalConfig?.purposes), [isOpen, privacySignalConfig]);

  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const hasPurposes = purposes.length > 0;

  return (
    <DidomiModal isOpen={isOpen} data-testid="map-signal-modal" closable="true" onOpenChange={handleModalChange}>
      <div className={tx('text-body-extra-small text-primary-blue-6')}>Privacy Signal Mapping</div>
      <div className={tx('text-h3 text-primary-blue-6 font-bold font-serif mb-xxs')}>{privacySignalConfig?.namespaces.gpp || privacySignalConfig?.section}</div>

      <DidomiModalContent>
        <div>
          {hasPurposes && isOpen && (
            <>
              <div className={tx('mb-xs text-primary-blue-6')}>Add a mapping to this privacy signal</div>
              <DidomiAutocompleteWithSearch
                data-testid="gpp-signal-purposes-autocomplete"
                multi
                items={purposes}
                value={selectedPurposesSdkIds}
                valueField="sdk_id"
                searchField="description"
                searchFieldModifier={getTranslatedValue}
                secondaryLabelGetter={purpose => (purposesMappedToOtherSignals?.has(purpose.sdk_id) ? 'Mapped to other signal' : null)}
                label="Purposes"
                placeholder="Select or search for purposes"
                style={{ width: '400px' }}
                hideErrorMessage
                onValueChange={e => setSelectedPurposesSdkIds(e.detail as string[])}
              />
            </>
          )}
          {!hasPurposes && <DidomiEmptyState illustrationName="traces-no-match-found">There are no added purposes</DidomiEmptyState>}
        </div>
      </DidomiModalContent>

      <div>
        {hasPurposes && (
          <DidomiModalActions>
            <DidomiButtonGroup>
              <DidomiButton variant="secondary" onClick={onCancel}>
                Cancel
              </DidomiButton>
              <DidomiButton data-testid="save-signal-mapping" onClick={() => onSave(selectedPurposesSdkIds)}>
                Save
              </DidomiButton>
            </DidomiButtonGroup>
          </DidomiModalActions>
        )}
      </div>
    </DidomiModal>
  );
};
