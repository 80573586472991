import { useMemo } from 'react';
import { useReleaseFlag } from '@didomi/utility-react';
import { CUSTOMIZATION_CONFIG_PER_REGULATION, getRegulationGppMappingStatus, GppMappingStatus, isActiveDefaultRegulation } from '@utils';
import { useLocalConsentNoticeConfig } from './data/consent-notice-config-local/useLocalConsentNoticeConfig.hook';
import { usePrivacySignals } from './data/privacy-signals/usePrivacySignals.hook';
import { usePurposes } from './data/vendors-and-purposes/usePurposes.hook';
import { useVendors } from './data/vendors-and-purposes/useVendors.hook';
import { useVendorsWithOverrides } from './data/vendors-and-purposes/useVendorsWithOverrides.hook';

/**
 * Hook to determine GPP settings mapping status for each regulation
 * Available statuses:
 * - 'not-supported' when any of the below conditions is met:
 *   a) no release flag
 *   b) GPP is not enabled for the notice
 *   c) GPP signals mapping is not supported for the regulation
 *   d) there are not privacy signals for mapping for current gpp status id
 * - 'valid': all purposes are mapped to the signals
 * - 'invalid': some purposes are not mapped to the signals
 */
export const useRegulationsGppMappingStatus = (noticeId: string) => {
  const [hasGppRelease] = useReleaseFlag('gpp_privacy_signals');
  const { data: localConsentNoticeConfig, isLoading: isConfigLoading } = useLocalConsentNoticeConfig(noticeId);
  const isGppSupportedForNotice = !!hasGppRelease && !!localConsentNoticeConfig?.gpp_enabled;

  const { data: signals = [], isLoading: isSignalsLoading } = usePrivacySignals(undefined, undefined, { enabled: isGppSupportedForNotice });
  const { data: purposes = [], isLoading: isPurposesLoading } = usePurposes({ enabled: isGppSupportedForNotice });
  const { data: vendors = [], isLoading: isVendorsLoading } = useVendors(undefined, { enabled: isGppSupportedForNotice });
  const { isLoading: isOverridesLoading, getProcessedVendors } = useVendorsWithOverrides();

  const isDataLoading = isConfigLoading || isSignalsLoading || isPurposesLoading || isVendorsLoading || isOverridesLoading;

  // Determine GPP settings mapping status for each regulations
  const gppMappingStatusPerRegulation: Record<string, GppMappingStatus> = useMemo(() => {
    if (isDataLoading || isOverridesLoading || !localConsentNoticeConfig || !hasGppRelease) {
      return {};
    }

    const activeRegulations = localConsentNoticeConfig.regulation_configurations.filter(isActiveDefaultRegulation);
    const mappingStatus = activeRegulations.reduce<Record<string, GppMappingStatus>>((acc, regulation) => {
      const isGppSupportedForRegulation = CUSTOMIZATION_CONFIG_PER_REGULATION[regulation.regulation_id].hasGppPrivacySignals;

      if (isGppSupportedForNotice && isGppSupportedForRegulation) {
        const processedVendors = getProcessedVendors(vendors, regulation.regulation_id, regulation.config?.app?.vendors?.iab?.enabled);
        acc[regulation.regulation_id] = getRegulationGppMappingStatus(localConsentNoticeConfig, regulation, signals, purposes, processedVendors);
      } else {
        acc[regulation.regulation_id] = 'not-supported';
      }

      return acc;
    }, {});
    return mappingStatus;
  }, [localConsentNoticeConfig, signals, purposes, vendors, isDataLoading, isGppSupportedForNotice, hasGppRelease, isOverridesLoading, getProcessedVendors]);

  return {
    isLoading: isDataLoading,
    gppMappingStatusPerRegulation,
  };
};
