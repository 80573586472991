import React from 'react';
import { produce } from '@didomi/helpers';
import { DidomiTextInput, DidomiTextarea } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { useCustomizationRegulationConfig, useCustomizationStepOptions, useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useLocalRegulationConfig } from '@hooks';
import { ACCESS_POLICIES_CONFIG, INPUT_DEBOUNCE_TIME } from '@utils';

interface CustomizationThirdLayerProps {
  noticeId: string;
  regulationId: string;
}

// Vendors
export const CustomizationThirdLayer = ({ noticeId, regulationId }: CustomizationThirdLayerProps) => {
  const { data: localConsentNoticeConfig, isLoading: loadingNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { trafficLanguage } = useCustomizationStepOptions({ defaultTraffic: localConsentNoticeConfig?.config?.languages?.default });
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { regulationConfig, regulationConfigIndex } = useLocalRegulationConfig(noticeId, regulationId);
  const { hasPreferencesSubTextVendors } = useCustomizationRegulationConfig(regulationId, localConsentNoticeConfig);

  const preferencesContent = regulationConfig?.config?.preferences?.content;

  const handleMainVendorTextChange = (newText: string) => {
    if (!preferencesContent?.textVendors?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.textVendors.${trafficLanguage}`, newText || undefined),
    );
  };

  const handleAuthorizeVendorChange = (newPreferenceTitle: string) => {
    if (!preferencesContent?.authorizeVendors?.en && !newPreferenceTitle) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(
        localConsentNoticeConfig,
        `regulation_configurations[${regulationConfigIndex}].config.preferences.content.authorizeVendors.${trafficLanguage}`,
        newPreferenceTitle || undefined,
      ),
    );
  };

  const handleBlockAuthorizeVendorChange = (newText: string) => {
    if (!preferencesContent?.blockVendors?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.blockVendors.${trafficLanguage}`, newText || undefined),
    );
  };

  const handleMainVendorSubTextChange = (newText: string) => {
    if (!preferencesContent?.subTextVendors?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.subTextVendors.${trafficLanguage}`, newText || undefined),
    );
  };

  return (
    <div data-testid="customization-third-layer" className="flex flex-col gap-xs">
      <h3 className="text-body-normal font-semibold text-primary-pink-5">Vendors text</h3>

      <DidomiTextarea
        label="Main text"
        disabled={loadingNoticeConfig || !isCMPEditor}
        debounce={INPUT_DEBOUNCE_TIME}
        rows={8}
        placeholder={`You can set your consent preferences for every partner listed below individually. Click on a partner name to get more information on what it does, what data it is collecting and how it is using it.`}
        hideErrorMessage
        value={(preferencesContent?.textVendors && preferencesContent.textVendors[trafficLanguage]) || ''}
        onValueChange={e => handleMainVendorTextChange(e.detail)}
      />

      {/* Buttons */}
      <h3 className="text-body-normal font-semibold text-primary-pink-5 mt-xxs">Buttons</h3>

      <DidomiTextInput
        label="Authorize"
        placeholder="Authorize"
        debounce={INPUT_DEBOUNCE_TIME}
        disabled={loadingNoticeConfig || !isCMPEditor}
        hideErrorMessage
        value={(preferencesContent?.authorizeVendors && preferencesContent.authorizeVendors[trafficLanguage]) || ''}
        onValueChange={e => handleAuthorizeVendorChange(e.detail)}
      />

      <DidomiTextInput
        label="Block"
        placeholder="Block"
        debounce={INPUT_DEBOUNCE_TIME}
        disabled={loadingNoticeConfig || !isCMPEditor}
        hideErrorMessage
        value={(preferencesContent?.blockVendors && preferencesContent.blockVendors[trafficLanguage]) || ''}
        onValueChange={e => handleBlockAuthorizeVendorChange(e.detail)}
      />

      {/* Optional text */}
      {hasPreferencesSubTextVendors && (
        <>
          <h3 className="text-body-normal font-semibold text-primary-pink-5 mt-xxs">Optional text</h3>
          <DidomiTextarea
            label="Text between the list of vendors and the footer"
            debounce={INPUT_DEBOUNCE_TIME}
            disabled={loadingNoticeConfig || !isCMPEditor}
            rows={8}
            placeholder="Type your text here..."
            hideErrorMessage
            value={(preferencesContent?.subTextVendors && preferencesContent.subTextVendors[trafficLanguage]) || ''}
            onValueChange={e => handleMainVendorSubTextChange(e.detail)}
          />
        </>
      )}
    </div>
  );
};
