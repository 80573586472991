import React, { useState } from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiChip, DidomiEmptyState, DidomiHintbox, DidomiLoader } from '@didomi/ui-atoms-react';
import { ExpandableSection, ExpandableSectionToggle } from '@components';
import { VendorsSpiModal } from '@modals';
import { VendorsSpiGroup } from '@types';
import { VendorsSpiCard } from '../VendorsSpiCard/VendorsSpiCard';

type VendorsSpiListProps = {
  className?: string;
  isInitiallyExpanded?: boolean;
  isLoading: boolean;
  vendorsSpiGroups: VendorsSpiGroup[];
  withRegulations?: boolean;
};

export const VendorsSpiList = ({ className, isInitiallyExpanded, isLoading, vendorsSpiGroups, withRegulations }: VendorsSpiListProps) => {
  const [vendorsSpiModalData, setVendorsSpiModalData] = useState<VendorsSpiGroup>(null);
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  return (
    <section className={className}>
      <ExpandableSection
        id="spi-list"
        expanded={isInitiallyExpanded}
        variant="top-level"
        header={
          <div className="flex items-center gap-xs">
            <img alt="" src={`${ASSETS_URL}/assets/illustrations/vendors/spi.svg`} />
            <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4">Sensitive Personal Information</h2>
            {withRegulations && <DidomiChip label="Will apply only to US regulations" basic-type="error" leftIcon="warning-sm-white" />}
            <ExpandableSectionToggle a11yLabel="Sensitive Personal Information" className="ml-auto" />
          </div>
        }
      >
        <div className="pt-s">
          <DidomiHintbox iconName="warning" className="mb-xs" variant="warning">
            Sensitive Personal Information (SPI) can be added in the Purpose section and managed in the notice to enable all purpose-related features.
          </DidomiHintbox>

          <DidomiHintbox className="mb-xs" titleText="Collection of Sensitive Personal Information (Opt-in regulation)">
            If you have selected a vendor that processes a Sensitive Personal Information the corresponding data type will be displayed automatically.
          </DidomiHintbox>

          <ul data-testid="spi-list" className="grid xl:grid-cols-2 gap-xxs">
            {vendorsSpiGroups.map(spiGroup => (
              <li key={spiGroup.id}>
                <VendorsSpiCard className="h-full" vendorsSpiGroup={spiGroup} withRegulations={withRegulations} onSeeVendorsClicked={() => setVendorsSpiModalData(spiGroup)} />
              </li>
            ))}
          </ul>

          {/* Empty State */}
          {!isLoading && !vendorsSpiGroups.length && (
            <DidomiEmptyState illustration-name="traces-no-result-found">
              <div slot="title">It&apos;s empty here!</div>
              Your selected vendors don&apos;t collect any Sensitive Personal Information
            </DidomiEmptyState>
          )}

          {/* Loading State */}
          {isLoading && (
            <div className="w-full flex flex-col items-center justify-center">
              <DidomiLoader className="mb-xs" />
              <div className="text-body-normal text-primary-blue-5">Loading Sensitive Personal Information</div>
            </div>
          )}
        </div>
      </ExpandableSection>

      <VendorsSpiModal
        isOpen={!!vendorsSpiModalData}
        spiDescription={getTranslatedValue(vendorsSpiModalData?.description)}
        vendorsNames={vendorsSpiModalData?.vendorsNames}
        onClose={() => setVendorsSpiModalData(null)}
      />
    </section>
  );
};
