import React from 'react';
import { DidomiSwitch } from '@didomi/ui-atoms-react';
import { NoticeClosingCard } from '@components';

type NoticeOption =
  | 'disagree-and-close'
  | 'continue-without-agreeing'
  | 'display-x'
  | 'bulk-action'
  | 'granular-consent'
  | 'sticky-buttons'
  | 'toggle-button'
  | 'c&l-interest-button'
  | 'hide-notice'
  | 'hide-vendors';

interface NoticeClosingSwitchProps {
  image?: NoticeOption;
  value: boolean;
  'data-testid'?: string;
  disabled?: boolean;
  onValueChange: (event: CustomEvent<any>) => void;
  children?: React.ReactNode;
}

export const NoticeClosingSwitch = ({ image, 'data-testid': dataTestId, value, children, disabled, onValueChange }: NoticeClosingSwitchProps) => {
  return (
    <NoticeClosingCard isSelected={value} image={image}>
      <DidomiSwitch data-testid={dataTestId} value={value ? 'selected' : 'not-selected'} hideErrorMessage labelVariant="no-box" disabled={disabled} onValueChange={onValueChange}>
        <span className="text-body-small text-primary-blue-6">{children}</span>
      </DidomiSwitch>
    </NoticeClosingCard>
  );
};
