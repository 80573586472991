import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { ExistingVendorPurposeOverride, PurposeVariantPath } from '@types';
import { useFetchAllPaginatedResults } from '../../useFetchAllPaginatedResults.hook';

const usePurposesOverridesQuery = (path: PurposeVariantPath) => {
  const { fetchAllPaginatedResults } = useFetchAllPaginatedResults();
  const { organizationId } = useActiveOrganization();
  return useQuery<ExistingVendorPurposeOverride[], AxiosError>(path, () =>
    fetchAllPaginatedResults<ExistingVendorPurposeOverride>(`metadata/${path}?organization_id=${organizationId}&is_org_override=true`),
  );
};

export const useVendorsPurposesOverrides = () => {
  const defaultPurposesQuery = usePurposesOverridesQuery('partners-default-purposes');
  const legIntPurposesQuery = usePurposesOverridesQuery('partners-legitimate-interest-purposes');

  return {
    data: {
      purposeOverrides: defaultPurposesQuery.data || [],
      legIntPurposeOverrides: legIntPurposesQuery.data || [],
    },
    isLoading: defaultPurposesQuery.isLoading || legIntPurposesQuery.isLoading,
    isFetched: defaultPurposesQuery.isFetched || legIntPurposesQuery.isFetched,
    isError: defaultPurposesQuery.isError || legIntPurposesQuery.isError,
    error: defaultPurposesQuery.error || legIntPurposesQuery.error,
  };
};
