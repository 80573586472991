import React, { useContext, useEffect } from 'react';
import {
  DidomiSkeleton,
  DidomiTabs,
  DidomiTabHeading,
  DidomiTabHeaders,
  DidomiTabHeader,
  DidomiTabPanels,
  DidomiTabPanel,
  DidomiIcon,
  DidomiMenu,
  DidomiMenuItem,
  DidomiBottomBar,
  DidomiButton,
} from '@didomi/ui-atoms-react';
import { useCustomFeature, useHasAccessPolicies } from '@didomi/utility-react';
import { useParams, useNavigate } from 'react-router-dom';
import { LookAndFeel, ContentEditor, CookiesAndStorage, Integrations } from '@components';
import { useLocalConsentNoticeConfig, useCustomizationStepOptions, useLocalRegulationConfig, useIntegrationsAvailability } from '@hooks';
import { NoticeLayoutContext } from '@layouts';
import { ACCESS_POLICIES_CONFIG, getDefaultGdprConfigIab } from '@utils';

/**
 * Consent Notice customization page
 */
export const ConsentNoticeCustomizationPage = (): JSX.Element => {
  const { noticeId } = useParams();
  const navigate = useNavigate();
  const { saveNoticeChanges, loading, saving } = useContext(NoticeLayoutContext);
  const { hasAccess: isCMPEditor, isLoading: loadingAccessPolicy } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const { data: localConsentNoticeConfig, isLoading: loadingLocalConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { activeRegulationIds } = useLocalRegulationConfig(noticeId);
  // TODO: refactor component+tests to use Notice Config Facade
  const { isAdsIntegrationsAvailable, isTagManagerAvailable } = useIntegrationsAvailability({
    activeRegulationIds,
    isIabEnabled: getDefaultGdprConfigIab(localConsentNoticeConfig)?.enabled,
    platform: localConsentNoticeConfig?.platform,
  });

  const { customizationStep, setCustomizationStep } = useCustomizationStepOptions();

  const handleSave = async () => {
    const isSaved = await saveNoticeChanges();

    if (isSaved) {
      navigate('/' + noticeId + '/publish');
    }
  };

  const isLoading = loading || loadingLocalConsentNoticeConfig || loadingAccessPolicy;

  const isIntegrationsAvailable = isAdsIntegrationsAvailable || isTagManagerAvailable;
  const isMobileAppPlatform = localConsentNoticeConfig?.platform === 'app';
  const [hasWebPreviewForAppNotices] = useCustomFeature('web-preview-for-app-notices');
  const isCustomCssAvailable = ['web', 'amp'].includes(localConsentNoticeConfig?.platform) || (isMobileAppPlatform && hasWebPreviewForAppNotices);

  useEffect(() => {
    // If user are on Integrations tab but it is not available, go back to the first tab
    if (localConsentNoticeConfig && customizationStep === 2 && !isIntegrationsAvailable) {
      setCustomizationStep(0);
    }
  }, [customizationStep, setCustomizationStep, isIntegrationsAvailable, localConsentNoticeConfig]);

  return (
    <>
      <DidomiSkeleton data-testid="page-skeleton" isLoading={isLoading} variant="layout" className="flex flex-col flex-1 w-full h-full">
        <div className="flex-1 h-full w-full flex flex-col relative">
          <div className="absolute top-0 right-0 p-[2px] z-20">
            <button
              id="advance-settings"
              className="text-body-extra-small font-semibold text-primary-blue-6 px-xs py-xxxs !pr-0 !outline-none rounded-md hover:(!text-primary-blue-4) focus:(ring-[3px] ring-primary-blue-2)"
            >
              <div className="flex items-center" data-skeleton>
                Advanced settings
                <DidomiIcon name="settings" className="ml-xxs" />
              </div>
            </button>

            <DidomiMenu for="advance-settings" variant="main" distance="xxs">
              <div>
                <DidomiMenuItem data-tracking="custom-json" data-testid="custom-json" onItemSelected={() => navigate(`/${noticeId}/custom-json`)}>
                  Custom JSON
                </DidomiMenuItem>
                {isCustomCssAvailable && (
                  <DidomiMenuItem data-tracking="custom-css" data-testid="custom-css" onItemSelected={() => navigate(`/${noticeId}/custom-css`)}>
                    Custom CSS
                  </DidomiMenuItem>
                )}
              </div>
            </DidomiMenu>
          </div>
          <DidomiTabs activeTab={customizationStep} onActiveTabChange={e => setCustomizationStep(e?.detail?.active)}>
            <DidomiTabHeading variant="sub-level">
              <DidomiTabHeaders>
                <DidomiTabHeader name="Look & Feel"></DidomiTabHeader>
                <DidomiTabHeader name="Content editor"></DidomiTabHeader>
                <DidomiTabHeader name="Integrations" hidden={!isIntegrationsAvailable}></DidomiTabHeader>
                <DidomiTabHeader name="Cookies & storage" hidden={!!localConsentNoticeConfig && localConsentNoticeConfig?.platform !== 'web' ? true : undefined}></DidomiTabHeader>
              </DidomiTabHeaders>
            </DidomiTabHeading>
            <DidomiTabPanels className="py-[32px] !overflow-x-visible">
              <DidomiTabPanel>
                <LookAndFeel />
              </DidomiTabPanel>
              <DidomiTabPanel>
                <ContentEditor />
              </DidomiTabPanel>
              <DidomiTabPanel hidden={!isIntegrationsAvailable}>
                <Integrations />
              </DidomiTabPanel>
              <DidomiTabPanel hidden={!!localConsentNoticeConfig && localConsentNoticeConfig?.platform !== 'web' ? true : undefined}>
                <CookiesAndStorage />
              </DidomiTabPanel>
            </DidomiTabPanels>
          </DidomiTabs>
        </div>
      </DidomiSkeleton>

      {isCMPEditor && (
        <DidomiBottomBar className="-mx-l -mb-m flex-shrink-0" isOpen={true} variant="light" closable={false}>
          <div slot="actions" className="flex items-center gap-4">
            <DidomiButton iconLeft="left-on" variant="secondary" disabled={loading || saving} onClick={() => navigate('/' + noticeId)}>
              Previous
            </DidomiButton>
            <DidomiButton iconRight="right-on" disabled={loading || saving} onClick={handleSave}>
              Save & continue
            </DidomiButton>
          </div>
        </DidomiBottomBar>
      )}
    </>
  );
};
