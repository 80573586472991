import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type GcmRequiredModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const GcmRequiredModal = ({ isOpen, onClose }: GcmRequiredModalProps) => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <img className={tx('mb-4')} alt="" src={`${ASSETS_URL}/assets/illustrations/vendors/gcm-required.svg`} />
      <DidomiModalHeader variant="warning" modalTitle="Check Google Consent Mode" />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>
          <p className={tx('mb-4')}>
            As a reminder, our Google Consent Mode (GCM) integration only concerns both Google Analytics products and Google Advertising products vendors.
          </p>
          <p className={tx('font-semibold')}>At least one of these two vendors has to be enabled to use the GCM integration.</p>
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton onClick={onClose}>Ok, I got it</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
