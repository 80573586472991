import { PrivacySignalsConfigUi } from '@types';

// Extract unique regulationIds from the privacy signals configs
export const getAvailableRegulationsFromSignals = (privacySignalsConfig: PrivacySignalsConfigUi[] = []) => {
  // if privacySignalsConfig is empty or does not contain regulationIds, return an empty array
  if (privacySignalsConfig[0]?.regulationIds === undefined) {
    return [];
  }

  const regulationsIdsSet = privacySignalsConfig.reduce((acc, signal) => {
    signal.regulationIds.forEach(rId => acc.add(rId));
    return acc;
  }, new Set<string>());
  return Array.from(regulationsIdsSet);
};
