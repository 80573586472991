import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type AddMCMWarningModalProps = {
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Confirm before adding a Microsoft Vendor
 */
export const AddMCMWarningModal = ({ isOpen, onCancel, onProceed }: AddMCMWarningModalProps): JSX.Element => {
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const modalTitle = 'A vendor will be added';

  return (
    <DidomiModal isOpen={isOpen} onOpenChange={handleOnChange}>
      <DidomiIllustration name="add-microsoft-vendor" style={{ marginBottom: 'var(--space-m)' }} />
      <DidomiModalHeader variant="warning" modalSubTitle="Please pay attention" modalTitle={modalTitle}></DidomiModalHeader>

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6 max-w-[330px]')}>
          <p>
            In order to use the Microsoft Consent Mode integration the <strong>Microsoft Advertising vendor</strong> needs to be added to the notice.
          </p>
        </div>
      </DidomiModalContent>

      <DidomiModalActions className={tx('!pt-5')}>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onProceed}>Ok, add the vendor</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
