import React, { useContext } from 'react';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { SortableItemContext } from './SortableListItem';

type SortableGrabHandlerProps = {
  className?: string;
  a11yText?: string;
  disabled?: boolean;
};

export const SortableGrabHandler = ({ className, a11yText, disabled }: SortableGrabHandlerProps) => {
  const context = useContext(SortableItemContext);

  return (
    <button
      disabled={disabled}
      className={tx('leading-[0] focus:outline-none focus-visible:shadow-input-focused', { 'cursor-not-allowed': disabled }, className)}
      aria-label={a11yText}
      {...context?.handlerProps}
    >
      <DidomiIcon aria-hidden="true" name="grab" />
    </button>
  );
};
