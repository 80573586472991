export interface CustomizationConfig {
  hasNoticeFormat: boolean;
  hasStickyButtons: boolean;
  hasNegativeActionButton: boolean;
  hasContinueWithoutAgreeing: boolean;
  hasDisplayX: boolean;
  hasTextStyles: boolean;

  hasStandardText: boolean;
  hasDismiss: boolean;
  hasDeny: boolean;
  hasLearnMore: boolean;

  hasPreferencesEnableAllButtons: boolean;
  hasPreferencesEnableBulkActionOnPurposes: boolean;
  hasPreferencesAgree: boolean;
  hasPreferencesDisagree: boolean;
  hasPreferencesViewAllPartners: boolean;
  hasPreferencesAgreeToAll: boolean;
  hasPreferencesDisagreeToAll: boolean;
  hasPreferencesSave: boolean;
  hasPreferencesSubText: boolean;
  hasUserInteractionsToggleButtons: boolean;

  hasPreferencesSubTextVendors: boolean;

  hasIabIntegration: boolean;

  hasSPI: boolean;
  hasEssentialPurposes: boolean;
  hasButtonsCustomizationPerPurposeCategory: boolean;

  localExceptions: string[];
  hasGppPrivacySignals: boolean;
  hasNoticeLayout: boolean;
}

export const baseUSConfig: CustomizationConfig = {
  hasNoticeFormat: true,
  hasStickyButtons: false,
  hasNegativeActionButton: false,
  hasContinueWithoutAgreeing: false,
  hasDisplayX: false,
  hasTextStyles: true,

  hasStandardText: false,
  hasDismiss: true,
  hasDeny: false,
  hasLearnMore: true,

  hasPreferencesEnableAllButtons: false,
  hasPreferencesEnableBulkActionOnPurposes: false,
  hasPreferencesAgree: true,
  hasPreferencesDisagree: true,
  hasPreferencesViewAllPartners: true,
  hasPreferencesAgreeToAll: false,
  hasPreferencesDisagreeToAll: false,
  hasPreferencesSave: true,
  hasPreferencesSubText: false,
  hasUserInteractionsToggleButtons: false,

  hasPreferencesSubTextVendors: false,

  hasIabIntegration: false,

  hasSPI: true,

  hasEssentialPurposes: true,
  hasButtonsCustomizationPerPurposeCategory: true,

  localExceptions: [],
  hasGppPrivacySignals: true,
  hasNoticeLayout: true,
};

export const baseUSConfigV2: CustomizationConfig = {
  ...baseUSConfig,
  hasSPI: false,
  hasPreferencesEnableAllButtons: true,
  hasPreferencesEnableBulkActionOnPurposes: true,
  hasUserInteractionsToggleButtons: true,
  hasPreferencesAgreeToAll: true,
  hasPreferencesDisagreeToAll: true,
  hasPreferencesSubText: true,
};

export const CUSTOMIZATION_CONFIG_PER_REGULATION: Record<string, CustomizationConfig> = {
  gdpr: {
    hasNoticeFormat: true,
    hasStickyButtons: true,
    hasNegativeActionButton: true,
    hasContinueWithoutAgreeing: true,
    hasDisplayX: true,
    hasTextStyles: true,

    hasStandardText: true,
    hasDismiss: true,
    hasDeny: true,
    hasLearnMore: true,

    hasPreferencesEnableAllButtons: true,
    hasPreferencesEnableBulkActionOnPurposes: true,
    hasPreferencesAgree: true,
    hasPreferencesDisagree: true,
    hasPreferencesViewAllPartners: true,
    hasPreferencesAgreeToAll: true,
    hasPreferencesDisagreeToAll: true,
    hasPreferencesSave: true,
    hasPreferencesSubText: true,
    hasUserInteractionsToggleButtons: true,

    hasPreferencesSubTextVendors: true,

    hasIabIntegration: true,

    hasSPI: false,

    hasEssentialPurposes: true,
    hasButtonsCustomizationPerPurposeCategory: false,

    localExceptions: ['IT', 'FR'],
    hasGppPrivacySignals: false,
    hasNoticeLayout: false,
  },
  lgpd: {
    hasNoticeFormat: true,
    hasStickyButtons: true,
    hasNegativeActionButton: true,
    hasContinueWithoutAgreeing: true,
    hasDisplayX: true,
    hasTextStyles: true,

    hasStandardText: false,
    hasDismiss: true,
    hasDeny: true,
    hasLearnMore: true,

    hasPreferencesEnableAllButtons: true,
    hasPreferencesEnableBulkActionOnPurposes: true,
    hasPreferencesAgree: true,
    hasPreferencesDisagree: true,
    hasPreferencesViewAllPartners: true,
    hasPreferencesAgreeToAll: true,
    hasPreferencesDisagreeToAll: true,
    hasPreferencesSave: true,
    hasPreferencesSubText: true,
    hasUserInteractionsToggleButtons: false,

    hasPreferencesSubTextVendors: true,

    hasIabIntegration: false,

    hasSPI: false,

    hasEssentialPurposes: true,
    hasButtonsCustomizationPerPurposeCategory: false,

    localExceptions: [],
    hasGppPrivacySignals: true,
    hasNoticeLayout: false,
  },
  cpra: {
    ...baseUSConfig,
  },
  vcdpa: {
    ...baseUSConfig,
  },
  cpa: {
    ...baseUSConfig,
  },
  ctdpa: {
    ...baseUSConfig,
  },
  ucpa: {
    ...baseUSConfig,
  },
  tdpsa: {
    ...baseUSConfig,
  },
  ocpa: {
    ...baseUSConfig,
  },
  fdbr: {
    ...baseUSConfig,
  },
  mcdpa: {
    ...baseUSConfig,
  },
  nhpa: {
    ...baseUSConfig,
  },
  icdpa: {
    ...baseUSConfig,
  },
  dpdpa: {
    ...baseUSConfig,
  },
  njdpa: {
    ...baseUSConfig,
  },
  default: {
    hasNoticeFormat: true,
    hasStickyButtons: false,
    hasNegativeActionButton: false,
    hasContinueWithoutAgreeing: false,
    hasDisplayX: false,
    hasTextStyles: true,

    hasStandardText: false,
    hasDismiss: true,
    hasDeny: false,
    hasLearnMore: false,

    hasPreferencesEnableAllButtons: false,
    hasPreferencesEnableBulkActionOnPurposes: false,
    hasPreferencesAgree: false,
    hasPreferencesDisagree: false,
    hasPreferencesViewAllPartners: true,
    hasPreferencesAgreeToAll: false,
    hasPreferencesDisagreeToAll: false,
    hasPreferencesSave: true,
    hasPreferencesSubText: false,
    hasUserInteractionsToggleButtons: false,

    hasPreferencesSubTextVendors: false,

    hasIabIntegration: false,

    hasSPI: false,

    hasEssentialPurposes: true,
    hasButtonsCustomizationPerPurposeCategory: false,

    localExceptions: [],
    hasGppPrivacySignals: false,
    hasNoticeLayout: false,
  },
};

export const CUSTOMIZATION_CONFIG_PER_REGULATION_V2: Record<string, CustomizationConfig> = {
  gdpr: {
    ...CUSTOMIZATION_CONFIG_PER_REGULATION.gdpr,
  },
  lgpd: {
    ...CUSTOMIZATION_CONFIG_PER_REGULATION.lgpd,
  },
  cpra: {
    ...baseUSConfigV2,
  },
  vcdpa: {
    ...baseUSConfigV2,
  },
  cpa: {
    ...baseUSConfigV2,
  },
  ctdpa: {
    ...baseUSConfigV2,
  },
  ucpa: {
    ...baseUSConfigV2,
  },
  tdpsa: {
    ...baseUSConfigV2,
  },
  ocpa: {
    ...baseUSConfigV2,
  },
  fdbr: {
    ...baseUSConfigV2,
  },
  mcdpa: {
    ...baseUSConfigV2,
  },
  nhpa: {
    ...baseUSConfigV2,
  },
  icdpa: {
    ...baseUSConfigV2,
  },
  dpdpa: {
    ...baseUSConfigV2,
  },
  njdpa: {
    ...baseUSConfigV2,
  },
  default: {
    ...CUSTOMIZATION_CONFIG_PER_REGULATION.default,
    hasSPI: false,
    hasPreferencesEnableAllButtons: true,
    hasPreferencesEnableBulkActionOnPurposes: true,
  },
};
