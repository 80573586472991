import { NoticeConfig, PrivacySignalsConfig, Regulation, RegulationConfigAggregator } from '@didomi/cmp-generator';
import { ConsentNoticeConfig, ConsentNoticeConfigRegulation, PrivacySignal, Purpose, Vendor } from '@types';
import { areAllPurposesMappedToPrivacySignals } from './areAllPurposesMappedToPrivacySignals';
import { getAllSelectedVendors } from './getAllSelectedVendors';
import { getPurposeCategoriesBasedOnVendors } from './getPurposeCategoriesBasedOnVendors';
import { getPurposesFromCategories } from './getPurposesFromCategories';
import { getRegulationConfigGppStateId } from './getRegulationConfigGppStateId';
import { getSignalConfigsAvailableForMappingForStateId } from './getSignalConfigsAvailableForMappingForStateId';
import { getSyncedGppSignalsSettings } from './getSyncedGppSignalsSettings';
import { getUniqueMappableSignals } from './getUniqueMappableSignals';

export type GppMappingStatus = 'valid' | 'invalid' | 'not-supported';

const getUptoDateStoredPurposes = (aggregatedConfig: NoticeConfig, allPurposes: Purpose[], allVendors: Vendor[]): Purpose[] => {
  // Get ids of all selected vendors
  const allSelectedVendors = getAllSelectedVendors(aggregatedConfig.config, allVendors);

  // Get categories based on the selected vendors - as `config.preferences.categories` might not be up to date(e.g when some purposes were added/removed from the Vendor)
  const categories = getPurposeCategoriesBasedOnVendors(aggregatedConfig.config.preferences?.categories || [], allSelectedVendors, allPurposes);

  // Get purposes from categories
  const purposesFromCategories = getPurposesFromCategories(categories);

  // Get valid purposes, and filter out purposes that could have been deleted
  const addedValidPurposes = purposesFromCategories.map(({ purposeId }) => allPurposes.find(p => p.sdk_id === purposeId)).filter(Boolean);

  return addedValidPurposes;
};

const getUpToDateStoredMappableSignals = (aggregatedConfig: NoticeConfig, allSignals: PrivacySignal[], regulationId: Regulation): PrivacySignalsConfig[] => {
  const storedPrivacySignals = aggregatedConfig?.regulation_settings?.gpp?.privacySignals || [];

  // Get state if for the regulation config
  const gppStateId = getRegulationConfigGppStateId(aggregatedConfig, allSignals, regulationId);

  // Get signals that belongs to current gpp state id
  const currentGppStateIdSignals = allSignals.filter(signal => signal.regulation_id === gppStateId);

  // Transform signals into signals configs(avoids duplications, get SPI)
  const uniqueMappableSignalsConfigs = getUniqueMappableSignals(currentGppStateIdSignals);

  // Get synced GPP signals settings that should be applied to the regulation config - as the stored settings might be missing some signals(or might have signals that were removed from API)
  const upToDateStoredSignals = getSyncedGppSignalsSettings(storedPrivacySignals, uniqueMappableSignalsConfigs);

  // Get signals that are available for mapping - so it can be used to check if all purposes are mapped
  const availableForMappingSignals = getSignalConfigsAvailableForMappingForStateId(currentGppStateIdSignals, upToDateStoredSignals, gppStateId);

  return availableForMappingSignals;
};

/**
 * Get GPP mapping status for a given regulation
 * 1. Get up to date stored purposes
 * 2. Get up to date stored mappable signals
 * 3. Check if all purposes are mapped to privacy signals
 *
 */
export const getRegulationGppMappingStatus = (
  noticeConfig: ConsentNoticeConfig,
  regulationConfig: ConsentNoticeConfigRegulation,
  allSignals: PrivacySignal[],
  allPurposes: Purpose[],
  allVendors: Vendor[],
): GppMappingStatus => {
  const aggregatedConfig = RegulationConfigAggregator.aggregateRegulationConfigs(noticeConfig, regulationConfig);
  // 1. Get up to date stored purposes
  const upToDateStoredMappableSignals = getUpToDateStoredMappableSignals(aggregatedConfig, allSignals, regulationConfig.regulation_id);

  // Handle case when there are no signals available for mapping - so mapping is not supported
  if (upToDateStoredMappableSignals.length === 0) {
    return 'not-supported';
  } else {
    // 2. Get up to date stored mappable signals
    const upToDateStoredPurposes = getUptoDateStoredPurposes(aggregatedConfig, allPurposes, allVendors);

    // 3. Finally we have all the data needed to check if all purposes are mapped to privacy signals
    // - we have all purposes that should be mapped
    // - we have all signals that are available for mapping
    return areAllPurposesMappedToPrivacySignals(upToDateStoredPurposes, upToDateStoredMappableSignals) ? 'valid' : 'invalid';
  }
};
