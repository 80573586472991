import React from 'react';
import { tx } from '@twind/core';
import { PrivacySignalsConfigUi } from '@types';
import { getPrivacySignalConfigUniqueName } from '@utils';
import { PrivacySignalMappingCard } from '../PrivacySignalMappingCard/PrivacySignalMappingCard';

interface PrivacySignalsForMappingListProps {
  className?: string;
  privacySignalsConfig: PrivacySignalsConfigUi[];
  viewOnly?: boolean;
  onEditClicked: (signal: PrivacySignalsConfigUi) => void;
}

export const PrivacySignalsForMappingList = ({ className, privacySignalsConfig, viewOnly, onEditClicked }: PrivacySignalsForMappingListProps) => {
  return (
    <ul className={tx(className, 'flex flex-col gap-xs')}>
      {privacySignalsConfig.map(signalWithMapping => (
        <li key={getPrivacySignalConfigUniqueName(signalWithMapping)}>
          <PrivacySignalMappingCard signalWithMapping={signalWithMapping} viewOnly={viewOnly} onEditClicked={() => onEditClicked(signalWithMapping)} />
        </li>
      ))}
    </ul>
  );
};
