import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, PrivacySignal } from '@types';

// Get signals for usnat+state(based on regulation id)
export const usePrivacySignals = (regulationId?: string, framework?: string, options?: UseQueryOptions<PrivacySignal[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchSignals = async () => {
    const url = new URL(`${CONFIG.environmentConfig.apiBaseUrl}compliance/v1/privacy-signals`);
    framework && url.searchParams.append('$search[framework]', framework);
    regulationId && url.searchParams.append('$search[regulationId]', `${regulationId},usnat`);
    const response = await axiosWithInterceptors.get<PaginatedResponse<PrivacySignal>>(url.toString());
    return response.data.data;
  };

  return useQuery<PrivacySignal[], AxiosError>(['privacy-signals', regulationId, framework], fetchSignals, options);
};
