import React, { useMemo, useState } from 'react';
import { PrivacySignalsConfig, Regulation } from '@didomi/cmp-generator';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton, DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';
import { BlockLoader, ExpandableSection, ExpandableSectionToggle } from '@components';
import { MapSignalToPurposeModal } from '@modals';
import { PrivacySignalsConfigUi, Purpose } from '@types';
import { getAvailableRegulationsFromSignals, isSignalMatchesRegulationsFilter, getPrivacySignalsConfigsUiGroups, getPurposesMappedToSignals } from '@utils';
import { PrivacySignalCheckboxCard } from '../PrivacySignalCheckboxCard/PrivacySignalCheckboxCard';
import { PrivacySignalsForMappingList } from '../PrivacySignalsForMappingList/PrivacySignalsForMappingList';
import { RegulationsFilter } from '../RegulationsFilter/RegulationsFilter';

interface GppSettingsProps {
  className?: string;
  isLoading?: boolean;
  showWarning?: boolean;
  privacySignalsConfig: PrivacySignalsConfigUi[];
  noticePurposes: Purpose[];
  viewOnly?: boolean;
  sharingNoticeWithThirdParty: { value: boolean | null; regulationIds?: string[] };
  gppSettingsInfo?: { stringType: Regulation | 'usnat'; isMspaEnabled: boolean };
  infoBanner: React.ReactChild;
  onMappingChange: (updatedPrivacySignal: PrivacySignalsConfigUi) => void;
  onShareNoticeWithThirdPartyChange: (value: boolean) => void;
  onApplyDefaultMappingClicked?: () => void;
}

export const GppSettings = ({
  className,
  isLoading,
  showWarning,
  privacySignalsConfig,
  noticePurposes,
  viewOnly,
  sharingNoticeWithThirdParty,
  infoBanner,
  gppSettingsInfo,
  onMappingChange,
  onShareNoticeWithThirdPartyChange,
  onApplyDefaultMappingClicked,
}: GppSettingsProps) => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');
  const [signalConfigForMappingModal, setSignalConfigForMappingModal] = useState<PrivacySignalsConfig>(null);
  const [regulationsFilter, setRegulationsFilter] = useState<string[]>([]);

  const handleMapSignalToPurpose = (selectedPurposedSdkIds: string[]) => {
    onMappingChange({ section: signalConfigForMappingModal.section, namespaces: signalConfigForMappingModal.namespaces, purposes: selectedPurposedSdkIds });
    setSignalConfigForMappingModal(null);
  };

  const purposesMappedToOtherSignals = useMemo(() => {
    if (!signalConfigForMappingModal) return null;
    return getPurposesMappedToSignals(privacySignalsConfig, signalConfigForMappingModal);
  }, [signalConfigForMappingModal, privacySignalsConfig]);

  const availableRegulationsIds = useMemo(() => getAvailableRegulationsFromSignals(privacySignalsConfig), [privacySignalsConfig]);

  const filteredSignals = useMemo(() => {
    return privacySignalsConfig.filter(signal => isSignalMatchesRegulationsFilter(signal, regulationsFilter));
  }, [privacySignalsConfig, regulationsFilter]);

  const filteredSignalsUiGroups = useMemo(() => getPrivacySignalsConfigsUiGroups(filteredSignals), [filteredSignals]);

  const isSharingNoticeSignalVisible = useMemo(() => {
    // The signal is visible only if it's present(so it's value is not null)
    if (sharingNoticeWithThirdParty.value === null) {
      return false;
    }

    // If regulations are provided, the signal is visible only if it matches the regulations filter
    if (regulationsFilter.length && sharingNoticeWithThirdParty.regulationIds?.length) {
      return sharingNoticeWithThirdParty.regulationIds.some(rId => regulationsFilter.includes(rId));
    }

    return true;
  }, [sharingNoticeWithThirdParty, regulationsFilter]);

  return (
    <ExpandableSection
      id="gpp-settings"
      className={className}
      expanded={false}
      variant="top-level"
      header={
        <div className="flex items-center gap-xs">
          <img alt="" src={`${ASSETS_URL}/assets/illustrations/vendors/gpp.svg`} />
          <h2 className="text-h2 font-bold text-secondary-cobalt-blue-4">
            GPP settings{' '}
            {showWarning && (
              <DidomiTooltip variant="helper" content="All purposes that are part of the notice must be mapped with at least one privacy signal.">
                <DidomiIcon data-testid="gpp-settings-warning" name="warning-sm" />
              </DidomiTooltip>
            )}
          </h2>
          <ExpandableSectionToggle a11yLabel="GPP settings" className="ml-auto" />
        </div>
      }
    >
      <div className="pt-s">
        <div className="mb-xs">{infoBanner}</div>

        {gppSettingsInfo?.stringType && (
          <div className="flex items-center p-xs gap-xxs mb-xs bg-white rounded text-primary-blue-6 font-semibold text-field-label">
            <div data-testid="gpp-string-info" className="bg-neutral-gray-1 p-xs rounded">
              String type generated: <span className="text-secondary-cobalt-blue-3">{gppSettingsInfo.stringType === 'usnat' ? 'National' : 'State'} string</span>
            </div>
            <div data-testid="gpp-mspa-signatory-info" className="bg-neutral-gray-1 p-xs rounded">
              MSPA settings: <span className="text-secondary-cobalt-blue-3">{gppSettingsInfo.isMspaEnabled ? 'On' : 'Off'}</span>
            </div>

            {!!onApplyDefaultMappingClicked && (
              <DidomiButton className="ml-auto" size="small" variant="secondary" iconRight="union-sm" onClick={onApplyDefaultMappingClicked}>
                Apply default mapping
              </DidomiButton>
            )}
          </div>
        )}

        {/* Filters are only visible if regulations are provided */}
        {!!availableRegulationsIds.length && (
          <RegulationsFilter allRegulations={availableRegulationsIds} selectedRegulations={regulationsFilter} onRegulationSelectionChange={setRegulationsFilter} />
        )}

        {isSharingNoticeSignalVisible && (
          <PrivacySignalCheckboxCard
            fieldId="share-notice-with-3rd-party"
            label="Notice of the sharing of the consumer’s personal data with third parties"
            value={sharingNoticeWithThirdParty.value}
            regulationIds={sharingNoticeWithThirdParty.regulationIds}
            disabled={viewOnly}
            onValueChange={onShareNoticeWithThirdPartyChange}
          />
        )}

        {/* Signals */}
        <PrivacySignalsForMappingList privacySignalsConfig={filteredSignalsUiGroups.signalsWithoutSpis} viewOnly={viewOnly} onEditClicked={setSignalConfigForMappingModal} />

        {/* Signals SPIs */}
        {Object.entries<PrivacySignalsConfig[]>(filteredSignalsUiGroups.signalsWithSpis).map(([signalName, spiItems]) => (
          <ExpandableSection
            key={signalName}
            className="mt-s"
            id={`${signalName}-collapse`}
            variant="sub-level"
            header={
              <div className="flex items-center gap-xs">
                <ExpandableSectionToggle a11yLabel={signalName} />
                <h3 className="font-semibold text-h4">{signalName}</h3>
              </div>
            }
          >
            <PrivacySignalsForMappingList className="pt-xs" privacySignalsConfig={spiItems} viewOnly={viewOnly} onEditClicked={setSignalConfigForMappingModal} />
          </ExpandableSection>
        ))}
      </div>

      {/* Loading State */}
      {isLoading && <BlockLoader>Loading GPP settings</BlockLoader>}

      {/* Modals */}
      <MapSignalToPurposeModal
        isOpen={!!signalConfigForMappingModal}
        privacySignalConfig={signalConfigForMappingModal}
        purposes={noticePurposes}
        purposesMappedToOtherSignals={purposesMappedToOtherSignals}
        onSave={handleMapSignalToPurpose}
        onCancel={() => setSignalConfigForMappingModal(null)}
      />
    </ExpandableSection>
  );
};
