import React, { useMemo } from 'react';
import { DidomiHintbox, DidomiIcon, DidomiDatepicker, DidomiNumberInput, DidomiSelectionBlock } from '@didomi/ui-atoms-react';
import { SelectableTitle } from '@components';
import { formatDate } from '@utils';

type ConsentRecollectionSettingsProps = {
  className?: string;
  enableIgnoreConsentBefore?: boolean;
  ignoreConsentBefore?: string;
  daysBeforeShowingAgain?: number;
  disabled?: boolean;
  onEnableConsentRecollectionChange?: (enabled: boolean, dateTime?: string) => void;
  onIgnoreConsentBeforeChange?: (dateTime: string) => void;
  onRecollectionDaysChange?: (days: number) => void;
};

export const ConsentRecollectionSettings = ({
  className = '',
  disabled,
  enableIgnoreConsentBefore,
  ignoreConsentBefore,
  daysBeforeShowingAgain,
  onEnableConsentRecollectionChange,
  onIgnoreConsentBeforeChange,
  onRecollectionDaysChange,
}: ConsentRecollectionSettingsProps) => {
  const date = useMemo(() => {
    const date = ignoreConsentBefore ? new Date(ignoreConsentBefore) : new Date();
    return date?.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
  }, [ignoreConsentBefore]);

  const time = useMemo(() => {
    const date = ignoreConsentBefore ? new Date(ignoreConsentBefore) : new Date();
    return date?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }).replace(' ', '');
  }, [ignoreConsentBefore]);

  const handleDateChange = newDate => {
    const newDateWithTime = new Date(newDate.detail + ' ' + time.replace('PM', ' PM').replace('AM', ' AM'));
    onIgnoreConsentBeforeChange(newDateWithTime.toISOString());
  };

  const handleEnableConsentRecollection = (newRecollectionEnabled: boolean) => {
    const newDateWithTime = new Date(date + ' ' + time.replace('PM', ' PM').replace('AM', ' AM'));
    onEnableConsentRecollectionChange(newRecollectionEnabled, newDateWithTime.toISOString());
  };

  const handleRecollectionDaysChange = (newDaysChangeEvent: CustomEvent<string>) => {
    onRecollectionDaysChange(parseInt(newDaysChangeEvent.detail, 10) || 0);
  };

  return (
    <section className={`w-full ${className}`}>
      <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4 mb-xs">Consent re-collection</h2>

      <DidomiSelectionBlock className="w-full" disabled={disabled} selected={enableIgnoreConsentBefore} data-skeleton>
        <SelectableTitle
          className="mb-xxs"
          data-testid="consent-recollection"
          disabled={disabled}
          selected={enableIgnoreConsentBefore}
          onSelectionChanged={handleEnableConsentRecollection}
          title="Re-collect consent after a date"
        />

        <DidomiHintbox className="mb-xs">
          <div className="w-full flex gap-s flex-wrap lg:flex-nowrap">
            <div>
              Users that have given consent before that date will need to give consent again. Users that have given consent after that date will not be asked again. The window for
              recollecting consent and the user consent status will be ignored in favor of recollecting consent.
            </div>
            <a
              href="https://support.didomi.io/re-collect-consent-new-vendor/purpose-new-flow"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-xxxs whitespace-nowrap text-button-small font-semibold self-end"
            >
              <DidomiIcon name="read" />
              Learn more about consent re-collection
            </a>
          </div>
        </DidomiHintbox>

        <div className="w-1/2 pr-s">
          <DidomiDatepicker
            label="Date"
            value={date}
            dateFormat="n/j/Y"
            min={formatDate(new Date())}
            disabled={!enableIgnoreConsentBefore || disabled}
            data-testid="consent-recollection-date-input"
            onValueChange={handleDateChange}
          />
        </div>

        <hr className="w-full mt-xs mb-m" />
        <h3 className="text-primary-blue-6 text-h3 font-semibold font-serif">Window for re-collecting consent (in days)</h3>

        <DidomiHintbox className="mt-xxs mb-xs">
          Whenever you include a new vendor or purpose and subsequently republish your notice, it will be shown again to users who had previously provided consent. Assuming you’re
          collecting consent for all IAB vendors, this also applies when a vendor has a new purpose or is added to the official IAB vendors list. This re-display of the notice only
          applies to users whose consent was given more days ago than the period specified by you here.
        </DidomiHintbox>

        <div className="w-1/2 pr-s">
          <DidomiNumberInput
            data-skeleton
            disabled={disabled}
            label="Minimum number of days between 2 re-collections"
            value={daysBeforeShowingAgain}
            data-testid="consent-recollection-days-before-showing-again-input"
            min="0"
            onValueChange={handleRecollectionDaysChange}
          />
        </div>
      </DidomiSelectionBlock>
    </section>
  );
};
