import { SENSITIVE_DATA_PROCESSING_ITEM_DESCRIPTIONS, REGULATION_TO_GPP_STATE_STRING_CONFIG, SensitiveDataProcessingItem, PrivacySignalsConfig } from '@didomi/cmp-generator';
import { PrivacySignal } from '@types';

// Use combined section and namespace as a unique name for the signal config(as section could be not unique - for N-Bitfields)
export const getPrivacySignalConfigUniqueName = (signal: PrivacySignalsConfig): string => `${signal.section}.${signal.namespaces.gpp}`;

// Check if signal is configurable with purposes
export const isMappableSignal = (signal: PrivacySignal): boolean => signal.behavior === 'configurable-array';

// Check if signal is an N-Bitfield - so it has subitems
export const isSignalWithSubitems = (signal: PrivacySignal): boolean => signal.field_type.startsWith('N-Bitfield');

// Available GPP signals subitems data(stored in the Generator)
export const GPP_SIGNALS_SUBITEMS_DATA = {
  SensitiveDataProcessing: {
    description: SENSITIVE_DATA_PROCESSING_ITEM_DESCRIPTIONS,
    getItems: (regulationId: string) => REGULATION_TO_GPP_STATE_STRING_CONFIG[regulationId]?.orderedSensitiveDataProcessingItems,
  },
};

// Get signals subitems
export const getSignalsSubitems = (signal: PrivacySignal): SensitiveDataProcessingItem[] => {
  const signalSubitems = GPP_SIGNALS_SUBITEMS_DATA[signal.name]?.getItems?.(signal.regulation_id);
  return signalSubitems || [];
};

// Get name that should be displayed on the UI
export const getSignalNameToDisplay = (signal: PrivacySignalsConfig): string => {
  // Display section as the name for the root signals
  if (!signal.namespaces.gpp) {
    return signal.section;
  }

  // Display custom name(stored in the Generator) for subitems
  return GPP_SIGNALS_SUBITEMS_DATA[signal.section].description[signal.namespaces.gpp] || signal.section;
};
