import React from 'react';
import { DidomiHintbox } from '@didomi/ui-atoms-react';

export const GppSettingsNoticeInfoBanner = () => (
  <DidomiHintbox titleText="Mapping GPP privacy signals with your notice purposes.">
    <p className="mb-xxs">The GPP framework enforces a set of privacy signals (e.g. SaleOptOut, ShareOptOut) where the user&apos;s choice must be encapsulated.</p>
    <p className="mb-xxs">
      This means that <strong>you must map all the purposes of this notice to at least one GPP privacy signal</strong>, allowing the Didomi SDK to generate the status of the GPP
      privacy signals based on the user&apos;s choice in the consent notice.
    </p>
    <p>
      Providing the mapping for the applicable GPP privacy signals is crucial to ensure compliance with the GPP framework: Any unmapped signals will result in a failure to provide
      the user’s opt-out status in the GPP string.
    </p>
  </DidomiHintbox>
);
