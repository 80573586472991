import { PrivacySignalsConfig } from '@didomi/cmp-generator';

// Sort signals to first display main one, and after - subitems(signals with gpp namespace)
export const sortSignalsByNamespace = (a: PrivacySignalsConfig, b: PrivacySignalsConfig) => {
  // if "a" signal does not have gpp namespace, but "b" signal does - place "a" before "b"
  if (!a.namespaces.gpp && b.namespaces.gpp) {
    return -1;
  }

  return 0;
};
