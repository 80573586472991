import { useCallback } from 'react';
import { RegulationConfigAggregator, SDKConfigGenerator, NoticeConfigV2 } from '@didomi/cmp-generator';
import { useActiveOrganization } from '@didomi/utility-react';
import { useIabStacks, usePurposes, useVendors, useVendorsStorages, useConsentNoticeApprovedStandardTextContents } from '@hooks';
import { getApprovedStandardText, matchDefaultRegulationId } from '@utils';
import { usePurposesNumericIds } from './data/vendors-and-purposes/usePurposesNumericIds.hook';
import { usePurposesRegulationsOverrides } from './data/vendors-and-purposes/usePurposesRegulationsOverrides.hook';
import { useVendorsNumericIds } from './data/vendors-and-purposes/useVendorsNumericIds.hook';
import { useVendorsPurposesOverrides } from './data/vendors-and-purposes/useVendorsPurposesOverrides.hook';

const generator = new SDKConfigGenerator();

export const useSDKConfig = () => {
  const { organization } = useActiveOrganization();
  const { data: purposes, isFetched: loadedPurposes } = usePurposes();
  const { data: vendors, isFetched: loadedVendors } = useVendors();
  const { data: vendorsStorages, isFetched: loadedVendorsStorages } = useVendorsStorages();
  const { data: purposeGroups, isFetched: loadedPurposeGroups } = useIabStacks();
  const { data: approvedStandardTextContents, isFetched: loadedStandardTextContents } = useConsentNoticeApprovedStandardTextContents();
  const { data: numericPurposeIds, isFetched: loadedNumericPurposeIds } = usePurposesNumericIds();
  const { data: numericVendorIds, isFetched: loadedNumericVendorIds } = useVendorsNumericIds();
  const { data: partnerPurposesOverrides, isFetched: loadedPartnersPurposesOverrides } = useVendorsPurposesOverrides();
  const { data: purposeRegulationOverrides, isFetched: loadedPurposesOverrides } = usePurposesRegulationsOverrides();

  const generateSDKConfig = useCallback(
    async (config, regulationId) => {
      const localNoticeConfigForGenerator = config as unknown as NoticeConfigV2;

      const targetNoticeRegulationConfig = localNoticeConfigForGenerator?.regulation_configurations.find(matchDefaultRegulationId(regulationId));

      const noticeConfig = RegulationConfigAggregator.constructGeneratorInput(localNoticeConfigForGenerator, targetNoticeRegulationConfig);

      // Disable DCS signature on preview.
      noticeConfig.enable_didomi_token_signature = false;

      let consentTypeToVendorsMap;
      if (noticeConfig.microsoft_enabled) {
        consentTypeToVendorsMap = {
          mcm: {
            ad_storage: [CONFIG.microsoftIABVendorId],
          },
        };
      }
      return generator.generateDidomiSDKConfig(
        noticeConfig,
        {
          partners: vendors,
          purposesOverrides: partnerPurposesOverrides.purposeOverrides,
          legIntPurposesOverrides: partnerPurposesOverrides.legIntPurposeOverrides,
          purposeRegulationOverrides: purposeRegulationOverrides,
          purposes,
          purposeGroups,
          organizationApiKey: organization?.public_api_key,
          storages: vendorsStorages,
          approvedTextContent: getApprovedStandardText(approvedStandardTextContents, config?.text_id),
          defaultApprovedTextContent: null,
          numericPartners: numericVendorIds,
          numericPurposes: numericPurposeIds,
          includeCustomPurposesForIABVendors: noticeConfig.enable_didomi_token_v2,
          ...(consentTypeToVendorsMap && { consentTypeToVendorsMap }),
        },
        regulationId,
        true,
      );
    },
    [
      purposes,
      vendors,
      vendorsStorages,
      purposeGroups,
      approvedStandardTextContents,
      organization,
      numericVendorIds,
      numericPurposeIds,
      partnerPurposesOverrides.legIntPurposeOverrides,
      partnerPurposesOverrides.purposeOverrides,
      purposeRegulationOverrides,
    ],
  );

  return {
    generateSDKConfig,
    isReady:
      loadedPurposes &&
      loadedVendors &&
      loadedVendorsStorages &&
      loadedPurposeGroups &&
      loadedStandardTextContents &&
      loadedNumericPurposeIds &&
      loadedNumericVendorIds &&
      loadedPartnersPurposesOverrides &&
      loadedPurposesOverrides,
  };
};
