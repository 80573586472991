import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { ConsentNoticeConfig } from '@types';

/**
 * Edits a local version of a notice config
 * */
export const useEditLocalConsentNoticeConfig = (
  consentNoticeConfigId: string,
  options?: UseMutationOptions<Partial<ConsentNoticeConfig>, AxiosError, Partial<ConsentNoticeConfig>>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (consentNotice: Partial<ConsentNoticeConfig>) => {
      const consentNoticeGet: ConsentNoticeConfig = queryClient.getQueryData(['consent-notice-config-local', consentNoticeConfigId]);
      return Promise.resolve({ ...consentNoticeGet, ...consentNotice });
    },
    {
      ...options,
      onSuccess: (editedConsentNoticeConfig: ConsentNoticeConfig, variables, context) => {
        queryClient.setQueryData(['consent-notice-config-local', consentNoticeConfigId], editedConsentNoticeConfig);

        if (options?.onSuccess) {
          options.onSuccess(editedConsentNoticeConfig, variables, context);
        }
      },
    },
  );
};
