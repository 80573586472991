import React, { useMemo } from 'react';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { REGULATION_DICTIONARY } from '@utils';

interface GppStringSelectionRegulationCardHeaderProps {
  value: boolean | null;
  regulationId: string;
}

export const GppStringSelectionRegulationCardHeader = ({ value, regulationId }: GppStringSelectionRegulationCardHeaderProps) => {
  const titleText = regulationId.toUpperCase();

  const subtitleText = useMemo(() => {
    if (value === true) return 'National string';
    if (value === false) return 'State string (if supported, else National string)';
    return 'Default';
  }, [value]);

  return (
    <div className={tx('flex gap-xxs items-center')}>
      <DidomiIcon name={REGULATION_DICTIONARY[regulationId]?.iconMd} />
      <div className={tx('shrink-0')}>
        <p>{titleText}</p>
        <p className={tx('text-body-small text-neutral-gray-7 leading-none flex align-center gap-xxxs')}>{subtitleText}</p>
      </div>
    </div>
  );
};
