import { NoticeConfig, Regulation } from '@didomi/cmp-generator';
import { PrivacySignal } from '@types';

/**
 * Based on GPP config and privacy signals, determine whether state id(regulation id) or usnat should be used
 */
export const getRegulationConfigGppStateId = (aggregatedRegConfig: NoticeConfig, privacySignals: PrivacySignal[], regulationId: Regulation): Regulation | 'usnat' => {
  // MSPA Signatory is not enabled - State String must be configured
  const isMspaSignatory = aggregatedRegConfig.gpp_mspa_signatory;
  if (!isMspaSignatory) {
    return regulationId;
  }

  // MSPA Signatory enabled + String Across US approach selected = National String must be configured
  const isUsNatStringSelected = aggregatedRegConfig?.gpp_mspa_generate_usnat_string;
  if (isUsNatStringSelected) {
    return 'usnat';
  }

  // MSPA Signatory enabled + State approach selected:
  // - There are Privacy Signals for State = State String must be configured
  // - There are no Privacy Signals for State = National String must be configured
  const hasStatePrivacySignals = privacySignals.some(s => s.regulation_id === regulationId);
  return hasStatePrivacySignals ? regulationId : 'usnat';
};
