import { useActiveOrganization } from '@didomi/utility-react';

// Hook that keeps all logic related to the organization
export const useOrgRules = () => {
  const { organization } = useActiveOrganization();

  // Hiding purposes is available only if organization has a custom CMP ID
  const hasHidePurposes = !!organization.iab_tcf_cmp_id && organization.iab_tcf_cmp_id !== CONFIG.defaultCMPID;

  return {
    hasHidePurposes,
  };
};
