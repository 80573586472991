import React, { useEffect, useMemo, useState } from 'react';
import { produce } from '@didomi/helpers';
import { useLocalConsentNoticeConfig, useRegulations } from '@hooks';
import { MigrateToUiV2Modal } from '@modals';
import { ConsentNoticeConfig } from '@types';
import { doArrayIntersect } from '@utils';

export const UiV2MigrationWidget = ({ noticeId, onMigrationSave }: { noticeId: string; onMigrationSave: (config: ConsentNoticeConfig) => void }) => {
  const [migrationModalState, setMigrationModalState] = useState<'idle' | 'show' | 'shown'>('idle');
  const { data: localConsentNoticeConfig, isLoading } = useLocalConsentNoticeConfig(noticeId);
  const { data: regulations, isLoading: isLoadingRegulations } = useRegulations();

  const usaRegulationsIds = useMemo(() => {
    return regulations?.data?.filter(r => r.geos.some(geo => geo.country.id === 'US')).map(r => r.id) || [];
  }, [regulations?.data]);

  const activeDefaultRegulationIds = useMemo(() => {
    return localConsentNoticeConfig?.regulation_configurations.filter(r => r.is_default_regulation_config && !r.disabled_at).map(r => r.regulation_id) || [];
  }, [localConsentNoticeConfig]);

  useEffect(() => {
    if (
      localConsentNoticeConfig?.platform === 'web' &&
      localConsentNoticeConfig?.config?.ui?.version !== '2.0.0' &&
      migrationModalState === 'idle' &&
      doArrayIntersect(activeDefaultRegulationIds, usaRegulationsIds)
    ) {
      setMigrationModalState('show');
    }
  }, [localConsentNoticeConfig?.platform, localConsentNoticeConfig?.config?.ui?.version, migrationModalState, usaRegulationsIds, activeDefaultRegulationIds]);

  const migrateToUiV2 = () => {
    const updatedConfig = produce(localConsentNoticeConfig, 'config.ui.version', '2.0.0');
    onMigrationSave(updatedConfig as ConsentNoticeConfig);
    setMigrationModalState('shown');
  };

  return (
    <div data-testid={`ui-v2-wrapper-${isLoading || isLoadingRegulations ? 'loading' : 'loaded'}`}>
      <MigrateToUiV2Modal isOpen={!!localConsentNoticeConfig && migrationModalState === 'show'} onConfirm={migrateToUiV2} onCancel={() => setMigrationModalState('shown')} />;
    </div>
  );
};
