import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent, DidomiIllustration } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type MicrosoftRequiredModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MicrosoftRequiredModal = ({ isOpen, onClose }: MicrosoftRequiredModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration name="add-microsoft-vendor" style={{ marginBottom: 'var(--space-m)' }} />
      <DidomiModalHeader variant="warning" modalTitle="Check Microsoft Consent Mode" />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>
          <p className={tx('mb-4')}>As a reminder, our Microsoft Consent Mode (MCM) integration requires the Microsoft Advertising vendor to be enabled.</p>
          <p className={tx('font-semibold')}>Ensure that the Microsoft Advertising vendor remains selected to use the MCM integration.</p>
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton onClick={onClose}>Ok, I got it</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
