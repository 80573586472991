import React, { useEffect, useState } from 'react';
import { DidomiChip, DidomiSelectionBlock, DidomiButton, DidomiIcon, DidomiSwitch, DidomiTooltip } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useNavigate } from 'react-router-dom';
import { getCounterText, REGULATION_DICTIONARY } from '@utils';

interface RegulationSelectionCardProps {
  description: string;
  regulationId: string;
  checked: boolean;
  comingSoon?: boolean;
  purposesCount?: number;
  vendorsCount?: number;
  spiCount?: number;
  addPurposePath?: string;
  icon?: string;
  showPurposesMappingWarning?: boolean;
  onValueChange?: (checked: boolean) => boolean | undefined;
  disabled?: boolean;
}

export const RegulationSelectionCard = ({
  description,
  regulationId,
  checked,
  purposesCount,
  vendorsCount,
  spiCount,
  addPurposePath = '/',
  comingSoon,
  showPurposesMappingWarning,
  onValueChange,
  disabled,
}: RegulationSelectionCardProps) => {
  const [checkedLocal, setCheckedLocal] = useState(checked);

  useEffect(() => {
    setCheckedLocal(checked);
  }, [checked]);

  const handleSelectionChange = () => {
    if (onValueChange(!checkedLocal) !== false) {
      setCheckedLocal(cL => !cL);
    }
  };

  const isCardDisabled = comingSoon || disabled;
  const navigate = useNavigate();

  return (
    <DidomiSelectionBlock variant="hybrid" selected={checked} disabled={isCardDisabled}>
      <div className="h-full flex gap-s items-center">
        {/* Control */}
        <DidomiSwitch
          data-testid={regulationId + '-switch-input'}
          className="!w-auto"
          valueSelected={true}
          valueNotSelected={false}
          value={checkedLocal}
          onValueChange={handleSelectionChange}
          disabled={isCardDisabled}
          hideErrorMessage
        ></DidomiSwitch>

        {/* Regulation Icon */}
        <DidomiIcon className={tx('w-[44px] shrink-0', { grayscale: comingSoon })} name={REGULATION_DICTIONARY[regulationId]?.icon || regulationId} />

        {/* Content */}
        <div className="mr-auto">
          <div className="flex gap-s justify-between">
            <h3 className="h3-bold font-bold font-serif">
              <label htmlFor={regulationId + '-checkbox'}>{regulationId?.toUpperCase()}</label>
            </h3>
            {comingSoon && <DidomiChip variation="basic-outline" label="Coming soon" />}
          </div>
          <p title={description} className="text-body-small line-clamp-2">
            {description}
          </p>
        </div>

        {/* Separator */}
        <div className="border-r-1 border-solid border-neutral-gray-3 self-stretch" aria-hidden />

        <div className="flex items-center gap-xxs shrink-0 2xl:(flex-col items-end)">
          {/* div is used instead of DidomiChipList - as the last one prone to error when children is removed dynamically (CNC-448) */}
          <div className="flex items-center gap-xxs">
            <DidomiChip variation={vendorsCount ? 'basic' : 'basic-outline'} basicType={vendorsCount ? 'default' : 'empty'} label={getCounterText(vendorsCount || 0, 'vendor')} />
            <DidomiChip
              variation={purposesCount ? 'basic' : 'basic-outline'}
              basicType={purposesCount ? 'default' : 'empty'}
              label={getCounterText(purposesCount || 0, 'purpose')}
            />

            {showPurposesMappingWarning && (
              <DidomiTooltip className="leading-[0]" variant="helper" content="All purposes that are part of the notice must be mapped with at least one privacy signal.">
                <DidomiIcon data-testid="gpp-settings-warning" name="warning-sm" />
              </DidomiTooltip>
            )}
            {/* TODO: uncomment when fixed or delete if not necessary */}
            {/* {spiCount !== undefined && <DidomiChip variation={spiCount ? 'basic' : 'basic-outline'} basicType={spiCount ? 'default' : 'empty'} label={`${spiCount} SPI`} />} */}
          </div>

          <DidomiButton size="small" disabled={!checked} data-testid={regulationId + '-vendors-and-purposes-button'} onClick={() => navigate(addPurposePath)}>
            Edit Vendors & Purposes
          </DidomiButton>
        </div>
      </div>
    </DidomiSelectionBlock>
  );
};
