import { SDKConfig, PrivacySignalsConfig } from '@didomi/cmp-generator';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash.get';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNoticeTemplate } from '@types';
import { produceListOfChanges } from '@utils';

interface CreateTemplateParams {
  name: string;
  config?: SDKConfig;
  regulation_settings?: { gpp?: { privacySignals: PrivacySignalsConfig[]; sharingNoticeSignalValue?: boolean } };
}

/**
 * Creates a template, makes sure to update the list of templates with the edited template value
 * */
export const useCreateConsentNoticeTemplate = (options?: UseMutationOptions<AxiosResponse<ConsentNoticeTemplate>, AxiosError, CreateTemplateParams>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ name, config, regulation_settings }: CreateTemplateParams) => {
      const updatesToApply = [
        { path: 'app.vendors.include', value: get(config, 'app.vendors.include', []) },
        { path: 'preferences.categories', value: get(config, 'preferences.categories', []) },
        { path: 'app.essentialPurposes', value: get(config, 'app.essentialPurposes', []) },
        { path: 'app.vendors.iab.all', value: get(config, 'app.vendors.iab.all', false) },
        { path: 'app.vendors.iab.restrictions', value: get(config, 'app.vendors.iab.restrictions', []) },
        { path: 'app.vendors.iab.stacks', value: get(config, 'app.vendors.iab.stacks', { auto: false, ids: [] }) },
      ];

      config = produceListOfChanges(updatesToApply, {});

      const requestBody = {
        name,
        organization_id: organizationId,
        config,
        regulation_settings,
      };

      return axiosWithInterceptors.post<ConsentNoticeTemplate>(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/templates?$translations=true$with[]=config`, requestBody);
    },
    {
      ...options,
      onSuccess: (newTemplate: AxiosResponse<ConsentNoticeTemplate>, variables, context) => {
        const templatesGet: PaginatedResponse<ConsentNoticeTemplate> = queryClient.getQueryData(['consent-notice-templates', organizationId]);
        /* istanbul ignore if */
        if (templatesGet) {
          queryClient.removeQueries(['consent-notice-templates', organizationId]);
        }

        const templateGet: PaginatedResponse<ConsentNoticeTemplate> = queryClient.getQueryData(['consent-notice-template', newTemplate.data.id]);

        /* istanbul ignore if */
        if (templateGet) {
          queryClient.removeQueries(['consent-notice-template', newTemplate.data.id]);
        }

        if (options?.onSuccess) {
          options.onSuccess(newTemplate, variables, context);
        }
      },
    },
  );
};
