import React from 'react';
import { DidomiButton, DidomiHintbox } from '@didomi/ui-atoms-react';
import { useActiveOrganization, useSPARouter } from '@didomi/utility-react';

const ANALYTICS_DASHBOARD_ID = 'qKqYracU';

export const CheckComplianceReport = () => {
  const { navigateTo } = useSPARouter();
  const { organizationId } = useActiveOrganization();

  return (
    <DidomiHintbox className="mb-s" headerIconName="keyboard">
      <div className="flex gap-s">
        <p className="mr-auto">
          To verify your notice implementation, access the Implementation dashboard (data available within 24 hours). To ensure compliance, launch a website scan in the Compliance
          Report module.
        </p>

        <div className="flex flex-col 2xl:!flex-row gap-xs shrink-0">
          <DidomiButton className="shrink-0" variant="secondary" size="small" onClick={() => navigateTo(`/analytics/cmp/${organizationId}-${ANALYTICS_DASHBOARD_ID}`)}>
            Access dashboard
          </DidomiButton>

          <DidomiButton className="shrink-0" variant="secondary" size="small" onClick={() => navigateTo('/agnostik-compliance-report')}>
            Access Compliance Report
          </DidomiButton>
        </div>
      </div>
    </DidomiHintbox>
  );
};
