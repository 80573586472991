import React, { useMemo } from 'react';
import { getCounterText } from '@didomi/helpers';
import { DidomiChip, DidomiIconButton } from '@didomi/ui-atoms-react';
import { PrivacySignalsConfigUi } from '@types';
import { getPrivacySignalConfigUniqueName, getSignalNameToDisplay } from '@utils';
import { PrivacySignalSupportedRegulations } from '../PrivacySignalSupportedRegulations/PrivacySignalSupportedRegulations';

interface PrivacySignalMappingCardProps {
  signalWithMapping: PrivacySignalsConfigUi;
  viewOnly: boolean;
  onEditClicked: () => void;
}

export const PrivacySignalMappingCard = ({ signalWithMapping, viewOnly, onEditClicked }: PrivacySignalMappingCardProps) => {
  const counterText = getCounterText(signalWithMapping?.purposes?.length, 'purpose');
  const isEmpty = !signalWithMapping?.purposes?.length;

  const signalName = useMemo(() => getSignalNameToDisplay(signalWithMapping), [signalWithMapping]);

  return (
    <div className="flex items-center gap-xxs p-xs bg-white rounded" data-testid="gpp-signal-card">
      <div className="mr-auto">
        <div className="mb-xxxs text-body-small font-semibold text-primary-blue-6">{signalName}</div>
        <PrivacySignalSupportedRegulations regulationIds={signalWithMapping.regulationIds} />
      </div>

      <DidomiChip className="shrink-0" basicType={isEmpty ? 'empty' : 'success'} label="Mapped with" rightIcon="union-sm" />
      <DidomiChip
        data-testid={`${getPrivacySignalConfigUniqueName(signalWithMapping)}-mapping-counter`}
        className="shrink-0"
        variation="basic-outline"
        basicType={isEmpty ? 'empty' : 'default'}
        label={counterText}
      />

      {!viewOnly && <DidomiIconButton title="Edit" variant="rounded" icon="edit" size="small" onClick={onEditClicked} />}
    </div>
  );
};
