import React, { useMemo, useState } from 'react';
import { produce, isValidUrl, supportedLanguages } from '@didomi/helpers';
import { DidomiSelect, DidomiSelectOptions, DidomiSelectOption, DidomiTextInput, DidomiHintbox, DidomiAutocompleteWithSearch } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { ExpandableTitle } from '@components';
import { useRegulationsCountries, useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useCustomizationStepOptions } from '@hooks';
import { ACCESS_POLICIES_CONFIG, INPUT_DEBOUNCE_TIME, getDefaultGdprConfigIab } from '@utils';

interface CustomizationGlobalLayerProps {
  noticeId: string;
}

export const CustomizationGlobalLayer = ({ noticeId }: CustomizationGlobalLayerProps) => {
  const { data: localConsentNoticeConfig, isLoading: loadingNotice } = useLocalConsentNoticeConfig(noticeId);
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const [privacyPolicyError, setPrivacyPolicyError] = useState(null);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { data: countriesData, isLoading: isCountriesLoading } = useRegulationsCountries();
  const { trafficLanguage, setTrafficLanguage } = useCustomizationStepOptions({ defaultTraffic: localConsentNoticeConfig?.config?.languages?.default });

  const noticeApp = localConsentNoticeConfig?.config?.app;
  const noticeLanguages = localConsentNoticeConfig?.config?.languages;

  const isIabEnabled = useMemo(() => getDefaultGdprConfigIab(localConsentNoticeConfig)?.enabled, [localConsentNoticeConfig]);

  const handleAppNameChange = (newText: string) => {
    if (!noticeApp?.name && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(produce(localConsentNoticeConfig, 'config.app.name', newText || undefined));
  };

  const handleCountryChange = (countryCode: string) => {
    updateLocalConfig(produce(localConsentNoticeConfig, 'country', countryCode));
  };

  const handlePrivacyPolicyChange = (newText: string) => {
    if (!noticeApp?.privacyPolicyURL && !newText) /* istanbul ignore next */ return;
    if (newText === '' || isValidUrl(newText)) {
      updateLocalConfig(produce(localConsentNoticeConfig, 'config.app.privacyPolicyURL', newText || undefined));
      setPrivacyPolicyError(null);
    } else {
      setPrivacyPolicyError('Please use a valid URL');
    }
  };

  const handleDefaultLanguageChange = (newLanguage: string | string[]) => {
    if (!noticeLanguages?.default && !newLanguage) /* istanbul ignore next */ return;
    if (!noticeLanguages || !noticeLanguages.enabled || !noticeLanguages.enabled?.includes(newLanguage as string)) {
      updateLocalConfig(
        produce(localConsentNoticeConfig, 'config.languages', {
          default: newLanguage,
          enabled: [...(noticeLanguages?.enabled || []), newLanguage],
        }),
      );
    } else {
      updateLocalConfig(produce(localConsentNoticeConfig, 'config.languages.default', newLanguage || undefined));
    }
    setTrafficLanguage(newLanguage as string);
  };

  const handleEnabledLanguageChange = (newEnabledLanguages: string[]) => {
    if (!noticeLanguages?.enabled?.length && !newEnabledLanguages?.length) /* istanbul ignore next */ return;
    updateLocalConfig(produce(localConsentNoticeConfig, 'config.languages.enabled', newEnabledLanguages || undefined));
    checkAndUpdateTrafficLanguage(newEnabledLanguages);
  };

  // Check whether traffic language is enabled - if not, set it to the default language of the notice
  const checkAndUpdateTrafficLanguage = (newEnabledLanguages: string[]) => {
    if (!newEnabledLanguages.includes(trafficLanguage)) {
      setTrafficLanguage(localConsentNoticeConfig?.config?.languages?.default);
    }
  };

  return (
    <div id="customization-global-layer" data-testid="customization-global-layer" data-loading={isCountriesLoading || loadingNotice}>
      <DidomiHintbox iconName="warning-blue" className="mb-s">
        <p className="text-primary-blue-6">All the settings that apply globally to all selected regulations.</p>
      </DidomiHintbox>

      <div className="flex flex-col gap-xs">
        <ExpandableTitle title="Company" separate>
          <>
            <DidomiTextInput
              label="Company name"
              placeholder="Company name"
              debounce={INPUT_DEBOUNCE_TIME}
              disabled={loadingNotice || !isCMPEditor}
              hideErrorMessage
              value={noticeApp?.name || ''}
              onValueChange={e => handleAppNameChange(e.detail)}
            />
            <div className="text-body-small text-primary-blue-6">The company name will be shown in the purposes and vendors screen.</div>

            {isIabEnabled && (
              <>
                <DidomiAutocompleteWithSearch
                  data-testid="company-country-field"
                  attachTo="customization-global-layer"
                  label="Company country"
                  placeholder="Select a country"
                  disabled={loadingNotice || isCountriesLoading || !isCMPEditor}
                  hideErrorMessage
                  value={localConsentNoticeConfig?.country || ''}
                  valueField="code"
                  items={countriesData?.data}
                  onValueChange={e => handleCountryChange(e.detail as string)}
                />
                <div className="text-body-small text-primary-blue-6">
                  Select the country that your company is operating from. This will be used in the TCF consent string and can be used for configuration suggestions by Didomi.
                </div>
              </>
            )}
          </>
        </ExpandableTitle>
        <ExpandableTitle title="Privacy Policy" separate>
          <>
            <DidomiTextInput
              label="Company Privacy Policy link"
              placeholder="Enter a privacy policy URL"
              debounce={INPUT_DEBOUNCE_TIME}
              disabled={loadingNotice || !isCMPEditor}
              error={privacyPolicyError}
              hideErrorMessage={!privacyPolicyError}
              value={noticeApp?.privacyPolicyURL || ''}
              onValueChange={e => handlePrivacyPolicyChange(e.detail)}
            />
            <div className="text-body-small text-primary-blue-6">This URL will be used for the {`{privacyPolicyURL}`} shortcode.</div>
          </>
        </ExpandableTitle>
        <ExpandableTitle title="Languages">
          <>
            <DidomiSelect
              id="default-language-select"
              label="Default language"
              attachTo="customization-global-layer"
              placeholder="Default language"
              disabled={loadingNotice || !isCMPEditor}
              hideErrorMessage
              value={noticeLanguages?.default || 'en'}
              onValueChange={e => handleDefaultLanguageChange(e.detail)}
              allowAutomaticSize
            >
              <DidomiSelectOptions>
                {supportedLanguages.map(l => (
                  <DidomiSelectOption key={'default-lang-' + l.code} value={l.code} label={l.longName} />
                ))}
              </DidomiSelectOptions>
            </DidomiSelect>
            <div className="text-body-small text-primary-blue-6">
              Default language that the consent screens should be displayed in if the user language is not available or enabled.
            </div>
            <DidomiSelect
              label="Enabled languages"
              attachTo="customization-global-layer"
              id="enabled-languages-select"
              placeholder="Enabled languages"
              disabled={loadingNotice || !isCMPEditor}
              hideErrorMessage
              multi
              value={noticeLanguages?.enabled || ['en']}
              onValueChange={e => handleEnabledLanguageChange(e.detail as string[])}
              allowAutomaticSize
            >
              <DidomiSelectOptions>
                {supportedLanguages.map(l => (
                  <DidomiSelectOption key={'enabled-lang-' + l.code} disabled={noticeLanguages?.default === l.code} value={l.code} label={l.longName} />
                ))}
              </DidomiSelectOptions>
            </DidomiSelect>
            <div className="text-body-small text-primary-blue-6">
              Languages that the consent screens can be displayed in. We will automatically match the user language from their browser or Android/iOS configuration. Enable only one
              language to always display your notice in a single language no matter what the user language is.
            </div>
          </>
        </ExpandableTitle>
      </div>
    </div>
  );
};
