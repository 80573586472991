import { useActiveOrganization } from '@didomi/utility-react';
import { useLocalConsentNotice, useLocalConsentNoticeConfig } from '@hooks';
import { CodeType, EmbedCodeParams } from '@types';
import {
  getAMPEmbedCode,
  getDefaultGdprConfig,
  getDefaultGdprConfigIab,
  getManualAMPEmbedCode,
  getManualWebEmbedCode,
  getStaticEmbedCode,
  getTransformedSdkUrlForEmbeddedCode,
  getWebEmbedCode,
} from '@utils';

const generateTagPerPlatform = (platform: string, embedType: CodeType, options: EmbedCodeParams): string => {
  switch (platform) {
    case 'amp':
      if (embedType === 'generic') {
        return getAMPEmbedCode(options);
      } else if (embedType === 'specific') {
        return getManualAMPEmbedCode(options);
      } else {
        return getStaticEmbedCode(options);
      }

    case 'web':
    default:
      if (embedType === 'generic') {
        return getWebEmbedCode(options);
      } else if (embedType === 'specific') {
        return getManualWebEmbedCode(options);
      } else {
        return getStaticEmbedCode(options);
      }
  }
};

/**
 * Gets the script tag for a particular notice id
 * */
export const useLocalConsentNoticeEmbeddedScript = (noticeId: string, embedType: CodeType, options?: { sdkCdnUrl: string }) => {
  const { organization } = useActiveOrganization();
  const { data: notice, isLoading: loadingNotice } = useLocalConsentNotice(noticeId);
  const { data: noticeConfig, isLoading: loadingNoticeConfig } = useLocalConsentNoticeConfig(noticeId);

  if (loadingNotice || loadingNoticeConfig) {
    return { isLoading: true };
  }

  const ccpaEnabled = noticeConfig.custom_json?.regulations?.ccpa?.enabled || noticeConfig.config?.regulations?.ccpa?.enabled;
  const tcfConfig = getDefaultGdprConfigIab(noticeConfig);
  const source = embedType === 'static' && options?.sdkCdnUrl ? getTransformedSdkUrlForEmbeddedCode(options.sdkCdnUrl) : CONFIG.sdk.url;

  const code = generateTagPerPlatform(notice.config?.platform, embedType, {
    apiKey: organization.public_api_key || '',
    gdprAppliesGlobally: Boolean(getDefaultGdprConfig(noticeConfig)?.geo_locations.includes('*')),
    source,
    apis: {
      tcf: Boolean(tcfConfig?.enabled && tcfConfig?.version === 2),
      usp: ccpaEnabled,
      gpp: noticeConfig.gpp_enabled,
    },
    cmpId: organization.iab_tcf_cmp_id || CONFIG.defaultCMPID,
    noticeId: notice?.id,
  });

  return {
    data: code,
    isLoading: false,
  };
};
