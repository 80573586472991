import { PreviewSize } from '@types';

/**
 * Screen width breakpoints for different preview sizes.
 */
const SCREEN_WIDTH = {
  large: 1200,
  medium: 900,
  small: 520,
  amp: 520,
};

/**
 * Aspect ratio for CTV (Connected TV).
 */
const ASPECT_RATIO = 16 / 9;

/**
 * Configuration for calculating dimensions based on platform type.
 */
const platformConfig = {
  ctv: {
    /**
     * Calculates dimensions for CTV platform.
     * @param {number} containerWidth - The width of the container.
     * @param {number} containerHeight - The height of the container.
     * @returns {{ width: number, height: number, scale: number }} The calculated dimensions and scale.
     */
    calculateDimensions: (containerWidth: number, containerHeight: number) => {
      if (containerWidth / containerHeight > ASPECT_RATIO) {
        const height = containerHeight;
        const width = height * ASPECT_RATIO;
        return { width, height, scale: 1 };
      } else {
        const width = containerWidth;
        const height = width / ASPECT_RATIO;
        return { width, height, scale: 1 };
      }
    },
  },
  default: {
    /**
     * Calculates dimensions for default platform.
     * @param {number} containerWidth - The width of the container.
     * @param {number} containerHeight - The height of the container.
     * @param {PreviewSize} size - The preview size.
     * @returns {{ width: number, height: number, scale: number }} The calculated dimensions and scale.
     */
    calculateDimensions: (containerWidth: number, containerHeight: number, size: PreviewSize) => {
      const scale = containerWidth / SCREEN_WIDTH[size];
      const sizeGrow = 1 / scale;
      const width = containerWidth * sizeGrow;
      const height = containerHeight * sizeGrow;
      return { width, height, scale };
    },
  },
};

/**
 * Hook to calculate dimensions based on platform and container size.
 * @param {string} platform - The platform type ('ctv' or other ('web', 'app')).
 * @param {number} containerWidth - The width of the container.
 * @param {number} containerHeight - The height of the container.
 * @param {PreviewSize} size - The preview size.
 * @returns {{ width: number, height: number, scale: number }} The calculated dimensions and scale.
 */
export const useCalculateDimensions = (platform: string, containerWidth: number, containerHeight: number, size: PreviewSize) => {
  const platformType = platform === 'ctv' ? 'ctv' : 'default';
  const { calculateDimensions } = platformConfig[platformType];
  return calculateDimensions(containerWidth, containerHeight, size);
};
