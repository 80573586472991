import React, { useCallback, useMemo } from 'react';
import { DidomiCheckbox, DidomiEmptyState, DidomiPaginator, DidomiSelect, DidomiSelectOption, DidomiSelectOptions, DidomiTextSearchInput } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { usePaginatedFilteredItems, useTableState } from '@hooks';
import { Vendor, VendorFilterType, VendorsSortableField } from '@types';
import { checkVendorType } from '@utils';

const SEARCH_FIELDS = [{ field: 'name' }];

interface CompactVendorsSelectionListProps {
  className?: string;
  vendors: Vendor[];
  selectedIds: string[];
  updateSelection?: (newSelection: string[]) => void;
  filterType?: 'full' | 'minimal';
}

export const CompactVendorsSelectionList = ({ className, selectedIds, updateSelection, vendors, filterType = 'minimal' }: CompactVendorsSelectionListProps) => {
  const { limit, page, sortConfig, search, filters, changeLimit, changePage, changeSearch, changeFilters } = useTableState<VendorsSortableField>({
    defaultLimit: 5,
    defaultFilters: { type: VendorFilterType.ALL },
  });

  const filterByType = useCallback((vendor: Vendor) => checkVendorType(vendor, filters.type as VendorFilterType, selectedIds), [filters, selectedIds]);
  const filtersToApply = useMemo(() => [filterByType], [filterByType]);

  const { data: displayedVendors, total: paginationTotal } = usePaginatedFilteredItems<Vendor, VendorsSortableField>(vendors, {
    search,
    searchFields: SEARCH_FIELDS,
    limit,
    page,
    sortConfig,
    filters: filtersToApply,
  });

  const handleSelectionChange = (vendorId: string, selectionEvent: CustomEvent<boolean>) => {
    const isSelected = selectionEvent.detail;

    if (isSelected) {
      updateSelection([...selectedIds, vendorId]);
    } else {
      updateSelection(selectedIds.filter(id => id !== vendorId));
    }
  };

  return (
    <section data-testid="compact-vendors-selection-list" className={tx(className, 'flex flex-col')}>
      {/* Filters */}
      <div className={tx('flex gap-2 mb-4')}>
        <DidomiTextSearchInput
          data-testid="compact-vendors-selection-search"
          value=""
          placeholder="Search vendors"
          variant="secondary"
          hideErrorMessage
          onValueChange={e => changeSearch(e.detail)}
        />
        <DidomiSelect className={tx('w-[180px]')} placeholder="All vendors" hideErrorMessage variant="modern" onValueChange={e => changeFilters({ type: e.detail })}>
          <DidomiSelectOptions>
            <DidomiSelectOption label="All vendors" value={VendorFilterType.ALL}></DidomiSelectOption>
            {filterType === 'full' && (
              <>
                <DidomiSelectOption label="Custom vendors" value={VendorFilterType.CUSTOM}></DidomiSelectOption>
                <DidomiSelectOption label="Google ATP vendors" value={VendorFilterType.ATP}></DidomiSelectOption>
                <DidomiSelectOption label="IAB vendors" value={VendorFilterType.IAB}></DidomiSelectOption>
              </>
            )}
            <DidomiSelectOption label="Selected vendors" value={VendorFilterType.SELECTED}></DidomiSelectOption>
          </DidomiSelectOptions>
        </DidomiSelect>
      </div>

      {displayedVendors.length > 0 && (
        <>
          <ul className={tx('flex flex-col gap-2 mb-4')}>
            {displayedVendors.map(vendor => (
              <li key={vendor.id} className={tx('bg-neutral-gray-1 rounded p-xs')}>
                <DidomiCheckbox
                  data-testid={`vendor-${vendor.id}`}
                  label={vendor.name}
                  checked={selectedIds.includes(vendor.id)}
                  hideErrorMessage
                  onValueChange={e => handleSelectionChange(vendor.id, e)}
                />
              </li>
            ))}
          </ul>

          <DidomiPaginator
            data-testid="compact-vendors-selection-paginator"
            className={tx('self-end')}
            page={page}
            itemCount={paginationTotal}
            size={limit}
            onPageSizeChange={changeLimit}
            onPageChange={changePage}
          />
        </>
      )}

      {/* Empty State */}
      {displayedVendors.length === 0 && <DidomiEmptyState illustration-name="traces-no-match-found">No results</DidomiEmptyState>}
    </section>
  );
};
