import { useEffect, useMemo, useState } from 'react';
import { PrivacySignalsConfig } from '@didomi/cmp-generator';
import { ConsentNoticeTemplateConfig, PreferencesCategoriesConfig } from '@types';
import {
  getPrivacySignalConfigUniqueName,
  getPurposesFromCategories,
  getPurposesMappedToSignals,
  getSignalsToRegulationsHash,
  getSyncedGppSignalsSettings,
  getUniqueMappableSignals,
  sortSignalsByNamespace,
} from '@utils';
import { usePrivacySignals } from '../privacy-signals/usePrivacySignals.hook';

export const useTemplateGppPrivacySignalsState = ({ templateConfig }: { templateConfig: ConsentNoticeTemplateConfig }) => {
  const { data: signals = [], isLoading } = usePrivacySignals();
  const [templatePrivacySignals, setTemplatePrivacySignals] = useState<PrivacySignalsConfig[]>([]);
  const [templateSharingNoticeValue, setTemplateSharingNoticeValue] = useState<boolean>(false);

  useEffect(() => {
    if (!signals.length) return;

    const uniqueMappableSignals = getUniqueMappableSignals(signals);
    const signalsStoredInTemplate = templateConfig?.regulation_settings?.gpp?.privacySignals || [];
    const syncedSignals = getSyncedGppSignalsSettings(signalsStoredInTemplate, uniqueMappableSignals);

    setTemplatePrivacySignals(syncedSignals);
    setTemplateSharingNoticeValue(!!templateConfig?.regulation_settings?.gpp?.sharingNoticeSignalValue);
  }, [signals, templateConfig]);

  const templatePrivacySignalsWithRegulations = useMemo(() => {
    const signalsToRegulationsHash = getSignalsToRegulationsHash(signals);
    return templatePrivacySignals.map(s => ({ ...s, regulationIds: signalsToRegulationsHash[s.section] })).sort(sortSignalsByNamespace);
  }, [signals, templatePrivacySignals]);

  const sharingNoticeSignalRegulations = useMemo(() => {
    const signalsToRegulationsHash = getSignalsToRegulationsHash(signals);
    return signalsToRegulationsHash['SharingNotice'];
  }, [signals]);

  const mappedPurposes = useMemo(() => getPurposesMappedToSignals(templatePrivacySignals), [templatePrivacySignals]);

  // Updated template privacy signals after user performs purpose-to-signal mapping
  const updatePrivacySignalsAfterMapping = (updatedSignal: PrivacySignalsConfig) => {
    const signalUniqueNameToUpdate = getPrivacySignalConfigUniqueName(updatedSignal);
    const updatedPrivacySignals = templatePrivacySignals.map(s => (getPrivacySignalConfigUniqueName(s) === signalUniqueNameToUpdate ? updatedSignal : s));
    setTemplatePrivacySignals(updatedPrivacySignals);
  };

  // Update privacy signals when available purposes changes - to make sure that signals contain only purposes that are part of the categories
  const updatePrivacySignalsAfterPurposesChange = (updatedCategories: PreferencesCategoriesConfig) => {
    const selectedPurposes = getPurposesFromCategories(updatedCategories);
    const purposesIdsSet = new Set(selectedPurposes.map(p => p.purposeId));
    const updatedPrivacySignals = templatePrivacySignals.map(signal => ({ ...signal, purposes: signal.purposes.filter(purposeSdkId => purposesIdsSet.has(purposeSdkId)) }));
    setTemplatePrivacySignals(updatedPrivacySignals);
  };

  return {
    isLoading,
    signals,
    sharingNoticeSignalRegulations,
    templateSharingNoticeValue,
    templatePrivacySignalsWithRegulations,
    templatePrivacySignals,
    mappedPurposes,
    updatePrivacySignalsAfterMapping,
    updatePrivacySignalsAfterPurposesChange,
    updateSharingNoticeValue: setTemplateSharingNoticeValue,
  };
};
