import { SDKConfigPreferencesCategory } from '@didomi/cmp-generator';
import { Purpose } from './purpose';

export interface PreferenceCategory {
  type: 'purpose';
  purposeId: string;
}

export interface PreferenceCategoryGroup {
  type: 'category';
  id: string;
  name: { [lang: string]: string };
  description: { [lang: string]: string };
  agree?: { [lang: string]: string };
  disagree?: { [lang: string]: string };
  expanded?: boolean;
  hidePurposes?: boolean;
  children: PreferenceCategory[];
}

export interface ExtendedPreferenceCategoryGroup extends Omit<PreferenceCategoryGroup, 'children'> {
  children: ExtendedPurpose[];
}

export interface ExtendedPurpose extends Purpose {
  required: boolean;
}

export type PreferencesCategoriesConfig = Array<SDKConfigPreferencesCategory>;
export type ExtendedPreferencesCategoriesConfig = Array<ExtendedPurpose | ExtendedPreferenceCategoryGroup>;

// Type guard
export const isPreferenceCategory = (item: Purpose | ExtendedPreferenceCategoryGroup): item is ExtendedPreferenceCategoryGroup => {
  return !!(item as ExtendedPreferenceCategoryGroup).children;
};
