import React from 'react';
import { DidomiChip } from '@didomi/ui-atoms-react';

interface PrivacySignalSupportedRegulationsProps {
  regulationIds: string[];
}

export const PrivacySignalSupportedRegulations = ({ regulationIds }: PrivacySignalSupportedRegulationsProps) => {
  if (!regulationIds?.length) {
    return null;
  }

  return (
    <div className="flex gap-xxs">
      {regulationIds.map(rId => (
        <DidomiChip key={rId} variation="basic-outline" label={rId.toUpperCase()} />
      ))}
    </div>
  );
};
