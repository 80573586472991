import React, { useEffect, useMemo, useState } from 'react';
import { DidomiSkeleton, DidomiBottomBar, DidomiButton, DidomiButtonGroup } from '@didomi/ui-atoms-react';
import { useSnackbar, useCustomFeature, useReleaseFlag } from '@didomi/utility-react';
import { useNavigate, useParams } from 'react-router-dom';
import { PresetSelectionCard } from '@components';
import { useCreateConsentNotice, useRegulations } from '@hooks';
import { CreateNoticeExitPromptModal, LoadingModal } from '@modals';
import { CUSTOM_EVENTS, CONSENT_NOTICES_PLATFORMS, Frameworks } from '@types';
import { GCM_SUPPORTED_PLATFORMS, getFrawemorkFromPresetSelection } from '@utils';

/**
 * Consent Notice creation - Select frameworks page
 */
export const CreateNoticeSelectFrameworks = (): JSX.Element => {
  const [selectedGPP, setSelectedGPP] = useState(null);
  const [selectedGCM, setSelectedGCM] = useState(null);
  const [selectedIABTCF, setSelectedIABTCF] = useState(null);
  const [selectedMCM, setSelectedMCM] = useState(null);

  const [isGPPEnabled] = useReleaseFlag('gpp_privacy_signals');
  const [hasCTVFeature] = useCustomFeature('widgets-notices-ctv');
  const navigate = useNavigate();
  let { platformType } = useParams();
  const { displaySnackbar } = useSnackbar();
  const { data: regulations, isLoading: isLoadingGdprRegulation } = useRegulations(null, null, { filterById: 'gdpr' });

  const gdprGeos = useMemo(() => {
    const gdpr = regulations?.data[0];
    return (gdpr?.geos || []).map(geo => geo.country.id);
  }, [regulations]);

  const { mutate: createNotice, isLoading: creatingNotice } = useCreateConsentNotice({
    onSuccess: newNotice => {
      displaySnackbar(`${newNotice.data.name} has been created`, { icon: 'success-small' });
      navigate('/' + newNotice.data.id);
    },
    onError: () => {
      displaySnackbar('There was an error creating the notice', { variant: 'error' });
    },
  });

  useEffect(() => {
    if (!platformType) return;

    // If CTV but user does not have the feature, go back
    if (platformType === 'ctv' && !hasCTVFeature) {
      navigate('/add');
    }

    // If platform doesn't exist go back to add
    if (CONSENT_NOTICES_PLATFORMS.findIndex(p => p === platformType) === -1) {
      navigate('/add');
    }
  }, [hasCTVFeature, platformType, navigate]);

  const isGcmAvailable = GCM_SUPPORTED_PLATFORMS.includes(platformType);

  const selectedPreset = useMemo(
    () => getFrawemorkFromPresetSelection(selectedGCM, selectedIABTCF, selectedGPP, selectedMCM),
    [selectedGCM, selectedIABTCF, selectedGPP, selectedMCM],
  );

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoadingGdprRegulation}>
      <div className="px-12 pb-[152px] flex items-center justify-center">
        <div className="w-full flex flex-wrap justify-center gap-xs">
          {isGPPEnabled && (
            <PresetSelectionCard
              className="w-[300px] min-h-[330px] 2xl:(w-[400px] h-[370px])"
              value={Frameworks.GPP}
              titleText="Global privacy platform"
              description="GPP is a global framework that supports IAB Europe, IAB Canada and US states-specific privacy strings."
              illustration="CMP-framework-gpp"
              inputType="checkbox"
              onValueChange={e => setSelectedGPP(e.detail)}
            />
          )}
          {isGcmAvailable && (
            <PresetSelectionCard
              className="w-[300px] min-h-[330px] 2xl:(w-[400px] h-[370px])"
              value={Frameworks.GCM}
              titleText="Google Consent Mode V2"
              description="Google Consent Mode V2 wil be enabled by default."
              illustration="CMP-framework-gcm-v2"
              inputType="checkbox"
              onValueChange={e => setSelectedGCM(e.detail)}
            />
          )}

          <PresetSelectionCard
            className="w-[300px] min-h-[330px] 2xl:(w-[400px] h-[370px])"
            value={Frameworks.TCF}
            titleText="IAB TCF framework"
            description="Ensure compliance with the IAB TCF and the main GDPR obligations."
            illustration="CMP-framework-iab"
            inputType="checkbox"
            onValueChange={e => setSelectedIABTCF(e.detail)}
          >
            <DidomiButton
              data-testid="select-preset-what-is-iab-tcf-button"
              className="mb-s"
              iconRight="arrow-right-sm"
              variant="option"
              onClick={() => window.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.openDrawer, { detail: { scrollTo: '#iab-tcf-title' } }))}
            >
              What is IAB TCF
            </DidomiButton>
          </PresetSelectionCard>

          <PresetSelectionCard
            className="w-[300px] min-h-[330px] 2xl:(w-[400px] h-[370px])"
            value={Frameworks.MCM}
            titleText="Microsoft Consent Mode"
            description="Enable Microsoft Consent Mode if you are using UET tag."
            illustration="CMP-framework-mcm"
            inputType="checkbox"
            onValueChange={e => setSelectedMCM(e.detail)}
          />
        </div>
      </div>

      <DidomiBottomBar isOpen variant="light" className="!fixed bottom-0 w-full" closable={false}>
        <div slot="actions">
          <DidomiButtonGroup>
            <DidomiButton data-testid="create-preset-framework-previous-step-button" variant="secondary" onClick={() => navigate(`/add/${platformType}`)}>
              Previous
            </DidomiButton>
            <DidomiButton
              data-testid="create-preset-framework-next-step-button"
              disabled={!selectedPreset || isLoadingGdprRegulation}
              onClick={() => createNotice({ platform: platformType, preset: selectedPreset, gdprGeos })}
            >
              Generate my Consent Notice
            </DidomiButton>
          </DidomiButtonGroup>
        </div>
      </DidomiBottomBar>
      <CreateNoticeExitPromptModal
        isBlock={true}
        /* Allow any path but the main one "/" */
        whitelist={[/\/./g]}
        subTitle="Please pay attention"
        title="Your Consent Notice will not be saved"
        description="You are about to exit your consent notice setup without completing the flow."
      />
      <LoadingModal isOpen={creatingNotice} title="We are generating your Consent Notice..." />
    </DidomiSkeleton>
  );
};
