import React, { useState } from 'react';
import { matchId } from '@didomi/helpers';
import { DidomiLargePush, DidomiLink } from '@didomi/ui-atoms-react';
import { useActiveOrganization, useHasAccessPolicies } from '@didomi/utility-react';
import { SelectionCardCheckbox } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { useConsentNoticeStandardTextsByPlatform } from '@hooks';
import { ConfirmEnablingIabTcfModal } from '@modals';
import { ACCESS_POLICIES_CONFIG } from '@utils';
import { IntegrationDescription } from '../IntegrationDescription/IntegrationDescription';

export const IntegrationIAB = () => {
  const { organization } = useActiveOrganization();
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const configFacade = useLocalConsentNoticeConfigFacade();

  const { data: standardTextResponse, status: standardTextsStatus } = useConsentNoticeStandardTextsByPlatform(configFacade.platform);
  const approvedStandardTexts = standardTextResponse?.data || [];
  const [confirmIabWithStandardText, setConfirmIabWithStandardText] = useState(false);

  const handleIABIntegrationChange = (isSelected: boolean) => {
    // if IAB TCF is enabled: confirm enablement of Standard text
    if (isSelected && configFacade.textMode === 'custom' && !organization.allow_tcf_with_custom_text) {
      setConfirmIabWithStandardText(true);
    } else {
      configFacade.changeIabEnablement(isSelected);
    }
  };

  // Enable IAB TCF and Standard text
  const handleConfirmIabWithStandardText = () => {
    // get standard text by id or default one if ID is wrong
    const currentOrDefaultStandardText = approvedStandardTexts.find(matchId(configFacade.textId)) || approvedStandardTexts.find(text => text.default);

    configFacade.enableIabWithStandardText(currentOrDefaultStandardText?.id);
    setConfirmIabWithStandardText(false);
  };

  const description = (cmpId?: number | null) => {
    return (
      <>
        <div>
          We automatically share consent choices with IAB vendors belonging to the IAB TCF framework and for whom you collect consent. If you are using Prebid, make sure to
          configure it to obtain consent from our CMP. By using the IAB TCF, you agree to comply with IAB TCF policies in your texts and customizations. IAB TCF will be disabled
          for non-compliant notices.
        </div>

        {cmpId && (
          <div className="mt-xs" data-testid="iab-tcf-cmp-id">
            IAB TCF consent strings will be created with the <span className="font-bold">CMP ID {cmpId}</span>.
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <SelectionCardCheckbox
        disabled={standardTextsStatus !== 'success' || configFacade.isLoadingConfig || !isCMPEditor}
        title={`IAB Transparency & Consent framework (v.${configFacade.iabTcfVersion})`}
        checked={configFacade.isIabEnabled}
        description={<IntegrationDescription compatibleWith={['GDPR']} content={description(organization?.iab_tcf_cmp_id)} />}
        illustration="integrations-iab"
        onValueChange={handleIABIntegrationChange}
      >
        <DidomiLargePush
          className="w-full children:(!max-w-none) mb-xs"
          highlighted-text="IAB TCF version 2.2 - Update guide"
          text="Documentation"
          icon="export"
          href="https://support.didomi.io/tcf-v2.2-what-will-change-in-your-notice"
          target="_blank"
        ></DidomiLargePush>

        <div className="flex gap-xs">
          <DidomiLink size="small" isUnderlined href="https://iabeurope.eu/tcf-2-0/" target="_blank" text="Read more about the IAB TCF" />
          <DidomiLink
            size="small"
            isUnderlined
            href="https://developers.didomi.io/cmp/web-sdk/third-parties/direct-integrations/prebid"
            target="_blank"
            text="Configure prebid for the Didomi CMP"
          />
        </div>
      </SelectionCardCheckbox>

      {/* Modals */}
      <ConfirmEnablingIabTcfModal isOpen={confirmIabWithStandardText} onCancel={() => setConfirmIabWithStandardText(false)} onConfirm={handleConfirmIabWithStandardText} />
    </>
  );
};
