import React, { useMemo } from 'react';
import { DidomiRadio, DidomiRadioGroup } from '@didomi/ui-atoms-react';

enum RADIO_CONTROL_STRINGS {
  USNAT_DEFAULT = 'usnat-default',
  USNAT_TRUE = 'usnat-true',
  USNAT_FALSE = 'usnat-false',
}

const RADIO_CONTROL_STRING_TO_VALUE = {
  [RADIO_CONTROL_STRINGS.USNAT_TRUE]: true,
  [RADIO_CONTROL_STRINGS.USNAT_FALSE]: false,
  [RADIO_CONTROL_STRINGS.USNAT_DEFAULT]: null,
};

interface GppStringSelectionRadioControlProps {
  value: boolean | null;
  showDefault?: boolean;
  onValueChange: (value: boolean | null) => void;
}

export const GppStringSelectionRadioControl = ({ value, showDefault, onValueChange }: GppStringSelectionRadioControlProps) => {
  // Transform the value to the corresponding string that can be used as the radio group value
  const radioGroupValue = useMemo(() => {
    if (value === true) return RADIO_CONTROL_STRINGS.USNAT_TRUE;
    if (value === false) return RADIO_CONTROL_STRINGS.USNAT_FALSE;
    return RADIO_CONTROL_STRINGS.USNAT_DEFAULT;
  }, [value]);

  const handleControlChange = (value: RADIO_CONTROL_STRINGS) => {
    onValueChange(RADIO_CONTROL_STRING_TO_VALUE[value]);
  };

  return (
    <DidomiRadioGroup
      data-testid="mspa-generate-string-across-us"
      value={radioGroupValue}
      orientation="vertical"
      hideErrorMessage
      onValueChange={e => handleControlChange(e.detail)}
    >
      {showDefault && <DidomiRadio value={RADIO_CONTROL_STRINGS.USNAT_DEFAULT}>Default</DidomiRadio>}
      <DidomiRadio value={RADIO_CONTROL_STRINGS.USNAT_TRUE}>Generate national string across the United States</DidomiRadio>
      <DidomiRadio value={RADIO_CONTROL_STRINGS.USNAT_FALSE}>Generate national string only if state string is not supported</DidomiRadio>
    </DidomiRadioGroup>
  );
};
