import { PrivacySignalsConfig } from '@didomi/cmp-generator';
import { PrivacySignal } from '@types';
import { isSignalWithSubitems, isMappableSignal, getSignalsSubitems } from './signalsHelpers';

/**
 * Get a template of privacy signals that can be mapped with purposes for the current regulation(state + usnat - as we store them all)
 * This template is used to check current stored signals - and update them if needed
 * @param privacySignals - privacy signals for the current regulation + usnat
 */
export const getUniqueMappableSignals = (privacySignals: PrivacySignal[]): PrivacySignalsConfig[] => {
  // keep track of added signals to avoid duplications
  const addedSignals = new Set();

  const signalReducer = (acc: PrivacySignalsConfig[], signal: PrivacySignal) => {
    // skip if signal is not configurable with purposes or already added
    if (!isMappableSignal(signal) || addedSignals.has(signal.name)) return acc;

    // handle signals with subitems
    const signalSubitems = getSignalsSubitems(signal);
    if (isSignalWithSubitems(signal) && signalSubitems.length) {
      // iterate over subitems and add them to the list
      signalSubitems.forEach(subitem => {
        // as signal.name is not unique among subitems - we are using combination of signal.name + subitem in order to track added signals
        const fullSignalName = `${signal.name}.${subitem}`;
        if (addedSignals.has(fullSignalName)) return;

        acc.push({ section: signal.name, namespaces: { gpp: subitem }, purposes: [] });
        addedSignals.add(fullSignalName);
      });
    } else {
      acc.push({ section: signal.name, namespaces: {}, purposes: [] });
      addedSignals.add(signal.name);
    }

    return acc;
  };

  return privacySignals.reduce<PrivacySignalsConfig[]>(signalReducer, []);
};
