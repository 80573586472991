import React, { useState } from 'react';
import { DidomiCheckbox, DidomiLargePush, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { SelectionCardCheckbox } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { AddMCMWarningModal } from '@modals';
import { ACCESS_POLICIES_CONFIG } from '@utils';
import { IntegrationDescription } from '../IntegrationDescription/IntegrationDescription';

export const IntegrationMicrosoftConsentMode = () => {
  const { isLoading, mcm, isIabEnabled, changeMcmSetDefaultStatus, changeMcmAdsDefaultStatus, changeMcmEnablement, changeMcmTCFEnablement } = useLocalConsentNoticeConfigFacade();

  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const [displayAddMicrosoftVendorModal, setDisplayAddMicrosoftVendorModal] = useState(false);

  const handleMCMIntegrationChange = (isSelected: boolean) => {
    if (isSelected && !mcm.isMicrosoftIncluded) {
      setDisplayAddMicrosoftVendorModal(true);
    } else {
      changeMcmEnablement(isSelected);
    }
  };

  const handleAddVendorConfirmation = () => {
    changeMcmEnablement(true);
    setDisplayAddMicrosoftVendorModal(false);
  };

  const canSetDefaultStatus = Boolean(mcm.isSetDefaultStatus && isCMPEditor);
  const canSetDefaultStatusForAdStorage = Boolean(canSetDefaultStatus && mcm.isMicrosoftIncluded);

  return (
    <section data-testid="integrations-microsoft-wrapper" data-loading={isLoading}>
      <SelectionCardCheckbox
        title="Microsoft Consent Mode"
        data-testid="microsoft-consent-mode"
        checked={mcm.isEnabled}
        onValueChange={handleMCMIntegrationChange}
        description={
          <IntegrationDescription
            compatibleWith={['GDPR']}
            content={
              <>
                <div>Enable Microsoft Consent Mode to adjust the behaviour of your universal event tracking (UET) tags, based on the user&apos;s consent status.</div>
                <div>Selecting &quot;Microsoft Advertising&quot; in this integration will automatically add the vendor in the &quot;Vendors & Purposes&quot; section.</div>
              </>
            }
          />
        }
        illustration="integrations-microsoft-consent-mode"
        disabled={!isCMPEditor}
      >
        <div className="flex flex-col gap-xxs">
          <DidomiCheckbox
            data-testid="microsoft-consent-mode-default-status"
            disabled={!mcm.isEnabled || !isCMPEditor}
            checked={mcm.isSetDefaultStatus}
            onValueChange={e => changeMcmSetDefaultStatus(e.detail)}
            hideErrorMessage
            label="Set the default status of Microsoft Consent Mode when the page loads"
          />

          <DidomiCheckbox
            data-testid="microsoft-consent-mode-advertising-default-status"
            disabled={!canSetDefaultStatusForAdStorage || !mcm.isEnabled}
            checked={canSetDefaultStatusForAdStorage && mcm.isAdStorageDefaultStatus}
            onValueChange={e => changeMcmAdsDefaultStatus(e.detail)}
            className="ml-s"
            hideErrorMessage
            label="Enable ad_storage before user gives consent"
          />

          <DidomiTooltip variant="helper" content={mcm.isEnabled && !isIabEnabled ? 'TCF IAB Framework is required to enable the integration with Microsoft Consent Mode' : ''}>
            <div className="inline-block">
              <DidomiCheckbox
                data-testid="microsoft-consent-mode-tcf"
                disabled={!mcm.isEnabled || !isCMPEditor || !isIabEnabled}
                checked={mcm.isEnableTcfAdvertiserConsentMode}
                onValueChange={e => changeMcmTCFEnablement(e.detail)}
                hideErrorMessage
                label="Enable IAB TCF integration with Microsoft Consent Mode"
              />
            </div>
          </DidomiTooltip>
        </div>
        <DidomiLargePush
          data-testid="microsoft-consent-mode-documentation"
          className="w-full mb-xs pt-xs"
          highlighted-text="Microsoft Consent Mode"
          text="Documentation"
          icon="export"
          href="https://support.didomi.io/microsoft-consent-mode"
          target="_blank"
        ></DidomiLargePush>
      </SelectionCardCheckbox>
      <AddMCMWarningModal isOpen={!!displayAddMicrosoftVendorModal} onCancel={() => setDisplayAddMicrosoftVendorModal(null)} onProceed={handleAddVendorConfirmation} />
    </section>
  );
};
