import React, { useState } from 'react';
import { GppMspaModes } from '@didomi/cmp-generator';
import { DidomiButton, DidomiCheckbox, DidomiLargePush, DidomiLink, DidomiRadio, DidomiRadioGroup } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { SelectionCardCheckbox } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { GppStringSettingsModal } from '@modals';
import { ACCESS_POLICIES_CONFIG } from '@utils';
import { IntegrationDescription } from '../IntegrationDescription/IntegrationDescription';

export const IntegrationGpp = () => {
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { gpp, getRegulationsToUsnatHash, changeGppEnablement, changeGppMspaSignatory, changeGppMspaMode, changeGppMspaGenerateUsnatString } = useLocalConsentNoticeConfigFacade();
  const [configureUsnatModalState, setConfigureUsnatModalState] = useState({ isOpen: false, regulationIdsToUsnatHash: {} });

  const areMspaSubfieldsDisabled = !gpp.isEnabled || !gpp.isMspaSignatory;

  const handleSaveStringChange = (defaultValue: boolean, regulationIdToUsnatHash: Record<string, boolean | null>) => {
    changeGppMspaGenerateUsnatString(defaultValue, regulationIdToUsnatHash);
    setConfigureUsnatModalState({ isOpen: false, regulationIdsToUsnatHash: {} });
  };

  return (
    <>
      <SelectionCardCheckbox
        title="Global Privacy Platform Framework"
        data-testid="gpp"
        description={
          <IntegrationDescription
            compatibleWith={['GDPR', 'US']}
            content={
              <p>
                The Global Privacy Platform (GPP) streamlines compliance with regional privacy regulations for digital advertising by providing a flexible transport layer for user
                consent and preference signaling. It reduces costs, adapts to regional differences, and enhances transparency and control for users globally.
              </p>
            }
          />
        }
        illustration="integrations-iab"
        checked={gpp.isEnabled}
        disabled={!isCMPEditor}
        onValueChange={changeGppEnablement}
      >
        <DidomiCheckbox
          disabled={!gpp.isEnabled || !isCMPEditor}
          checked={gpp.isMspaSignatory}
          hideErrorMessage
          label="MSPA Signatory"
          onValueChange={e => changeGppMspaSignatory(e.detail)}
        />

        <div className="pl-m text-body-small mb-s">
          <p>To configure this MSPA settings, your organization must be MSPA signatory.</p>
          <DidomiLink className="block mb-xxs" isUnderlined href="#TODO" text="Read more about MSPA" />

          <div>
            <div className="mb-xs">
              <div className="font-semibold mb-xxs">MSPA Mode</div>
              <DidomiRadioGroup
                value={gpp.mspaMode}
                orientation="vertical"
                data-testid="mspa-mode"
                disabled={areMspaSubfieldsDisabled || !isCMPEditor}
                hideErrorMessage
                onValueChange={e => changeGppMspaMode(e.detail)}
              >
                <div>
                  <DidomiRadio value={GppMspaModes.SERVICE_PROVIDER}>Service Provider Mode</DidomiRadio>
                  <div className="text-neutral-gray-0">If you only engage in MSPA with Downstream Participants or Certified Partners as Service Providers.</div>
                </div>
                <div>
                  <DidomiRadio value={GppMspaModes.OPT_OUT}>Opt-Out Option Mode</DidomiRadio>
                  <div className="text-neutral-gray-0">
                    If you engage in MSPA with Downstream Participants or Certified Partners in other roles and provide Consumers with an Opt-out option per State Privacy Law.
                  </div>
                </div>
              </DidomiRadioGroup>
            </div>
            <hr className="h-0 border-t-1 border-neutral-gray-3 mb-xs" />
            <div>
              <div className="font-semibold">US Regulation approach</div>
              <div className="mb-xxs">Only MSPA signatories can use the US national section to encode the user&apos;s opt-out status.</div>
              <DidomiButton
                className="!block"
                size="small"
                icon-right="settings"
                disabled={areMspaSubfieldsDisabled || !isCMPEditor}
                onClick={() => setConfigureUsnatModalState({ isOpen: true, regulationIdsToUsnatHash: getRegulationsToUsnatHash() })}
              >
                Configure string
              </DidomiButton>
            </div>
          </div>
        </div>

        <DidomiLargePush className="w-full" highlighted-text="Global Privacy Platform" text="Documentation" icon="export" href="#TODO" target="_blank" />
      </SelectionCardCheckbox>

      {/* Modals */}
      <GppStringSettingsModal
        isOpen={configureUsnatModalState.isOpen}
        regulationIdToUsnatValueHash={configureUsnatModalState.regulationIdsToUsnatHash}
        defaultValue={gpp.isMspaGenerateUsnatString}
        onCancel={() => setConfigureUsnatModalState({ isOpen: false, regulationIdsToUsnatHash: {} })}
        onSave={handleSaveStringChange}
      />
    </>
  );
};
