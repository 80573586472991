import React, { useEffect, useState } from 'react';
import { DidomiModal, DidomiModalActions, DidomiButton, DidomiModalContent, DidomiIllustration, DidomiTextInput } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { BlockLoader } from '@components';
import { useConsentNoticeTemplates, useCreateConsentNoticeTemplate, useEditConsentNoticeConfig, useLocalConsentNoticeConfig, useLocalRegulationConfig } from '@hooks';
import { ConsentNoticeConfig } from '@types';

type SaveAsTemplateModalProps = {
  isOpen: boolean;
  noticeId: string;
  regulationId: string;
  onCancel?: () => void;
  onSave?: () => void;
  onError?: () => void;
};

export const SaveAsTemplateModal = ({ isOpen, noticeId, regulationId, onCancel, onSave, onError }: SaveAsTemplateModalProps) => {
  const [templateName, setTemplateName] = useState('');

  const { data: localConsentNoticeConfig, isLoading: isLoadingLocalNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { regulationConfig, isLoading: isLoadingLocalRegulationConfig } = useLocalRegulationConfig(noticeId, regulationId);

  const { mutateAsync: createTemplate, isLoading: savingCreateTemplate } = useCreateConsentNoticeTemplate();
  const { mutateAsync: updateNoticeConfig, isLoading: savingNoticeConfig } = useEditConsentNoticeConfig();

  const { data: { data: templates } = { data: [] } } = useConsentNoticeTemplates();

  useEffect(() => {
    setTemplateName(`Consent notice - Template #${(templates?.length || 0) + 1}`);
  }, [isOpen, templates, setTemplateName]);

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const handleSave = async () => {
    try {
      const createdTemplate = await createTemplate({ name: templateName, config: regulationConfig.config, regulation_settings: regulationConfig.regulation_settings });

      await updateNoticeConfig({
        id: localConsentNoticeConfig.id,
        notice_id: noticeId,
        regulation_configurations: [{ id: regulationConfig.id, template_id: createdTemplate.data.id }],
      } as ConsentNoticeConfig);

      onSave();
    } catch (error) {
      onError?.();
    }
  };

  const isSaving = savingNoticeConfig || savingCreateTemplate;
  const isLoading = isLoadingLocalRegulationConfig || isLoadingLocalNoticeConfig;

  return (
    <DidomiModal data-testid={isLoading ? 'loading-save-as-template-modal' : 'save-as-template-modal'} isOpen={isOpen} onOpenChange={handleModalChange}>
      <div>{isSaving && <BlockLoader>Saving changes...</BlockLoader>}</div>

      <div className={isSaving ? 'hidden' : ''}>
        <DidomiIllustration className={tx('mb-s')} name="templating" />

        <div className={tx('text-body-big-semibold text-primary-pink-5 font-bold mb-xxxs')}>Save as vendor list</div>

        <DidomiModalContent className={tx('text-left w-full')}>
          {!isLoading && <DidomiTextInput class="w-full" value={templateName} onValueChange={e => setTemplateName(e.detail)} label="Name" hideErrorMessage />}
        </DidomiModalContent>

        <DidomiModalActions>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>

          <DidomiButton iconRight="save" variant="main" onClick={handleSave} disabled={isLoading}>
            Save
          </DidomiButton>
        </DidomiModalActions>
      </div>
    </DidomiModal>
  );
};
