import React, { useMemo, useState } from 'react';
import { SDKConfigPreferencesCategory } from '@didomi/cmp-generator';
import { getTranslatedValue, matchId } from '@didomi/helpers';
import { DidomiButton, DidomiEmptyState, DidomiHintbox, DidomiLoader } from '@didomi/ui-atoms-react';
import { useSnackbar, useSPARouter } from '@didomi/utility-react';
import { closestCenter, DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import debounce from 'lodash.debounce';
import { ConfirmDeleteModal, PurposeCategoryFormModal } from '@modals';
import { ExtendedPreferencesCategoriesConfig, isPreferenceCategory, PreferenceCategoryGroup } from '@types';
import { getUpdatedNoticeCategoriesAfterDragEnd, getUpdatedNoticeCategoriesOnDragOver } from '@utils';
import { SortableListItem } from '../../SortableHelpers/SortableListItem';
import { NoticePurposeCard } from '../NoticePurposeCard/NoticePurposeCard';
import { NoticePurposeCategoryCard } from '../NoticePurposeCategoryCard/NoticePurposeCategoryCard';

type NoticePurposesProps = {
  isLoading: boolean;
  noticeName: string;
  categories: SDKConfigPreferencesCategory[];
  extendedCategories: ExtendedPreferencesCategoriesConfig;
  viewOnly?: boolean;
  hasEssentialPurposes?: boolean;
  isGDPRRegulation?: boolean;
  hasButtonsCustomizationPerPurposeCategory?: boolean;
  hasHidePurposes?: boolean;
  showGppMappingWarning?: boolean;
  purposesMappedToSignals?: Set<string>;
  onCategoriesChanges: (categories: SDKConfigPreferencesCategory[]) => void;
  onChangeEssentialPurpose?: (purposeSdkId: string, isRequired: boolean) => void;
};

export const NoticePurposes = ({
  isLoading,
  noticeName,
  categories,
  extendedCategories,
  viewOnly,
  hasEssentialPurposes = true,
  isGDPRRegulation,
  hasButtonsCustomizationPerPurposeCategory,
  hasHidePurposes,
  showGppMappingWarning,
  purposesMappedToSignals,
  onCategoriesChanges,
  onChangeEssentialPurpose,
}: NoticePurposesProps) => {
  const [categoryModalData, setCategoryModalData] = useState({ isOpen: false, category: null });
  const [categoryToDelete, setCategoryToDelete] = useState<PreferenceCategoryGroup>(null);
  const { displaySnackbar } = useSnackbar();
  const { navigateTo } = useSPARouter();

  const [draggingItem, setDraggingItem] = useState(null);
  const sensors = useSensors(useSensor(KeyboardSensor), useSensor(PointerSensor, { activationConstraint: { delay: 50, tolerance: 10 } }));

  const flattenCategories: ExtendedPreferencesCategoriesConfig = useMemo(() => {
    return extendedCategories.reduce((acc, category) => {
      return [...acc, category, ...(category['children'] || [])];
    }, []);
  }, [extendedCategories]);

  // Use debounced onCategoriesChanges to avoid infinite loop that might occur when moving item between containers(from root to group OR from group to root)
  // https://github.com/clauderic/dnd-kit/issues/735
  const updateDebouncedCategories = useMemo(() => {
    return debounce(onCategoriesChanges, 100);
  }, [onCategoriesChanges]);

  const handleAddCategory = (newCategory: PreferenceCategoryGroup) => {
    onCategoriesChanges([...categories, newCategory]);

    displaySnackbar('Category has been added', { icon: 'success-small' });
    setCategoryModalData({ isOpen: false, category: null });
  };

  const handleUpdateCategory = (updatedCategory: PreferenceCategoryGroup) => {
    const updatedCategories = categories.map(category => (category.type === 'category' && category.id === updatedCategory.id ? updatedCategory : category));
    onCategoriesChanges(updatedCategories);

    displaySnackbar('Category has been updated', { icon: 'success-small' });
    setCategoryModalData({ isOpen: false, category: null });
  };

  const handleDeleteCategory = () => {
    const filteredCategories = categories.filter(category => (category.type === 'category' ? category.id !== categoryToDelete.id : true));

    // we need to append all the children of deleted category to the top list
    onCategoriesChanges([...filteredCategories, ...categoryToDelete.children]);

    displaySnackbar('Category has been deleted', { icon: 'success-small' });
    setCategoryToDelete(null);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const draggingId = event.active.id as string;

    const draggingPurpose = flattenCategories.find(matchId(draggingId));
    setDraggingItem(draggingPurpose);
  };

  /*
   * During drag over event we need to check whether dragging item should be moved between different groups - if yes, we need to perform state update in order to move this item
   * Possible movements:
   * - from-root => to-group
   * - from-group => to-root
   * - from-one-group => to-another-group
   */
  const handleDragOver = (event: DragOverEvent) => {
    const updatedCategories = getUpdatedNoticeCategoriesOnDragOver(event, categories);

    if (updatedCategories) {
      updateDebouncedCategories(updatedCategories);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setDraggingItem(null);
    const updatedCategories = getUpdatedNoticeCategoriesAfterDragEnd(event, categories, extendedCategories);

    if (updatedCategories) {
      onCategoriesChanges(updatedCategories);
    }
  };

  return (
    <section>
      <header className="flex items-center mb-xs">
        <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4 mr-auto">Purposes</h2>

        {!viewOnly && (
          <>
            <DidomiButton className="mr-4" size="small" variant="secondary" onClick={() => setCategoryModalData({ isOpen: true, category: null })}>
              Add category
            </DidomiButton>
            <DidomiButton
              size="small"
              onClick={() => {
                navigateTo(`/data-manager/add-purpose?fromPath=${window.location.pathname}`);
              }}
            >
              Add a new purpose
            </DidomiButton>
          </>
        )}
      </header>

      <DidomiHintbox titleText="Organizing the list of purposes">
        <p>Those purposes will be displayed to the user in your consent notice. We have automatically added the purposes required by your vendors.</p>
        {hasHidePurposes && (
          <p>
            Purposes under categories can be shown or hidden, which can be managed by editing or creating a category.{' '}
            <strong>
              Ensure that the category description provides clear and transparent information to the end user. Hidden purposes will still be included in the user’s consent based on
              the category’s status.
            </strong>
          </p>
        )}
      </DidomiHintbox>

      {showGppMappingWarning && (
        <DidomiHintbox className="mt-xs" variant="warning" iconName="warning">
          <strong>As you have GPP framework activated</strong>, you need to map all purposes to at least one privacy signal to be able to save.
        </DidomiHintbox>
      )}

      <DndContext collisionDetection={closestCenter} sensors={sensors} onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnd={handleDragEnd}>
        <ul className="flex flex-col gap-2 mt-8">
          <SortableContext disabled={viewOnly} id="root-sortable" items={extendedCategories.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
            {extendedCategories.map((itemConfig, i) => (
              <SortableListItem
                key={itemConfig.id}
                data-testid="root-purpose-config-item"
                sortingData={{ isGroup: isPreferenceCategory(itemConfig), purposeSdkId: itemConfig['sdk_id'] }}
                sortingId={itemConfig.id}
              >
                {isPreferenceCategory(itemConfig) ? (
                  <NoticePurposeCategoryCard
                    category={itemConfig}
                    viewOnly={viewOnly}
                    hasEssentialPurposes={hasEssentialPurposes}
                    isGDPRRegulation={isGDPRRegulation}
                    purposesMappedToSignals={purposesMappedToSignals}
                    onEdit={() => setCategoryModalData({ isOpen: true, category: categories[i] as PreferenceCategoryGroup })}
                    onDelete={() => setCategoryToDelete(categories[i] as PreferenceCategoryGroup)}
                    onChangeEssentialPurpose={onChangeEssentialPurpose}
                  />
                ) : (
                  <NoticePurposeCard
                    purpose={itemConfig}
                    viewOnly={viewOnly}
                    hasEssentialPurposes={hasEssentialPurposes}
                    isGDPRRegulation={isGDPRRegulation}
                    isMappedToSignal={purposesMappedToSignals?.has(itemConfig.sdk_id)}
                    onRequiredChange={isRequired => onChangeEssentialPurpose(itemConfig.sdk_id, isRequired)}
                  />
                )}
              </SortableListItem>
            ))}
          </SortableContext>
        </ul>

        <DragOverlay className="opacity-90">
          {draggingItem &&
            (draggingItem.children ? (
              <NoticePurposeCategoryCard category={draggingItem} viewOnly={true} purposesMappedToSignals={purposesMappedToSignals} />
            ) : (
              <NoticePurposeCard
                highlightTitle
                purpose={draggingItem}
                isGDPRRegulation={isGDPRRegulation}
                hasEssentialPurposes={hasEssentialPurposes}
                viewOnly={true}
                isMappedToSignal={purposesMappedToSignals?.has(draggingItem.sdk_id)}
              />
            ))}
        </DragOverlay>
      </DndContext>

      {/* Empty State */}
      {categories.length === 0 && (
        <DidomiEmptyState
          illustration-name="traces-no-result-found"
          className="h-[500px] mt-8"
          actionName={viewOnly ? '' : 'Add category'}
          actionIconRight={viewOnly ? '' : 'new-create'}
          onActionClick={() => setCategoryModalData({ isOpen: true, category: null })}
        >
          <div slot="title">It&apos;s empty here!</div>
          You have no purposes or categories added.
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {isLoading && (
        <div className="w-full flex flex-col items-center justify-center mt-8">
          <DidomiLoader className="mb-xs" />
          <div className="text-body-normal text-primary-blue-5">Loading purposes</div>
        </div>
      )}

      {/* Essential purpose's tooltip content(used inside NoticePurposeCard) */}
      <div id="essential-purpose-helper-text" hidden>
        Defining a purpose as <strong>“Essential”</strong> doesn&apos;t allow users to do a choice between “Disagree“ and “Agree“, the purpose is considered as required by default.
      </div>

      {/* Modals */}
      <PurposeCategoryFormModal
        isOpen={categoryModalData.isOpen}
        noticeName={noticeName}
        category={categoryModalData.category}
        withButtonsCustomization={hasButtonsCustomizationPerPurposeCategory}
        withHidePurposes={hasHidePurposes}
        onSave={categoryData => (categoryModalData.category ? handleUpdateCategory(categoryData) : handleAddCategory(categoryData))}
        onCancel={() => setCategoryModalData({ isOpen: false, category: null })}
      />

      <ConfirmDeleteModal
        isOpen={!!categoryToDelete}
        titleText={'Are you sure you want to delete this category?'}
        onCancel={() => setCategoryToDelete(null)}
        onConfirm={handleDeleteCategory}
      >
        <div>
          You are about to delete category <strong>&quot;{categoryToDelete ? getTranslatedValue(categoryToDelete.name) : ''}&quot;</strong>.
        </div>
      </ConfirmDeleteModal>
    </section>
  );
};
