import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiIconButton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { SortableContext } from '@dnd-kit/sortable';
import { SortableDropzone, SortableListItem, SortableGrabHandler } from '@components';
import { ExtendedPreferenceCategoryGroup } from '@types';
import { NoticePurposeCard } from '../NoticePurposeCard/NoticePurposeCard';

type NoticePurposeCategoryCardProps = {
  category: ExtendedPreferenceCategoryGroup;
  viewOnly?: boolean;
  hasEssentialPurposes?: boolean;
  isGDPRRegulation?: boolean;
  purposesMappedToSignals?: Set<string>;
  onEdit?: () => void;
  onDelete?: () => void;
  onChangeEssentialPurpose?: (purposeSdkId: string, isRequired: boolean) => void;
};

export const NoticePurposeCategoryCard = ({
  category,
  viewOnly,
  hasEssentialPurposes = true,
  isGDPRRegulation,
  purposesMappedToSignals,
  onEdit,
  onDelete,
  onChangeEssentialPurpose,
}: NoticePurposeCategoryCardProps) => {
  return (
    <div className="text-primary-blue-6 py-2 pl-4 bg-white">
      {/* HEADER */}
      <div className="flex items-center">
        <SortableGrabHandler a11yText={'Drag ' + getTranslatedValue(category.name)} disabled={viewOnly} className="mr-4" />

        <div className="font-semibold text-body-small mr-auto" data-testid="purpose-category-name">
          {getTranslatedValue(category.name)}
        </div>

        {!viewOnly && (
          <>
            <DidomiTooltip content="Edit" placement="top">
              <DidomiIconButton aria-label="Edit category" type="button" variant="rounded" icon="edit" onClick={onEdit} />
            </DidomiTooltip>
            <DidomiTooltip content="Delete" placement="top">
              <DidomiIconButton aria-label="Delete category" type="button" variant="rounded" icon="delete" onClick={onDelete} />
            </DidomiTooltip>
          </>
        )}
      </div>

      {/* Shift by using ml-[7px] to display left border beneath the icon(15px)  */}
      <div className="ml-[7px] pl-6 border-l-1 border-solid border-neutral-gray-3">
        <div className="text-body-extra-small mb-2" data-testid="purpose-category-description">
          {getTranslatedValue(category.description, '')}
        </div>

        <SortableContext id={category.id} disabled={viewOnly} items={category.children.map(({ id }) => id)}>
          {!!category.children.length && (
            <ul className="flex flex-col gap-2">
              {category.children.map(purpose => (
                <SortableListItem key={purpose.id} sortingData={{ purposeSdkId: purpose.sdk_id }} sortingId={purpose.id}>
                  <NoticePurposeCard
                    purpose={purpose}
                    viewOnly={viewOnly}
                    hasEssentialPurposes={hasEssentialPurposes}
                    isGDPRRegulation={isGDPRRegulation}
                    isMappedToSignal={purposesMappedToSignals?.has(purpose.sdk_id)}
                    isHidden={category.hidePurposes}
                    onRequiredChange={isRequired => onChangeEssentialPurpose(purpose.sdk_id, isRequired)}
                  />
                </SortableListItem>
              ))}
            </ul>
          )}

          {category.children.length === 0 && (
            <SortableDropzone dropzoneId={category.id + '-dropzone'}>
              Drop purposes here to add them to the category. The category will not be displayed to users unless you add at least one purpose to it.
            </SortableDropzone>
          )}
        </SortableContext>
      </div>
    </div>
  );
};
