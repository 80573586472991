import { PurposeRegulationOverride } from '@didomi/cmp-generator';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useFetchAllPaginatedResults } from '../../useFetchAllPaginatedResults.hook';

export const usePurposesRegulationsOverrides = (options?: UseQueryOptions<PurposeRegulationOverride[]>) => {
  const { fetchAllPaginatedResults } = useFetchAllPaginatedResults();
  const { organizationId } = useActiveOrganization();

  const fetchOverrides = async () => fetchAllPaginatedResults<PurposeRegulationOverride>(`metadata/purposes-regulations-overrides?organization_id=${organizationId}`);

  return useQuery<PurposeRegulationOverride[], AxiosError>('purposes-regulations-overrides', fetchOverrides, options);
};
